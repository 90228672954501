
<template>
  <div id="client-modal-wrapper" v-if="!clientDataLoading">
    <v-snackbar v-model="snackbar.active" :color="snackbar.color" top :timeout="snackbar.timeout">
      <v-row>
        <v-icon color="white" class="mx-3">{{ snackbar.icon}}</v-icon>
        <span class="py-2 white--text">{{ snackbar.text}}</span>
      </v-row>
    </v-snackbar>

    <div v-if="viewClient">   
      <div class="client-information-wrapper">
        <div class="client-information-menu">
          <v-card flat :color="$themes[$store.state.companyTheme][$store.state.themeModus].card" class="theme-card theme-shadow" :class="$store.state.companyTheme" style="width: 100%" >
            <!-- <v-tabs class="mb-1" vertical left :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary"  show-arrows v-model="tab" @change="tabChanged()" style="border-bottom: thin solid;text-align: left;" :style="{'border-color' : $themes[$store.state.companyTheme][$store.state.themeModus].separator }">
              <v-tab href="#summary"><v-icon left>mdi-account</v-icon>{{ $t('clients.summary', $store.state.locale) }}</v-tab>
              <v-divider></v-divider>
              <v-tab href="#body"><v-icon left>mdi-scale-bathroom</v-icon>{{ $t('clients.bodyAnalysis', $store.state.locale) }}</v-tab>
              <v-tab href="#measurements"><v-icon left>mdi-ruler</v-icon>{{ $t('clients.measurements', $store.state.locale) }}</v-tab> 
              <v-tab href="#photos"><v-icon left>mdi-camera</v-icon>{{ $t('clients.beforeAfterPhotos', $store.state.locale) }}</v-tab> 
              <v-tab href="#history"><v-icon left>mdi-calendar-check</v-icon>{{ $t('clients.history', $store.state.locale) }}</v-tab>
              <v-divider></v-divider>
              <v-tab href="#appointments"><v-icon left>mdi-calendar-arrow-right</v-icon>{{ $t('clients.scheduledAppointments', $store.state.locale) }} </v-tab>
              <v-tab href="#tags"><v-icon left>mdi-tag</v-icon>{{ $t('clients.tags', $store.state.locale) }}</v-tab>
              <v-tab href="#forms" @click.native="triggerLoadClientForm()"><v-icon left>mdi-table-account</v-icon>{{ $t('clientForm.clientForms', $store.state.locale) }}</v-tab>
            </v-tabs> -->
            <v-list class="pa-0 ma-0" expand dense>
              <v-list-item :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" :input-value="selectedView('summary')" @click="selectClientView('summary')">
                <v-list-item-icon class="mr-2">
                  <v-icon small>mdi-account</v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title style="text-transform: uppercase; font-size: 14px" :style="{color: $themes[$store.state.companyTheme][$store.state.themeModus].text}">{{ $t('clients.summary', $store.state.locale) }}</v-list-item-title>
                </v-list-item-content>
              </v-list-item>

              <v-divider></v-divider>

              <v-list-item :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" :input-value="selectedView('history')" @click="selectClientView('history')">
                <v-list-item-icon class="mr-2">
                  <v-icon small>mdi-calendar-check</v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title style="text-transform: uppercase; font-size: 14px" :style="{color: $themes[$store.state.companyTheme][$store.state.themeModus].text}">{{ $t('clients.history', $store.state.locale) }}</v-list-item-title>
                </v-list-item-content>
              </v-list-item>

              <v-divider></v-divider>

              <!-- <v-list-item :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" :input-value="selectedView('photos')" @click="selectClientView('photos')">
                <v-list-item-icon class="mr-2">
                  <v-icon>mdi-camera</v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title style="text-transform: uppercase; font-size: 14px" :style="{color: $themes[$store.state.companyTheme][$store.state.themeModus].text}">{{ $t('clients.photos', $store.state.locale) }}</v-list-item-title>
                </v-list-item-content>
              </v-list-item>

              <v-divider></v-divider> -->

              <v-list-item :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" :input-value="selectedView('appointments')" @click="selectClientView('appointments')">
                <v-list-item-icon class="mr-2">
                  <v-icon small>mdi-calendar-arrow-right</v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title style="text-transform: uppercase; font-size: 14px" :style="{color: $themes[$store.state.companyTheme][$store.state.themeModus].text}">{{ $t('clients.scheduledAppointments', $store.state.locale) }}</v-list-item-title>
                </v-list-item-content>
              </v-list-item>

              <v-divider></v-divider>

              <v-list-item :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" :input-value="selectedView('tags')" @click="selectClientView('tags')">
                <v-list-item-icon class="mr-2">
                  <v-icon small>mdi-tag</v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title style="text-transform: uppercase; font-size: 14px" :style="{color: $themes[$store.state.companyTheme][$store.state.themeModus].text}">{{ $t('clients.tags', $store.state.locale) }}</v-list-item-title>
                </v-list-item-content>
              </v-list-item>

              <v-divider></v-divider>

              <v-list-item :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" :input-value="selectedView('prepayments')" @click="selectClientView('prepayments')">
                <v-list-item-icon class="mr-2">
                  <v-icon small>mdi-cash-check</v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title style="text-transform: uppercase; font-size: 14px" :style="{color: $themes[$store.state.companyTheme][$store.state.themeModus].text}">{{ $t('clients.prepayments', $store.state.locale) }}</v-list-item-title>
                </v-list-item-content>
              </v-list-item>

              <v-divider></v-divider>

              <v-list-item :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" :input-value="selectedView('memberships')" @click="selectClientView('memberships')">
                <v-list-item-icon class="mr-2">
                  <v-icon small>mdi-check</v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title style="text-transform: uppercase; font-size: 14px" :style="{color: $themes[$store.state.companyTheme][$store.state.themeModus].text}">{{ $t('memberships.multiSubscriptions', $store.state.locale) }}</v-list-item-title>
                </v-list-item-content>
              </v-list-item>

              <v-divider></v-divider>

              <v-list-item :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" :input-value="selectedView('forms')" @click="selectClientView('forms')">
                <v-list-item-icon class="mr-2">
                  <v-icon small>mdi-table-account</v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title style="text-transform: uppercase; font-size: 14px" :style="{color: $themes[$store.state.companyTheme][$store.state.themeModus].text}">{{ $t('clientForm.clientForms', $store.state.locale) }} </v-list-item-title>
                </v-list-item-content>
              </v-list-item>
              <v-divider></v-divider>

              <v-list-item :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" :input-value="selectedView('connected')" @click="selectClientView('connected')">
                <v-list-item-icon class="mr-2">
                  <v-icon small>mdi-human-female-boy</v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title style="text-transform: uppercase; font-size: 14px" :style="{color: $themes[$store.state.companyTheme][$store.state.themeModus].text}">{{ $t('clients.connectedClients', $store.state.locale) }} </v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </v-list>
          </v-card>
        </div>
        <div class="client-information-content pl-4">
          <!-- / Client Summary \ -->
          <div v-if="selectedView('summary')">     
            <v-container grid-list-md class="pa-0">
              <v-alert class="ma-0 pa-1" v-if="feedbackClientOverview" :value="true" type="warning">{{ feedbackClientOverview }}</v-alert>
              <v-layout v-if="editClient">
                <v-flex xs6>
                  <div v-if="selectedClientTags && selectedClientTags.length > 0">
                    
                    <v-tooltip dark top v-for="(item, index) in selectedClientTags" :key="index">
                      <template v-slot:activator="{ on }" >
                        <v-chip v-on="getTagType(item.id).description ? on : false" class="ma-1 custom-chip-style" close small label :color="$themes[$store.state.companyTheme][$store.state.themeModus][getTagType(item.id).color]" :text-color="$themes[$store.state.companyTheme][$store.state.themeModus][getTagType(item.id).color]" @click:close="removeTagDialog(item)"> 
                          {{ getTagType(item.id).name }}
                        </v-chip>
                      </template>
                      <span class="tooltip-text-white">{{  getTagType(item.id).description }}</span>
                    </v-tooltip>

                    <!-- <v-chip light class="ma-1 custom-chip-style" v-for="(item, index) in selectedClientTags" :key="index" label small close :color="$themes[$store.state.companyTheme][$store.state.themeModus][getTagType(item.id).color]" :text-color="$themes[$store.state.companyTheme][$store.state.themeModus][getTagType(item.id).color]" @click:close="removeTagDialog(item)">
                      <span v-if="item.type == 'lead'"><strong>{{ getTagType(item.id).name }}</strong></span><span v-if="item.name">{{ item.name }}</span>
                    </v-chip> -->
                  </div>
                </v-flex>
                <v-flex xs6 style="min-height: 40px;">
                  <div class="text-right pa-0">
                    <v-btn class="ma-0" small :dark="changed" :depressed="!changed" :disabled="!changed" :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" @click="updateClient()">
                      <div v-if="changed">{{ $t('general.saveChanged', $store.state.locale) }} <v-icon righy>mdi-chevron-right</v-icon></div>
                      <div v-else>{{ $t('general.saved', $store.state.locale) }}  <v-icon right>mdi-check</v-icon></div>  
                    </v-btn>
                  </div>
                </v-flex>
              </v-layout>
              <v-layout v-else>
                <v-flex xs12>
                  <v-alert dense outlined :border="'left'" type="warning">{{ $t('general.noEditPermission', $store.state.locale) }}</v-alert>
                </v-flex>
              </v-layout>
              
              <v-layout row wrap>
                <v-flex :class="columnWidth" class="pr-2">

                  <!-- / CLIENT BASIC INFORMATION \ -->
                  <v-card flat :color="$themes[$store.state.companyTheme][$store.state.themeModus].card" class="theme-card theme-shadow" :class="$store.state.companyTheme">
                    <div class="pa-3">
                      <v-avatar
                        size="36"
                        light
                        style="float: left"
                        class="ma-0 mr-2 custom-avatar-style" 
                        rounded="lg"
                        :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" 
                        :style="{'color': $themes[$store.state.companyTheme][$store.state.themeModus].primary}" 
                        >
                        <v-icon :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary">mdi-information</v-icon>
                      </v-avatar>
                      <h3 class="pt-1 pb-4">{{ $t('clients.basicInformation', $store.state.locale) }}</h3>

                      <v-divider></v-divider>

                      <v-card outlined class="ma-0 my-3" :color="$themes[$store.state.companyTheme][$store.state.themeModus].cardOption" v-if="$store.state.userRoleNumber < 2 && !$store.state.demoMode">
                        <v-btn dark @click="copyTextToClipboard(selectedClient.id, 'clientId')" class="ma-1 my-2" small :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" style="text-transform: none">
                          <strong>{{ selectedClient.id }}</strong>
                        </v-btn>
                        <div v-if="copyType == 'clientId'" style="display: inline-block" class="pa-2 copy-tag-url copied">DocId gekopieërd</div>

                        <div style="display: inline-block" v-else   class="pa-2 copy-tag-url" @click="copyTextToClipboard(selectedClient.id, 'clientId')">
                          <v-icon small :color="$themes[$store.state.companyTheme][$store.state.themeModus].text">mdi-content-copy</v-icon> Kopieër DocId
                        </div>
                        
                      </v-card>
          
                      <v-layout row wrap class="mt-3">
                        <v-flex xs6>
                          <v-text-field
                            class="ma-0 pa-0 mt-1"
                            :label="$t('general.firstName', $store.state.locale)"
                            v-model="selectedClient.name"
                            outlined
                            dense
                            :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" 
                            background-color="background"
                            hide-details
                          ></v-text-field>
                        </v-flex>

                        <v-flex xs6>
                          <v-text-field
                            class="ma-0 pa-0 mt-1"
                            :label="$t('general.lastName', $store.state.locale)"
                            v-model="selectedClient.surname"
                            outlined
                            dense
                            :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" 
                            background-color="background"
                            hide-details
                          ></v-text-field>
                        </v-flex>

                        <v-flex xs6>
                          <v-select
                            class="ma-0 pa-0 mt-1"
                            :label="$t('general.gender', $store.state.locale)"
                            v-model="selectedClient.gender"
                            v-on:input="clearClientIdealWeight()"
                            :items="genders"
                            item-text="name"
                            item-value="id"
                            required
                            dense
                            :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" 
                            :item-color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" 
                            outlined
                            background-color="background"
                            hide-details
                          ></v-select>
                        </v-flex>

                        <v-flex xs6>
                          <v-menu
                            :close-on-content-click="false"
                            v-model="birthdayDateDialog"
                            :nudge-right="40"         
                            transition="scale-transition"
                            offset-y
                            max-width="290px"
                            min-width="290px"
                            >
                            <template v-slot:activator="{ on }">
                              <v-text-field
                                v-on="on"
                                :value="birthdayDate.readable"
                                :label="$t('general.birthday', $store.state.locale)"
                                readonly
                                dense
                                :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" 
                                outlined
                                hide-details
                                class="mt-1"
                                background-color="background"    
                              ></v-text-field>
                            </template>
                            <v-date-picker
                              :active-picker.sync="activePicker"
                              v-model="birthdayDateRaw"
                              no-title
                              :max="today"
                              :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary"
                              @input="birthdayDateDialog = false"
                            ></v-date-picker>
                          </v-menu>
                        </v-flex>

                        <v-flex xs12>
                          <v-select
                            :label="$t('general.language', $store.state.locale)"
                            outlined
                            persistent-hint
                            class="mt-1"
                            dense
                            hide-details
                            content-class="pa-0 ma-0 mr-4"
                            :items="availableLanguages"
                            v-model="selectedClient.language"
                            :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary"
                            :item-color="$themes[$store.state.companyTheme][$store.state.themeModus].primary"
                            background-color="background"
                            item-text="id"
                            >
                            <template slot="selection" slot-scope="data" class="ma-0 pa-0">
                              <country-flag :country="data.item.flagCode" size='small'/>
                              <span>{{ data.item.languageName[$store.state.locale.toLowerCase()] }}</span>
                            </template>
                            <template slot="item" slot-scope="data">
                              <country-flag :country="data.item.flagCode" size='small'/>
                              <span>{{ data.item.languageName[$store.state.locale.toLowerCase()] }}</span>
                            </template>
                          </v-select>
                        </v-flex>
                        
                        <v-flex xs12>
                          <v-switch
                            class="pa-1 ma-0"
                            v-model="selectedClient.minor"
                            inset
                            dense
                            :label="$t('clients.minorLabel', $store.state.locale)"
                            :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary"
                            :hint="`<i>${$t('clients.minorChoiceClientHint', $store.state.locale)}  ${ selectedClient.name } ${ selectedClient.surname } </i>`"
                            persistent-hint
                            @change="setMinorData()"
                            >
                            <template v-slot:message="{ message, key }">
                              <span v-html="message"></span>
                            </template>
                          </v-switch>

                          <v-select
                           v-if="selectedClient.minor" 
                            :label="$t('general.type', $store.state.locale)"
                            outlined
                            class="my-2"
                            dense
                            :item-text="item =>  $t('general.'+item.name, $store.state.locale)"
                            item-value="id"
                            hide-details
                            content-class="pa-0 ma-0 mr-4"
                            :items="minorTypes"
                            v-model="selectedClient.minorType"
                            :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary"
                            :item-color="$themes[$store.state.companyTheme][$store.state.themeModus].primary"
                            background-color="background"
                            >
                          </v-select>

                          <v-alert v-if="selectedClient.minor" type="info" prominent text :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" class="mt-2 ma-0 pa-2" style="font-size: 14px" v-html="$t('clients.clientMinorHint', $store.state.locale)"></v-alert>

                        </v-flex>
                      </v-layout>
                      <v-layout row wrap>
                        <v-flex xs12 class="pt-2">
                          <h4>Meldingen en notities</h4>
                        </v-flex>
                        <v-flex xs12>
                          <v-btn-toggle
                            class="ma-0 pa-0 mt-1"
                            v-model="selectedClient.icon"
                            mandatory
                            :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary"
                            :background-color="$themes[$store.state.companyTheme][$store.state.themeModus].background"
                          >
                            <v-btn height="40" text :value="null" :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary">
                              Geen
                            </v-btn>
                            <v-btn height="40" value="star" text color="#FFD600">
                              <v-icon small color="#FFD600">mdi-star</v-icon>
                            </v-btn>
                            <v-btn height="40" text value="alert" color="#FF5722">
                              <v-icon small color="#FF5722">mdi-alert</v-icon>
                            </v-btn>
                            <v-btn height="40" text value="flag" :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary">
                              <v-icon small :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary">mdi-flag</v-icon>
                            </v-btn>
                            <v-btn height="40" text value="flash" color="#FFD600">
                              <v-icon small color="#FFD600">mdi-flash</v-icon>
                            </v-btn>
                            <v-btn height="40" text value="clock" :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary">
                              <v-icon small :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary">mdi-clock-outline</v-icon>
                            </v-btn>
                          </v-btn-toggle>
                        </v-flex>
                        <v-flex xs12>
                          <v-text-field
                            class="ma-0 pa-0 mt-1"
                            :label="$t('general.alert', $store.state.locale)"
                            v-model="selectedClient.alert"
                            outlined
                            dense
                            :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" 
                            background-color="background"
                            hide-details
                          ></v-text-field>
                        </v-flex>
                      </v-layout>
                      <v-layout row wrap>
                        <v-flex xs12>
                          <v-textarea
                            :label="$t('clients.notes', $store.state.locale)"
                            v-model="selectedClient.notes"
                            no-resize
                            dense
                            rows="3"
                            :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary"
                            outlined
                            background-color="menu"
                            hide-details
                            class="mt-4"
                          ></v-textarea>
                        </v-flex>
                        <v-flex xs12>
                          <v-checkbox
                            class="ma-0 mt-2 pa-0"
                            :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary"
                            hide-details
                            :label="$t('clients.recieveNewsletter', $store.state.locale)"
                            v-model="selectedClient.emailSubscriptions.newsletter"
                          ></v-checkbox>
                        </v-flex>
                      </v-layout>
                    </div>
                  </v-card>
                  <!-- \ CLIENT BASIC INFORMATION / -->
                    
                  <!-- / CLIENT ADDRESS \ -->
                  <v-card flat :color="$themes[$store.state.companyTheme][$store.state.themeModus].card" class="theme-card theme-shadow mt-4" :class="$store.state.companyTheme">
                    <div class="pa-3">
                      <v-avatar
                        size="36"
                        light
                        style="float: left"
                        class="ma-0 mr-2 custom-avatar-style" 
                        rounded="lg"
                        :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" 
                        :style="{'color': $themes[$store.state.companyTheme][$store.state.themeModus].primary}" 
                        >
                        <v-icon :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary">mdi-map-marker</v-icon>
                      </v-avatar>
                      <h3 class="pt-1 pb-4">{{ $t('clients.contact', $store.state.locale) }}</h3>

                      <v-divider></v-divider>

                      <v-layout class="mt-3">
                        <v-flex xs12>
                          <v-select
                            :label="$t('general.country', $store.state.locale)"
                            v-model="selectedClient.address.country"
                            :items="$countries"
                            :item-text="item => item.countryName[($store.state.locale).toLowerCase()]"
                            item-value="country"
                            required
                            outlined
                            dense
                            :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary"
                            :item-color="$themes[$store.state.companyTheme][$store.state.themeModus].primary"
                            hide-details
                          ></v-select>
                        </v-flex> 
                      </v-layout>
                      <v-layout>
                        <v-flex xs12 md6>
                          <v-text-field
                            :label="$t('general.zipcode', $store.state.locale)"
                            v-model="selectedClient.address.postalCode"
                            outlined
                            dense
                            :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary"
                            background-color="menu"
                            suffix="1234AA"
                            hide-details        
                            @input="checkZipcode()"       
                          ></v-text-field>
                        </v-flex>
                        <v-flex xs6 md3>
                          <v-text-field
                            :label="$t('general.number', $store.state.locale)"
                            number
                            v-model.number="selectedClient.address.number"
                            outlined
                            dense
                            :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary"
                            background-color="menu"
                            hide-details
                          ></v-text-field>
                        </v-flex> 
                        <v-flex xs6 md3>
                          <v-text-field
                            :label="$t('general.numberAdd', $store.state.locale)"
                            v-model="selectedClient.address.numberAddition"
                            outlined
                            dense
                            :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary"
                            background-color="menu"
                            hide-details
                            ></v-text-field>
                        </v-flex>
                      </v-layout>
                      
                      <v-layout>
                        <v-flex xs6>
                          <v-text-field
                            :label="$t('general.street', $store.state.locale)"
                            v-model="selectedClient.address.street"
                            outlined
                            dense
                            :disabled="!addAddressManual"
                            :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary"
                            background-color="menu"
                            hide-details
                          ></v-text-field>
                        </v-flex> 
                        <v-flex xs6>
                          <v-text-field
                            :label="$t('general.city', $store.state.locale)"
                            v-model="selectedClient.address.city"
                            outlined
                            dense
                            :disabled="!addAddressManual"
                            :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary"
                            background-color="menu"
                            hide-details
                          ></v-text-field>
                        </v-flex>
                      </v-layout>
                      <v-layout class="mt-1" v-if="clientAddressChanged">
                        <v-flex xs12 class="text-center">
                          <v-alert v-if="!addAddressManual && addressNotFound" text icon="mdi-alert" class="ma-0 pa-2" type="warning">{{ $t('clients.addressNotFound', $store.state.locale) }}</v-alert>
                          <p :style="{color: $themes[$store.state.companyTheme][$store.state.themeModus].primary }" v-if="!selectedClient.address.postalCode || !selectedClient.address.number"> <v-icon size="18" :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary">mdi-information</v-icon> <i>{{ $t('clients.getAddressHint', $store.state.locale) }}</i></p>
                          <v-btn class="mt-2" v-if="!addAddressManual && ((!addressNotFound && addressChanged) || (addressNotFound && addressChanged))" :disabled="!selectedClient.address.postalCode || !selectedClient.address.number" outlined block small :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" @click="checkAddress">{{ $t('clients.getAddress', $store.state.locale) }}</v-btn>
                          <v-btn class="mt-2" outlined small block v-if="!addAddressManual && (selectedClient.address.country != 'netherlands' || !addressChanged && addressNotFound)" :color="$themes[$store.state.companyTheme][$store.state.themeModus].text" @click="!addAddressManual ? addAddressManual = true : addAddressManual = false">{{ $t('clients.addAddressManual', $store.state.locale) }}</v-btn>
                          <p v-if="addressChecked && !addAddressManual && !addressNotFound && !addressChanged"><v-icon small :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary">mdi-information</v-icon> <i>{{ $t('clients.addressFound', $store.state.locale) }}</i></p>       
                          <p v-if="addAddressManual"><i>{{ $t('clients.addressFoundManual', $store.state.locale) }}</i> <v-btn small icon @click="addAddressManual = false;"><v-icon small :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary">mdi-undo-variant</v-icon></v-btn></p>  
                        </v-flex>    
                      </v-layout>
                    </div>
                    <div :key="addressChecked" v-if="selectedClient.address.coordinates && selectedClient.address.coordinates.length == 2">
                      <GmapMap
                        :center="{lat:selectedClient.address.coordinates[1], lng:selectedClient.address.coordinates[0]}"
                        :zoom="14"
                        :options="{
                          scrollwheel: false,
                          zoomControl: true,
                          mapTypeControl: false,
                          scaleControl: false,
                          streetViewControl: false,
                          rotateControl: false,
                          fullscreenControl: false,
                          disableDefaultUi: false
                        }"
                        map-type-id="terrain"
                        style="width: 100%; height: 200px"
                        >
                        <gmap-custom-marker 
                          :marker="{lat:selectedClient.address.coordinates[1], lng:selectedClient.address.coordinates[0]}"
                          :clickable="false"
                          :draggable="false"
                          >
                          <v-icon large :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary">mdi-map-marker</v-icon>
                        </gmap-custom-marker>

                      </GmapMap>
                    </div>

                    
                  </v-card>
                  <!-- \ CLIENT ADDRESS  / -->

                  <!-- / CLIENT EMAILADDRESSES  \ -->
                  <v-card flat :color="$themes[$store.state.companyTheme][$store.state.themeModus].card" class="theme-card theme-shadow mt-4" :class="$store.state.companyTheme" :key="changes">
                    <div class="pa-3 pb-0">
                      <v-row class="pa-0 ma-0">
                        <v-avatar
                          size="36"
                          light
                          style="float: left"
                          class="ma-0 mr-2 custom-avatar-style" 
                          rounded="lg"
                          :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" 
                          :style="{'color': $themes[$store.state.companyTheme][$store.state.themeModus].primary}" 
                          >
                          <v-icon :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary">mdi-email</v-icon>
                        </v-avatar>
                        <h3 class="pt-1 pb-4">{{ $t('general.emailaddress', $store.state.locale) }}</h3>
                        <v-spacer></v-spacer>
                        <v-btn fab x-small depressed :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" dark @click="addEmailaddress()"><v-icon>mdi-plus</v-icon></v-btn>
                      </v-row>

                      <v-divider></v-divider>
                    </div>

                    <div v-if="!selectedClient.emails || selectedClient.emails.length == 0" class="text-center pa-1 pt-0">
                      <v-avatar 
                        size="46"
                        light
                        class="ma-4 custom-avatar-style" 
                        rounded="lg"
                        :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" 
                        :style="{'color': $themes[$store.state.companyTheme][$store.state.themeModus].primary}" 
                        >
                        <v-icon :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary">mdi-email-multiple-outline</v-icon>
                      </v-avatar>
                      <p>{{ $t('clients.noEmailsAdded', $store.state.locale) }}</p>
                    </div>

                    <div v-if="selectedClient.emails && selectedClient.emails.length > 0">
                      <v-data-table
                        :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary"
                        :headers="emailHeaders"
                        :items-per-page="-1"
                        :items="selectedClient.emails"
                        hide-default-footer
                        class="extra-dense-table"
                        style="background: none"
                        >
                        <template v-slot:item="{item, index}">
                          <tr class="pa-1">
                            <td><v-btn style="text-transform: none" small class="px-2 my-2" text @click="copyTextToClipboard(item.email)">{{ item.email }} <v-icon right small :color="$themes[$store.state.companyTheme][$store.state.themeModus].text">mdi-content-copy</v-icon></v-btn></td>
                            <td class="pr-0" :style="'color:'+ emailScoreColor(item.score) + ';'"
									           @click="emailScoreDialog(item.score, item.error)">{{ item.score }}</td>
                             <td class="text-center px-0">
                              <v-btn icon small @click="openSpellingData(item)"><v-icon  :size="18" :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary">mdi-alphabetical-variant</v-icon></v-btn>
                            </td>
                            <td class="text-center px-0">
                              <v-btn v-if="item.primary" icon small><v-icon  :size="18" :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary">mdi-star</v-icon></v-btn>
                              <v-btn @click="setEmailPrimary(item)" icon small v-else><v-icon :size="18" :color="$themes[$store.state.companyTheme][$store.state.themeModus].textSmooth">mdi-star-outline</v-icon></v-btn>
                            </td>
                            <td class="text-center pl-1">
                              <v-btn icon small @click="removeEmail(item, index)"><v-icon :size="18" :color="$themes[$store.state.companyTheme][$store.state.themeModus].text">mdi-delete</v-icon></v-btn>
                            </td>
                          </tr>
                        </template>
                      </v-data-table>
                      <v-divider></v-divider>
                    </div>
                  </v-card>
                  <!-- \ CLIENT EMAILADDRESSES  / -->
                      
                  <!-- / CLIENT PHONENUMBERS  \ -->
                  <v-card flat :color="$themes[$store.state.companyTheme][$store.state.themeModus].card" class="theme-card theme-shadow mt-4" :class="$store.state.companyTheme">
                    <div class="pa-3">
                      <v-row class="pa-0 ma-0">
                        <v-avatar
                          size="36"
                          light
                          style="float: left"
                          class="ma-0 mr-2 custom-avatar-style" 
                          rounded="lg"
                          :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" 
                          :style="{'color': $themes[$store.state.companyTheme][$store.state.themeModus].primary}" 
                          >
                          <v-icon :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary">mdi-phone</v-icon>
                        </v-avatar>
                        <h3 class="pt-1 pb-4">{{ $t('general.phones', $store.state.locale) }}</h3>
                        <v-spacer></v-spacer>
                        <v-btn fab x-small depressed :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" dark @click="addPhonenumber()"><v-icon>mdi-plus</v-icon></v-btn>
                      </v-row>

                      <v-divider></v-divider>
                    </div>

                    <div v-if="!selectedClient.phones || selectedClient.phones.length == 0" class="text-center pa-1 pt-0">
                      <v-avatar 
                        size="46"
                        light
                        class="ma-4 custom-avatar-style" 
                        rounded="lg"
                        :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" 
                        :style="{'color': $themes[$store.state.companyTheme][$store.state.themeModus].primary}" 
                        >
                        <v-icon :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary">mdi-phone-ring</v-icon>
                      </v-avatar>
                      <p>{{ $t('clients.noPhonenumbersAdded', $store.state.locale) }}</p>
                    </div>

                    <div v-if="selectedClient.phones && selectedClient.phones.length > 0">
                      <v-data-table
                        :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary"
                        :headers="phoneHeaders"
                        :items-per-page="-1"
                        :items="selectedClient.phones"
                        hide-default-footer
                        class="dense-table"
                        style="background: none"
                        >
                        <template v-slot:item="{item, index}">
                          <tr>
                            <td class="text-center">
                              <v-icon :size="18" v-if="item.type == 'mobile'" :color="$themes[$store.state.companyTheme][$store.state.themeModus].text">mdi-cellphone</v-icon>
                              <v-icon :size="18" v-else :color="$themes[$store.state.companyTheme][$store.state.themeModus].text">mdi-deskphone</v-icon>
                            </td>
                            <td v-if="item.region == 'NL'">
                              <v-btn style="text-transform: none" small class="px-2 my-2" text @click="copyTextToClipboard(item.phone.national)">{{ item.phone.national }} <v-icon right small :color="$themes[$store.state.companyTheme][$store.state.themeModus].text">mdi-content-copy</v-icon></v-btn>
                            </td>
                            <td v-else>
                              <v-btn style="text-transform: none" small class="px-2 my-2" text @click="copyTextToClipboard(item.phone.international)">{{ item.phone.international }} <v-icon right small :color="$themes[$store.state.companyTheme][$store.state.themeModus].text">mdi-content-copy</v-icon></v-btn>
                            </td>
                            <td class="text-center">
                              <v-icon v-if="item.primary" :size="18" :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary">mdi-star</v-icon>
                              <v-btn @click="setPhonePrimary(item)" icon small v-else><v-icon :size="18" :color="$themes[$store.state.companyTheme][$store.state.themeModus].textSmooth">mdi-star-outline</v-icon></v-btn>
                            </td>
                            <td>
                              <v-btn icon small @click="removePhone(item, index)"><v-icon :size="18" :color="$themes[$store.state.companyTheme][$store.state.themeModus].text">mdi-delete</v-icon></v-btn>
                            </td>
                          </tr>
                        </template>
                      </v-data-table>
                      <v-divider></v-divider>
                    </div>
                  </v-card>
                  <!-- \ CLIENT PHONENUMBERS  / -->
                </v-flex>

                <v-flex :class="columnWidth" class="pl-2">

                  <!-- / CLIENT APPOINTMENTS  \ -->
                  <v-card flat :color="$themes[$store.state.companyTheme][$store.state.themeModus].card" class="theme-card theme-shadow" :class="$store.state.companyTheme">
                    <div class="pa-0">
                      <div class="pa-3 pb-0">
                        <v-avatar
                          size="36"
                          light
                          style="float: left"
                          class="ma-0 mr-2 custom-avatar-style" 
                          rounded="lg"
                          :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" 
                          :style="{'color': $themes[$store.state.companyTheme][$store.state.themeModus].primary}" 
                          >
                          <v-icon :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary">mdi-calendar-expand-horizontal</v-icon>
                        </v-avatar>
                        <h3 class="pt-1 pb-4">{{ $t('clients.appointments', $store.state.locale) }}</h3>
                        <v-divider class="mb-4 pb-2"></v-divider>
                      </div>

                      

                      <div v-if="historyBookingsLoading">
                        <div style="position: relative; padding: 200px 0;">
                          <div class="thrive-loader" ></div>
                        </div>
                      </div>
                      <div v-if="selectedClientFutureBookingsActive.length > 0 && !historyBookingsLoading">
                        <p style="font-weight: 600; text-align: center;">Er <span style="font-weight: 600;" v-if="selectedClientFutureBookingsActive.length == 1"> is {{ selectedClientFutureBookingsActive.length }} afspraak</span> <span style="font-weight: 600;" v-else> zijn {{ selectedClientFutureBookings.length }} afspraken</span> gepland in de toekomst.</p>
                        <p v-if="selectedClientFutureBookingsActive.length > 3" style="text-align: center; font-style: italic">{{ $t('clients.seeAllComminAppointments', $store.state.locale) }} <strong>{{ $t('clients.scheduledAppointments', $store.state.locale) }} </strong></p>
                        
                        <div v-for="(item, index) in selectedClientFutureBookingsActive" :key="index">
                          <v-card outlined v-if="index < 3" class="pa-2 mb-4 mx-3" >  
                            <p style="font-size: 12px" class="ma-0">{{ item.treatmentStart }}</p>
                            <h3 style="font-size: 16px;">{{  $autoLanguageSelector($store.state.locale, $store.state.companyLanguages, item.treatmentTitle)}}</h3>
                            <div v-for="(branch, index) in $store.state.companyBranches" :key="index" style="font-style: italic;">
                              <v-chip v-if="item.branchId == branch.id" x-small label :color="$themes[$store.state.companyTheme][$store.state.themeModus].tertiary" :text-color="$themes[$store.state.companyTheme][$store.state.themeModus].tertiary" light class="mr-1 my-1 custom-chip-style" >{{ branch.name }}</v-chip>
                            </div>
    
                            <v-btn v-if="type != 'modal'" class="mt-1" dark x-small :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" outlined depressed @click="navigateBooking(item)" height="25">
                              {{ $t('clients.seeInCalendar', $store.state.locale) }}
                              <v-icon right>mdi-chevron-right</v-icon>
                            </v-btn>
                          </v-card>
                        </div>
                      </div>

                      <div v-if="selectedClientFutureBookings.length == 0 && !historyBookingsLoading" class="text-center">
                        <v-avatar
                          size="58"
                          light
                          class="ma-0 mr-2 mb-2 custom-avatar-style" 
                          rounded="lg"
                          :color="$themes[$store.state.companyTheme][$store.state.themeModus].secondary" 
                          :style="{'color': $themes[$store.state.companyTheme][$store.state.themeModus].secondary}" 
                          >
                          <v-icon size="32" :color="$themes[$store.state.companyTheme][$store.state.themeModus].secondary">mdi-calendar-arrow-right</v-icon>
                        </v-avatar>
                        <p class="pb-2">{{ $t('clients.noFurureAppointments', $store.state.locale) }}</p>
                      </div>

                      <div v-if="selectedClientHistoryBookings.length > 0 && !historyBookingsLoading">
                        <div class="px-3">
                          <v-divider></v-divider>    
                        </div>
                             
                        <v-timeline align-top dense class="pa-0 ma-0 pr-3">
                          <v-timeline-item
                            right
                            small
                            hide-dot
                            class="py-0 my-0"
                          >
                          <div class="text-left py-4">
                            <h3>{{ $t('clients.history', $store.state.locale)}}</h3>
                          </div>
                          
                          <v-switch
                            v-if="selectedClientHistoryBookingsDeletedFiltered.length > 0"
                            class="ma-0 mt-4 pa-0"
                            :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary"
                            inset
                            v-model="showDeletedBookings"
                            :label="$t('clients.showRemovedAppointments', $store.state.locale)"
                            ></v-switch>
                          </v-timeline-item>

                          <span v-for="(booking, index) in selectedClientHistoryBookingsFiltered" :key="index">
                            <v-timeline-item    
                              :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary"
                              right
                              fill-dot
                              small
                              >
                              <v-card outlined :color="booking.color" :style="[{'color': booking.color},{'overflow' : 'hidden'}]" class="custom-card-style">
                                <v-card-title class="py-0 px-0">
                                  <v-list two-line flat style="width: 100%" class="pa-0 ma-0">
                                    <v-list-item flat class="pa-0 ma-0 px-3" style="min-height:50px!important" >
                                      <v-list-item-content flat class="pa-0 ma-0" >
                                        <v-list-item-title :style="{'color': booking.color}">
                                          <v-icon  v-if="booking.deleted" :color="booking.color" class="mr-1" size="20">mdi-calendar-remove</v-icon>
                                          <strong>{{ $autoLanguageSelector($store.state.locale, $store.state.companyLanguages, booking.treatmentTitle) }}</strong> 
                                          <v-chip v-if="booking.deleted" x-small label :color="booking.color"  dark class="mx-2 my-0 " >{{ $t('general.removed', $store.state.locale) }}</v-chip>
                                        </v-list-item-title>

                                        <v-list-item-subtitle><span :style="{'color': booking.color}" style="font-size: 12px; line-height: 14px;">{{ booking.treatmentStart }}</span></v-list-item-subtitle>
                                      </v-list-item-content>
                                      <v-list-item-action v-if="!booking.deleted">    
                                        <v-row class="pa-0 ma-0">
                                          <v-tooltip dark left v-if="booking.downPayment && booking.downPayment.active && booking.downPayment.paid">                                
                                            <template v-slot:activator="{on}">
                                              <v-icon v-on="on" :color="booking.color">mdi-cash-check</v-icon>
                                            </template>
                                            <span class="tooltip-text-white">{{ $t('clients.appointmentDownpayment', $store.state.locale) }}</span>
                                          </v-tooltip>
                                          <v-tooltip dark left v-if="booking.downPayment && booking.downPayment.active && !booking.downPayment.paid">                                
                                            <template v-slot:activator="{on}">
                                              <v-icon v-on="on" :color="booking.color">mdi-cash-remove</v-icon>
                                            </template>
                                            <span class="tooltip-text-white">{{ $t('clients.appointmentDownpaymentNoRecieved', $store.state.locale) }}</span>
                                          </v-tooltip>
                                          <v-tooltip dark left v-if="booking.end < now && !booking.paid">
                                            <template v-slot:activator="{on}">
                                              <v-icon v-on="on" :color="$themes[$store.state.companyTheme][$store.state.themeModus].tertiary">mdi-alert</v-icon>
                                            </template>
                                            <span class="tooltip-text-white">{{ $t('clients.appointmentNotPayed', $store.state.locale) }}</span>
                                          </v-tooltip>
                                          <v-tooltip dark left v-if="booking.paid">
                                            <template v-slot:activator="{on}">
                                              <v-icon v-on="on" :color="booking.color" size="20" class="ml-1">mdi-credit-card</v-icon>
                                            </template>
                                            <span class="tooltip-text-white">{{ $t('clients.appointmentPayed', $store.state.locale) }}</span>
                                          </v-tooltip>
                                        </v-row>
                                      </v-list-item-action>

                                      <v-list-item-action v-if="booking.deleted">
                                        <v-row class="ma-0 pa-0">
                                            <v-tooltip dark left v-if="booking.downPayment && booking.downPayment.active && booking.downPayment.paid">                                
                                              <template v-slot:activator="{on}">
                                                <v-icon v-on="on" :color="booking.color">mdi-cash-check</v-icon>
                                              </template>
                                              <span class="tooltip-text-white">{{ $t('clients.appointmentDownpayment', $store.state.locale) }}</span>
                                            </v-tooltip>
                                            <v-tooltip dark left v-if="booking.downPayment && booking.downPayment.active && !booking.downPayment.paid">                                
                                              <template v-slot:activator="{on}">
                                                <v-icon v-on="on" :color="booking.color">mdi-cash-remove</v-icon>
                                              </template>
                                              <span class="tooltip-text-white">{{ $t('clients.appointmentDownpaymentNoRecieved', $store.state.locale) }}</span>
                                            </v-tooltip>
                                            <v-icon v-if="!booking.showInfo" :color="booking.color" @click="booking.showInfo = true">mdi-chevron-down</v-icon>
                                            <v-icon v-if="booking.showInfo" :color="booking.color" @click="booking.showInfo = false">mdi-chevron-up</v-icon>
                                          <!-- </v-col>
                                          <v-col xs6 class="ma-0 py-0 pr-0">
                                            <v-spacer></v-spacer>
                                            <v-tooltip dark left v-if="booking.deletedInfo">
                                              <template v-slot:activator="{on}">
                                                <v-icon v-on="on" :color="$themes[$store.state.companyTheme][$store.state.themeModus].card">mdi-calendar-remove</v-icon>
                                              </template>
                                              <span class="tooltip-text-white">
                                                {{ $t('clients.appointmentRemovedOn', $store.state.locale) }} {{ booking.deletedInfo.timestamp | moment("DD-MM-YYYY H:mm") }}u {{ $t('clients.by', $store.state.locale) }} {{ booking.deletedInfo.user }} 
                                                <span v-if="booking.deletedInfo.userType && booking.deletedInfo.userType == 'employee'">{{ $t('clients.employeeType', $store.state.locale) }}</span>
                                                <span v-if="booking.deletedInfo.userType && booking.deletedInfo.userType == 'client'">{{ $t('clients.clientType', $store.state.locale) }}</span>
                                              </span>
                                            </v-tooltip>
                                            <v-icon v-else :color="$themes[$store.state.companyTheme][$store.state.themeModus].card">>mdi-calendar-remove</v-icon>
                                          </v-col> -->
                                        </v-row>
                                        
                                      </v-list-item-action>
                                    </v-list-item>
                                  </v-list>
                                </v-card-title>
                            
                                <v-card-text v-if="!booking.deleted || booking.showInfo" class="py-2 px-3 ma-0 " style="position: relative" :style="{color: $themes[$store.state.companyTheme][$store.state.themeModus].text, backgroundColor: $themes[$store.state.companyTheme][$store.state.themeModus].card}">
                                  <!-- <div><strong>{{ $t('general.type', $store.state.locale) }} </strong>
                                    <span v-if="booking.bookingwhere == 'bookingapp'">{{ $t('clients.onlineAppointment', $store.state.locale) }}</span>
                                  </div> -->
                                  <div>
                                    <strong>{{ $t('general.branch', $store.state.locale) }}: </strong>
                                    <span v-for="(branch, index) in branches" :key="index">
                                      <span v-if="booking.branchId == branch.id">{{branch.name}}</span>
                                    </span>
                                  </div>
                                  <div>
                                    <strong>{{ $t('general.employees', $store.state.locale) }}: </strong>
                                    {{ getTreatmentEmployeesNames(booking.employees) }}
                                  </div>
                                  <div v-if="booking.treatmentDeviceTypes && booking.treatmentDeviceTypes.length > 0">
                                    <v-card outlined class="mt-3" v-for="(device, i) in booking.treatmentDeviceTypes" :key="i">
                                      <v-expansion-panels flat tile >
                                        <v-expansion-panel >
                                          <v-expansion-panel-header color="background" class="pa-2 py-1" style="font-size: 14px;" >
                                            <span>
                                              <v-icon small v-if="booking.treatmentParameters[device].valid" color="success">mdi-check-circle</v-icon>
                                              <v-icon v-else small color="warning">mdi-alert</v-icon>
                                              <strong> {{ $t('clients.treatmentParameters', $store.state.locale) }} {{ getDeviceName(device) }}</strong>
                                            </span> 
                                          </v-expansion-panel-header>
                                          <v-expansion-panel-content>
                                            <v-divider></v-divider>
                                            <v-container grid-list-xs class="pa-4" fluid>
                                              <v-layout row wrap>

                                                <v-flex xs12 s12 class="pa-1">
                                                  <v-select
                                                    class="mt-1"
                                                    :label="$t('general.device', $store.state.locale)"
                                                    v-model="booking.treatmentParameters[device].device"
                                                    :items="getAvailableDevices(device)"
                                                    item-text="resourceName"
                                                    item-value="id"
                                                    required
                                                    outlined
                                                    dense
                                                    v-on:input="setBookingTreatmentParametersZones(index, device), checkTreatmentParameters(booking, device)"
                                                    background-color="background"
                                                    :item-color="$themes[$store.state.companyTheme][$store.state.themeModus].primary"
                                                    :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary"
                                                    hide-details
                                                  ></v-select>

                                                  <v-select
                                                    class="mt-4"
                                                    :label="$t('clients.zones', $store.state.locale)"
                                                    v-model="booking.treatmentParameters[device].zones"
                                                    :items="zones"
                                                    item-text="name"
                                                    required
                                                    outlined
                                                    multiple
                                                    chips
                                                    dense
                                                    v-on:input="setBookingTreatmentParametersZones(index, device), checkTreatmentParameters(booking, device)"
                                                    return-object
                                                    background-color="background"
                                                    :item-color="$themes[$store.state.companyTheme][$store.state.themeModus].primary"
                                                    :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary"
                                                    hide-details
                                                    >
                                                    <template v-slot:selection="{ item }">
                                                      <v-chip small :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" class="ma-1"><span class="tooltip-text-white">{{ item.name }}</span></v-chip>
                                                    </template>
                                                  </v-select>
                                                </v-flex>
                                              </v-layout>
                                            </v-container>

                                            <v-card outlined class="my-3 mx-2" color="background" v-for="(zone, index) in booking.treatmentParameters[device].zones" :key="index">
                                              <v-card-text class="ma-0 pt-2">
                                                <h3><strong>{{zone.name}}</strong></h3>
                                                <v-layout row wrap>
                                                  <v-flex xs12 class="pt-2 pa-1">
                                                    <v-text-field
                                                      :label="$t('clients.program', $store.state.locale)"
                                                      v-model="booking.treatmentParameters[device].zoneParameters[zone.id].program"
                                                      outlined
                                                      dense
                                                      background-color="menu"
                                                      :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary"
                                                      hide-details
                                                      v-on:input="checkTreatmentParameters(booking, device)"
                                                    ></v-text-field>
                                                  </v-flex>
                                                  <v-flex xs6 class="pa-1">
                                                    <v-text-field
                                                      :label="$t('clients.duration', $store.state.locale)"
                                                      v-model="booking.treatmentParameters[device].zoneParameters[zone.id].time"
                                                      outlined
                                                      dense
                                                      append-icon="mdi-clock-outline"
                                                      background-color="menu"
                                                      :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary"
                                                      hide-details
                                                      v-on:input="checkTreatmentParameters(booking, device)"
                                                    ></v-text-field>
                                                  </v-flex>
                                                  <v-flex xs6 class="pa-1">
                                                    <v-text-field
                                                      :label="$t('clients.intensity', $store.state.locale)"
                                                      v-model.number="booking.treatmentParameters[device].zoneParameters[zone.id].intensity"
                                                      outlined
                                                      suffix="%"
                                                      dense
                                                      background-color="menu"
                                                      :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary"
                                                      hide-details
                                                      v-on:input="checkTreatmentParameters(booking, device)"
                                                    ></v-text-field>
                                                  </v-flex>
                                                </v-layout>
                                              </v-card-text>
                                            </v-card>
                                            <v-container grid-list-xs class="pa-4" fluid>
                                              <v-layout row wrap>
                                                <v-flex xs12 class="pa-1">
                                                  <v-textarea
                                                    :label="$t('clients.notesParameters', $store.state.locale)"
                                                    v-model.number="booking.treatmentParameters[device].notes"
                                                    outlined
                                                    dense
                                                    :rows="3"
                                                    full-width
                                                    background-color="background"
                                                    :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary"
                                                    hide-details
                                                    v-on:input="checkTreatmentParameters(booking, device)"
                                                  ></v-textarea>
                                                </v-flex>
                                                <v-flex xs12 s12 class="pa-1" v-if="booking.changed && editClient">
                                                  <v-btn class="ma-0" rounded depressed dark block :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" @click="updateBookingSummary(booking.id, index)">{{ $t('clients.saveParameters', $store.state.locale) }}</v-btn>
                                                </v-flex>
                                              </v-layout>
                                            </v-container>
      
                                          </v-expansion-panel-content>
                                        </v-expansion-panel>
                                        
                                      </v-expansion-panels>
                                    </v-card>
                                  </div>
                                  
                                  <div v-if="booking.emailHistory && booking.emailHistory.length > 0">
                                    <div class="pt-3 pb-1"><strong>{{ $t('clients.emailsSend', $store.state.locale) }}</strong></div>
                                    <v-card outlined style="overflow: hidden">
                          
                                      <v-expansion-panels flat dense focusable tile >
                                        <v-expansion-panel
                                          v-for="(emailinfo,i) in booking.emailHistory.slice().reverse()"
                                          :key="i"
                                          class="ma-0 pa-0"
                                          >
                                

                                          <div v-if="(!type.showAll && i < 5) || type.showAll">
                                            <v-expansion-panel-header v-if="!emailShowAll && i < 5 || emailShowAll" class="pa-2 py-1" style="font-size: 14px; min-height: 36px">
                                              <v-row no-gutters>
                                                <v-col cols="7">    
                                                  <span style="font-weight: 500;" v-if="emailinfo.messageContentType == 'appointmentReminder'">{{ $t('clients.appointmentReminder', $store.state.locale) }}</span>
                                                  <span style="font-weight: 500;" v-else-if="emailinfo.messageContentType == 'appointmentConfirm' || emailinfo.type == 'eventConfirm'">{{ $t('clients.appointmentConfirmation', $store.state.locale) }}</span>
                                                  <span style="font-weight: 500;" v-else-if="emailinfo.messageContentType == 'appointmentCancel' || emailinfo.type == 'eventCancel'">{{ $t('clients.appointmentCanceled', $store.state.locale) }}</span>
                                                  <span style="font-weight: 500;" v-else-if="emailinfo.messageContentType == 'confirmchangeMail' || emailinfo.type == 'eventChanges'">{{ $t('clients.appointmentChanged', $store.state.locale) }} </span>
                                                  <span style="font-weight: 500;" v-else-if="emailinfo.messageContentType == 'appointmentChanges' || emailinfo.type == 'eventChanges'">{{ $t('clients.appointmentChanged', $store.state.locale) }}</span>
                                                  <span style="font-weight: 500;" v-else-if="emailinfo.messageContentType == 'appointmentConfirmDownPayment'">{{ $t('emails.downPaymentRequest', $store.state.locale) }}</span>
                                                  <span style="font-weight: 500;" v-else-if="emailinfo.messageContentType == 'downPaymentReminder'">{{ $t('clients.downPaymentReminder', $store.state.locale) }}</span>
                                                  <span style="font-weight: 500;" v-else-if="emailinfo.messageContentType == 'appointmentCancelNoDownPayment'">{{ $t('emails.appointmentCancelNoDownPayment', $store.state.locale) }}</span>
                                                  <span style="font-weight: 500;" v-else>
                                                    <span v-if="emailinfo.messageContentType">{{ emailinfo.messageContentType }}</span>
                                                    <span v-else>No Message Content Type</span>
                                                  </span>
                                                </v-col>
                                                <v-col cols="5" class="text--secondary">
                                                  <span v-if="emailinfo.sendDate" style="font-style: italic; font-size: 12px">{{ emailinfo.sendDate | moment("D MMMM - H:mm")}}u</span>
                                                </v-col>
                                              </v-row>
                                            </v-expansion-panel-header>
                                            <v-divider v-if="booking.emailHistory.length > 1 && ((i < (booking.emailHistory.length - 1) && i < 4 && !emailShowAll) || i < (booking.emailHistory.length - 1) && emailShowAll)"></v-divider >
                                            <v-expansion-panel-content v-if="!emailShowAll && i < 5 || emailShowAll">
                                              <div style="padding: 4px 10px 0 10px; font-size: 12px" v-if="emailinfo.succesfullSend == false"><div style="display: inline-block; position: relative; left: 0;margin: 0;" class="circle"></div> {{ $t('clients.emailNotSent', $store.state.locale) }}</div>
                                              <div style="padding: 4px 10px 0 10px; font-size: 12px" v-else><div style="display: inline-block; position: relative; left: 0;margin: 0;" class="circle active"></div> {{ $t('clients.emailSuccessfullySent', $store.state.locale) }}</div>
                                              <div style="padding: 4px 10px 0 10px; font-size: 12px" v-if="emailinfo.opens_count > 0"><div style="display: inline-block; position: relative; left: 0;margin: 0;" class="circle active"></div> {{ $t('clients.emailOpenedOn', $store.state.locale) }} {{ emailinfo.last_event_time | moment("D MMMM - H:mm")}}u</div>
                                              <div style="padding: 4px 10px 0 10px; font-size: 12px" v-else><div style="display: inline-block; position: relative; left: 0;margin: 0;" class="circle"></div> {{ $t('clients.emailNotOpened', $store.state.locale) }}</div>
                                              <v-divider v-if="!emailShowAll && i < 4 || emailShowAll"></v-divider >
                                            </v-expansion-panel-content>
                                          </div>
                                        </v-expansion-panel>
                                        
                                      </v-expansion-panels>
                                    </v-card>
                                    <div style="padding: 10px; text-align: center; font-size: 13px; line-height: 16px;" v-if="(!emailShowAll && booking.emailHistory.length >= 5) || emailShowAll"> 
                                      <v-btn v-if="!emailShowAll" @click="emailShowAll = true" class="ma-3" outlined :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" small>
                                        Alle verzonden emails weergeven (+{{ booking.emailHistory.length - 5 }})
                                        <v-icon right>mdi-chevron-down</v-icon>
                                      </v-btn>
                                      <v-btn v-if="emailShowAll" @click="emailShowAll = false" class="ma-3" text :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" small>
                                        Alleen laatste 5 verzonden emails weergeven
                                      </v-btn>            
                                    </div>
                                  </div>

                                  <div v-else class="pt-3 pb-1"><strong>{{ $t('clients.noEmailSend', $store.state.locale) }}</strong></div>

                                  <div class="mt-1">
                                    <span style="font-style:italic; font-size: 12px; line-height: 14px;">{{ $t('clients.appointmentMade', $store.state.locale) }} 

                                    <span style="font-style:italic; font-size: 12px; line-height: 14px;" v-if="booking.bookingType == 'personal'"> {{ $t('clients.appointmentPersonal', $store.state.locale) }}  </span>
                                    <span style="font-style:italic; font-size: 12px; line-height: 14px;" v-if="booking.bookingType == 'telephone'"> {{ $t('clients.appointmentPhone', $store.state.locale) }}  </span>
                                    <span style="font-style:italic; font-size: 12px; line-height: 14px;" v-if="booking.bookingType == 'widget'"> {{ $t('clients.appointmentWidget', $store.state.locale) }}  </span>
                                    <span style="font-style:italic; font-size: 12px; line-height: 14px;" v-if="booking.bookingType == 'app'"> {{ $t('clients.appointmentApp', $store.state.locale) }}  </span>
                                    {{ $t('clients.appointmentMadeOn', $store.state.locale) }} 
                                    
                                    {{ booking.treatmentCreated }}</span>
                                  </div>

                              </v-card-text>
                              
                              </v-card>
                            </v-timeline-item>
                          </span>
                          
                          <v-timeline-item
                            v-if="selectedUserTreatments.length >= 25"
                            right
                            small
                            hide-dot
                            class="pa-5 my-0"
                            >
                            <div  class="text-center">
                              <v-btn
                                v-if="!extraLoading && moreHistoryTreatmentsAvailable"
                                :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary"
                                @click="getMoreSelectedClientHistoryBookings()"
                                >{{ $t('clients.moreHistory', $store.state.locale) }}</v-btn>
                              <div v-if="extraLoading" class="extra-loading-dots"></div>
                            </div>
                          </v-timeline-item>
                        </v-timeline>
                      </div>
                      <div v-if="selectedClientHistoryBookings.length == 0 && !historyBookingsLoading" class="text-center">
                        <v-avatar
                          size="58"
                          light
                          class="ma-0 mr-2 custom-avatar-style" 
                          rounded="lg"
                          :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" 
                          :style="{'color': $themes[$store.state.companyTheme][$store.state.themeModus].primary}" 
                          >
                          <v-icon size="32" :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary">mdi-calendar-multiple-check</v-icon>
                        </v-avatar>
                        <p class="pt-2 ma-0 mb-2">{{ $t('clients.noHistory', $store.state.locale) }}</p>
                      </div>
                      
                    </div>
                  </v-card>
                  <!-- \ CLIENT APPOINTMENTS / -->

                  <!-- / SALONHUB APPOINTMENTS  \ -->
                  <v-card flat :color="$themes[$store.state.companyTheme][$store.state.themeModus].card" class="theme-card theme-shadow mt-4" :class="$store.state.companyTheme" v-if="selectedClient.salonhubTreatmentHistory.length > 0 && selectedClient.salonhubTreatmentHistory[0].timestamp  > showSalonhubTreatmentsDate"> 
                    <div class="pa-0">
                      <div class="pa-3 pb-0">
                        <v-avatar
                          size="36"
                          light
                          style="float: left; color: #FB8C00"
                          class="ma-0 mr-2 custom-avatar-style" 
                          rounded="lg"
                          color="#FB8C00" 
                
                          >
                          <v-icon color="#FB8C00">mdi-alpha-s-box</v-icon>
                        </v-avatar>
                        <h3 class="pt-1 pb-4">{{ $t('clients.appointments', $store.state.locale) }} Salonhub</h3>
                        <v-divider class="mb-2 pb-2"></v-divider>
                      </div>

                      

                      <div v-for="(treatment, index) in selectedClient.salonhubTreatmentHistory" :key="index">
                        <v-divider v-if="index < 10"></v-divider>
                        <div class="pa-2 px-3" v-if="index < 10">
                          <h4 class="ma-0">{{ treatment.description }}</h4>
                          <v-layout row wrap class="ma-0 pa-0">
                            <v-flex xs6 class="ma-0 pa-0">
                              <p class="ma-0"><strong>Salon:</strong> {{ treatment.salon }}</p>
                            </v-flex>
                            <v-flex xs6 class="ma-0 pa-0">
                              <p class="ma-0"><strong>Prijs:</strong> &euro; {{ treatment.price }}</p>
                            </v-flex>
                            <v-flex xs6 class="ma-0 pa-0">
                              <p class="ma-0"><strong>Door:</strong> {{ treatment.employee }}</p>
                            </v-flex>
                            <v-flex xs6 class="ma-0 pa-0">
                              <p class="ma-0"><strong>Datum:</strong> {{ treatment.timestamp | moment("D MMM YYYY") }}</p>
                            </v-flex>
                          </v-layout>
                        </div>  
                      </div>

                    </div>
                  </v-card>
                   <!-- \ SALONHUB APPOINTMENTS / -->
                </v-flex>
              </v-layout>
            </v-container>
          </div>
          <!-- \ Client Summary / -->

          <!-- / Client History \ -->
          <div v-if="selectedView('history')" class="mt-8">
            <div class="no-data-message text-center" v-if="selectedClientHistory.products.length == 0 && selectedClientHistory.treatments.length == 0 && selectedClient.salonhubTreatmentHistory.length == 0 && selectedClient.salonhubProductHistory.length == 0 && selectedClient.salonhubColoringHistory.length == 0">
              <v-avatar
                size="58"
                light
                class="ma-0 mr-2 custom-avatar-style" 
                rounded="lg"
                :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" 
                :style="{'color': $themes[$store.state.companyTheme][$store.state.themeModus].primary}" 
                >
                <v-icon size="32" :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary">mdi-cart</v-icon>
              </v-avatar>
              <p v-html="$t('clients.noPurchaseHistory', $store.state.locale)"></p>
            </div>

            <v-container v-else grid-list-md>
              <v-layout row wrap>
                <v-flex xs6 v-if="selectedClientHistory.treatments.length > 0" class="px-2 pb-4">
                  <v-card flat :color="$themes[$store.state.companyTheme][$store.state.themeModus].card" class="theme-card theme-shadow" :class="$store.state.companyTheme">
                    <div class="pa-3">
                      <v-avatar
                        size="36"
                        light
                        style="float: left"
                        class="ma-0 mr-2 custom-avatar-style" 
                        rounded="lg"
                        :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" 
                        :style="{'color': $themes[$store.state.companyTheme][$store.state.themeModus].primary}" 
                        >
                        <v-icon :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary">mdi-clipboard-text-outline</v-icon>
                      </v-avatar>
                      <h3 class="pt-1 pb-4">{{ $t('general.treatments', $store.state.locale) }}</h3>
                      <v-divider></v-divider>
                    </div>

                    <v-data-table
                      :headers="historyTreatmentsHeader"
                      :items="selectedClientHistory.treatments"
                      :items-per-page="-1"
                      hide-default-footer
                      dense
                      class="dense-table"
                      >
                      <template v-slot:item="{ item}">
                        <tr>
                          <td>{{ item.date }}</td>
                          <td>{{ getTreatmentName(item.name) }}</td>
                          <td>{{ item.price }}</td>
                          <td>{{ getTreatmentEmployeesNames(item.resourceIds) }}</td>
                        </tr>
                      </template>
                    </v-data-table>
                  </v-card>
                </v-flex>
                <v-flex xs6  v-if="selectedClientHistory.products.length > 0" class="px-2 pb-4">
                  <v-card  flat :color="$themes[$store.state.companyTheme][$store.state.themeModus].card" class="theme-card theme-shadow" :class="$store.state.companyTheme">
                    <div class="pa-3">
                      <v-avatar
                        size="36"
                        light
                        style="float: left"
                        class="ma-0 mr-2 custom-avatar-style" 
                        rounded="lg"
                        :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" 
                        :style="{'color': $themes[$store.state.companyTheme][$store.state.themeModus].primary}" 
                        >
                        <v-icon :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary">mdi-spray-bottle</v-icon>
                      </v-avatar>
                      <h3 class="pt-1 pb-4">{{ $t('general.products', $store.state.locale) }}</h3>
                      <v-divider></v-divider>
                    </div>

                    <v-data-table
                      :headers="historyProductsHeader"
                      :items="selectedClientHistory.products"
                      :items-per-page="-1"
                      hide-default-footer
                      dense
                      class="dense-table"
                      >
                      <template v-slot:item="{ item}">
                        <tr>
                          <td>{{ item.date}}</td>
                          <td>{{ getProductName(item.name) }}</td>
                          <td>{{ item.price }}</td>
                          <td>{{ getEmployeeName(item.resourceId) }}</td>
                        </tr>
                      </template>
                    </v-data-table>
                  </v-card>
                </v-flex>
              </v-layout>

              <v-layout row wrap class="mt-6">
                <v-flex xs6  v-if="selectedClient.salonhubTreatmentHistory.length > 0" class="px-2 pb-4">
                  <v-card  flat :color="$themes[$store.state.companyTheme][$store.state.themeModus].card" class="theme-card theme-shadow" :class="$store.state.companyTheme">
                    <div class="pa-3">
                      <v-avatar
                        size="36"
                        light
                        style="float: left"
                        class="ma-0 mr-2 custom-avatar-style" 
                        rounded="lg"
                        :color="$themes[$store.state.companyTheme][$store.state.themeModus].tertiary" 
                        :style="{'color': $themes[$store.state.companyTheme][$store.state.themeModus].tertiary}" 
                        >
                        <v-icon :color="$themes[$store.state.companyTheme][$store.state.themeModus].tertiary">mdi-clipboard-text-outline</v-icon>
                      </v-avatar>
                      <h3 class="pt-1 pb-4">{{ $t('general.treatments', $store.state.locale) }} (Salonhub)</h3>
                      <v-divider></v-divider>
                    </div>

                    <v-data-table
                      :headers="historyTreatmentsHeader"
                      :items="selectedClient.salonhubTreatmentHistory"
                      :items-per-page="-1"
                      hide-default-footer
                      dense
                      class="dense-table"
                      >
                      <template v-slot:item="{ item}">
                        <tr>
                          <td>{{ transformTimestamp(item.timestamp) }}</td>
                          <td>{{ item.description }}</td>
                          <td>{{ cashConvFilter(item.price, 'EUR') }}</td>
                          <td>{{ item.employee }}</td>    
                        </tr>
                      </template>
                    </v-data-table>
                  </v-card>
                </v-flex>

                <v-flex xs6  v-if="selectedClient.salonhubColoringHistory.length > 0" class="px-2  pb-4">
                  <v-card  flat :color="$themes[$store.state.companyTheme][$store.state.themeModus].card" class="theme-card theme-shadow" :class="$store.state.companyTheme">
                    <div class="pa-3">
                      <v-avatar
                        size="36"
                        light
                        style="float: left"
                        class="ma-0 mr-2 custom-avatar-style" 
                        rounded="lg"
                        :color="$themes[$store.state.companyTheme][$store.state.themeModus].tertiary" 
                        :style="{'color': $themes[$store.state.companyTheme][$store.state.themeModus].tertiary}" 
                        >
                        <v-icon :color="$themes[$store.state.companyTheme][$store.state.themeModus].tertiary">mdi-palette</v-icon>
                      </v-avatar>
                      <h3 class="pt-1 pb-4">{{ $t('general.receipts', $store.state.locale) }} (Salonhub)</h3>
                      <v-divider></v-divider>
                    </div>

                    <v-data-table
                      :headers="historyReceiptsHeader"
                      :items="selectedClient.salonhubColoringHistory"
                      :items-per-page="-1"
                      hide-default-footer
                      dense
                      class="dense-table"
                      >
                      <template v-slot:item="{ item}">
                        <tr>
                          <td>{{ transformTimestamp(item.timestamp) }}</td>
                          <td>{{ item.employee }}</td>
                          <td>{{ item.description }}</td>
                          <td>{{ item.length }}min.</td>
                        </tr>
                      </template>
                    </v-data-table>
                  </v-card>
                </v-flex>

                <v-flex xs6  v-if="selectedClient.salonhubProductHistory.length > 0" class="px-2 pb-4">
                  <v-card  flat :color="$themes[$store.state.companyTheme][$store.state.themeModus].card" class="theme-card theme-shadow" :class="$store.state.companyTheme">
                    <div class="pa-3">
                      <v-avatar
                        size="36"
                        light
                        style="float: left"
                        class="ma-0 mr-2 custom-avatar-style" 
                        rounded="lg"
                        :color="$themes[$store.state.companyTheme][$store.state.themeModus].tertiary" 
                        :style="{'color': $themes[$store.state.companyTheme][$store.state.themeModus].tertiary}" 
                        >
                        <v-icon :color="$themes[$store.state.companyTheme][$store.state.themeModus].tertiary">mdi-spray-bottle</v-icon>
                      </v-avatar>
                      <h3 class="pt-1 pb-4">{{ $t('general.products', $store.state.locale) }} (Salonhub)</h3>
                      <v-divider></v-divider>
                    </div>

                    <v-data-table
                      :headers="historyProductsHeader"
                      :items="selectedClient.salonhubProductHistory"
                      :items-per-page="-1"
                      hide-default-footer
                      dense
                      class="dense-table"
                      >
                      <template v-slot:item="{ item}">
                        <tr>
                          <td>{{ transformTimestamp(item.timestamp) }}</td>
                          <td>{{ item.description }}</td>
                          <td>{{ cashConvFilter(item.price, 'EUR') }}</td>
                          <td>{{ item.employee }}</td>
                        </tr>
                      </template>
                    </v-data-table>
                  </v-card>
                </v-flex>

              </v-layout>

            </v-container>
            
          </div>
          <!-- \ Client History / -->

           <!-- / Client Photos \ -->
          <div v-if="selectedView('photos')" class="mt-8">>
            <v-card flat :color="$themes[$store.state.companyTheme][$store.state.themeModus].card" style="overflow: hidden">

              <div v-if="loadingBodyPictures == true">
                <div style="position: relative; padding: 200px 0;">
                  <div class="thrive-loader"></div>
                </div>
              </div>
              <div v-else>
                <v-container grid-list-md class="pa-0" fluid style="min-height: 100px;">
                  <v-layout row wrap>
                    <v-flex  :class="bodypartsNavigationWidth" xs6>
                      <div class="bodyparts-navigation-wrapper">
                        <div class="bodyparts-navigation-item">
                          <span style="padding: 20px; display: block; font-size: 16px; font-weight: 600;">Selecteer lichaamsdeel</span>
                        </div>
                        <div
                          class="bodyparts-navigation-item clear"
                          :class="[{selected: item.selected}, {empty: item.pictures.length == 0}]" 
                          v-for="(item, id) in bodyPictures"
                          :key="id"
                          @click="selectBodyPictureArea(id)"
                          >
                            <div class="bodypart-icon" :class="[id]"></div>
                            <div class="bodypart-text">
                              <div class="bodypart-title" v-html="item.name"></div>
                              <div class="bodypart-pictures">
                                {{item.pictures.length}}
                                <span v-if="item.pictures.length == 1">foto</span>
                                <span v-else>foto's</span>
                              </div>
                            </div>
                        </div>
                      </div>
                    </v-flex>

                    <v-flex :class="bodypartsContentWidth" xs6>
                      <div class="body-picture-content-wrapper">
                        <div class="client-tab-header clear">
                          <v-tooltip dark left>
                            <template v-slot:activator="{on}">
                              <v-btn
                                v-on="on"
                                fab
                                :dark="editClient"
                                small
                                :disabled="!editClient"
                                style="float: right;"
                                :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" 
                                @click="goToImportPictures()"
                                >
                                <v-icon>mdi-upload</v-icon>
                              </v-btn>
                            </template>
                            <span class="tooltip-text-white">Foto's importeren</span>
                          </v-tooltip>
                        </div>

                        <div v-if="selectedBodyPart && bodyPictures[selectedBodyPart].pictures && bodyPictures[selectedBodyPart].pictures.length > 0">
                          <div class="body-picture-content-title" v-html="bodyPictures[selectedBodyPart].name"></div>

                          <div class="body-picture-content-button-wrapper clear">
                            <div class="body-picture-content-button-inner first preview" :class="{active : pictureViewmode.preview}" @click="selectBodyPictureView('preview', selectedBodyPart)">Bekijken</div>
                            <div class="body-picture-content-button-inner second compare" :class="[{active : pictureViewmode.compare},{disabled : bodyPictures[selectedBodyPart].pictures.length == 1}]"  @click="selectBodyPictureView('compare', selectedBodyPart)">Vergelijken</div>
                            <div class="body-picture-content-button-inner last slider" :class="[{active : pictureViewmode.slider},{disabled : bodyPictures[selectedBodyPart].pictures.length == 1}]"  @click="selectBodyPictureView('slider', selectedBodyPart)">Slider</div>
                          </div>

                          <div v-if="pictureViewmode.preview == true" class="body-picture-content-preview">
                            <div class="main-picture-wrapper" style="max-height: 500px;" >
                              <div class="show-detail-picture" @click="showPictureDetails()"><v-icon :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" large>mdi-information</v-icon></div>
                              <v-img
                                :src="bodyPictures[selectedBodyPart].pictures[bodyPictures[selectedBodyPart].selectedPicture].imageUrl"
                                class="grey lighten-2"
                                max-height='500px'
                                contain
                              >
                                <v-layout
                                  slot="placeholder"
                                  fill-height
                                  align-center
                                  justify-center
                                  ma-0
                                >
                                  <v-progress-circular indeterminate color="grey lighten-5"></v-progress-circular>
                                </v-layout>
                              </v-img>
                              <!-- <div v-bind:class="[picturePositionInfo.position, picturePositionInfo.bodypart]" class="main-picture-bodypart-icon" ></div> -->
                              <div class="main-picture-bodypart-icon" :class="[selectedBodyPart]"></div>
                              <div class="main-picture-date"> {{ bodyPictures[selectedBodyPart].pictures[bodyPictures[selectedBodyPart].selectedPicture].dateAdded.seconds | moment("D MMM YYYY")}}</div>
                            </div>
                            
                            <div class="select-picture-wrapper clear">
                              <carousel :perPage="numberOfPictureThumbs" :mouse-drag="true" :centerMode="true">
                                <slide  v-for="(picture, index) in bodyPictures[selectedBodyPart].pictures" :key="index" >
                                  <div class="select-picture-preview-inner" :class="{active: bodyPictures[selectedBodyPart].selectedPicture == index}" @click="selectPictureForPreview(index)">
                                    <v-img
                                      :src="picture.imageThumbUrl"
                                      aspect-ratio="1"
                                      class="grey lighten-2"
                                      >
                                        <v-layout
                                          slot="placeholder"
                                          fill-height
                                          align-center
                                          justify-center
                                          ma-0
                                        >
                                        <v-progress-circular indeterminate color="grey lighten-5"></v-progress-circular>
                                      </v-layout>
                                    </v-img>
                                    <div class="select-picture-date"> {{ picture.dateAdded.seconds | moment("D MMM YYYY")}}</div> 
                                  </div>
                                </slide>
                              </carousel>
                            </div>
                          </div>

                          <div v-if="pictureViewmode.compare == true" class="body-picture-content-compare">

                            <div class="compare-picture-wrapper clear" :style="{ width: photoCompareWidth + 'px', height: photoCompareWidth * 0.665 + 'px' }">
                              <div class="compare-picture">
                                <div v-if="compareImageOne == null" class="image-compare-empty">
                                  <div class="empty-image-box"></div>
                                  <p>Selecteer afbeelding</p>
                                </div>
                                <div v-if="compareImageOne != null">
                                  <div class="image-selected-number-big left">1</div>
                                  <v-zoomer :style="{ width: photoCompareWidth/ 2 + 'px', height: photoCompareWidth * 1.33 + 'px'}" style="position: relative;">
                                    <v-img
                                      :src="bodyPictures[selectedBodyPart].pictures[compareImageOne].imageUrl"
                                      class="grey lighten-2"
                                    >
                                      <v-layout
                                        slot="placeholder"
                                        fill-height
                                        align-center
                                        justify-center
                                        ma-0
                                      >
                                        <v-progress-circular indeterminate color="grey lighten-5"></v-progress-circular>
                                      </v-layout>
                                    </v-img>
                                  </v-zoomer>
                                </div>
              
                                <!-- <div v-bind:class="[picturePositionInfo.position, picturePositionInfo.bodypart]" class="main-picture-bodypart-icon" ></div> -->
                                <div v-if="compareImageOne != null" class="main-picture-date"> {{ bodyPictures[selectedBodyPart].pictures[compareImageOne].dateAdded.seconds | moment("D MMM YYYY")}}</div>
                              </div>
                              <div class="compare-picture">
                                
                                <div v-if="compareImageTwo == null" class="image-compare-empty">
                                  <div class="empty-image-box"></div>
                                  <p>Selecteer afbeelding</p>
                                </div>
                                <div v-if="compareImageTwo != null" >
                                  <div class="image-selected-number-big right">2</div>
                                  <!-- <v-zoomer :style="{ width: window.width / 2 + 'px', height: previewHeight / 2 + 'px'}" style="; position: relative;"> -->
                                  <v-zoomer :style="{ width: photoCompareWidth/ 2 + 'px', height: photoCompareWidth * 1.33 + 'px'}" style="position: relative;">
                                    <v-img
                                      :src="bodyPictures[selectedBodyPart].pictures[compareImageTwo].imageUrl"
                                      class="grey lighten-2"
                                    >
                                      <v-layout
                                        slot="placeholder"
                                        fill-height
                                        align-center
                                        justify-center
                                        ma-0
                                      >
                                        <v-progress-circular indeterminate color="grey lighten-5"></v-progress-circular>
                                      </v-layout>
                                    </v-img>
                                  </v-zoomer>
                
                                </div>
                
                                <div v-if="compareImageTwo != null" class="main-picture-date"> {{ bodyPictures[selectedBodyPart].pictures[compareImageTwo].dateAdded.seconds | moment("D MMM YYYY")}}</div>
                              </div>
                            </div>

                            <div class="select-picture-wrapper clear">
                              <carousel :perPage="numberOfPictureThumbs" :mouse-drag="true" :centerMode="true">
                                <slide  v-for="(picture, index) in bodyPictures[selectedBodyPart].pictures" :key="index" >
                                  <div class="select-picture-preview-inner" :class="[{compareActiveOne: compareImageOne == index},{compareActiveTwo: compareImageTwo == index}]" @click="selectPictureForCompare(index)">
                                  
                                    <div class="image-selected-number" v-if="index == compareImageOne">1</div>
                                    <div class="image-selected-number" v-if="index == compareImageTwo">2</div>
                                    
                                    <v-img
                                      :src="picture.imageThumbUrl"
                                      aspect-ratio="1"
                                      class="grey lighten-2"
                                      >
                                        <v-layout
                                          slot="placeholder"
                                          fill-height
                                          align-center
                                          justify-center
                                          ma-0
                                        >
                                        <v-progress-circular indeterminate color="grey lighten-5"></v-progress-circular>
                                      </v-layout>
                                    </v-img>
                                    <div class="select-picture-date"> {{ picture.dateAdded.seconds | moment("D MMM YYYY")}}</div> 
                                  </div>
                                </slide>
                              </carousel>
                            </div>

                          </div>

                          <div v-show="pictureViewmode.slider == true" class="body-picture-content-slider">
                        
                            <div id="image-compare-slider" class="clear" :style="{ width: photoCompareWidth / 2 + 'px', height: photoCompareWidth * 0.665 + 'px' }">
                              <div>
                                <div class="image-overlay" v-bind:style="{width: compareWidth + 'px'} "  >
                                  <div class="slider-image-one">
                                    <div v-if="sliderImageOne != null" class="image-selected-number-big left">1</div>
                                    <img
                                      style="position: absolute; top: 0; left: 0; heigth: auto"
                                      v-bind:style="{width: photoCompareWidth / 2 + 'px'} " 
                                      v-if="pictureViewmode.slider && sliderImageOne != null"
                                      :src="bodyPictures[selectedBodyPart].pictures[sliderImageOne].imageUrl"
                                    />
                            
                                    <div v-if="pictureViewmode.slider && sliderImageOne != null" class="slider-picture-date left"> {{ bodyPictures[selectedBodyPart].pictures[sliderImageOne].dateAdded.seconds | moment("D MMM YYYY")}}</div>
                                  </div>
                                </div>
                                <div class="slider-image-two-wrapper" v-bind:style="{width: compareWidthNegative + 'px'}">
                                  <div class="image-selected-number-big right">2</div>
                                  <div v-if="sliderImageTwo == null" class="image-slider-empty">
                                    <div class="empty-image-box"></div>
                                    <p>Selecteer afbeelding</p>
                                  </div>
                                  <img
                                    style="position: absolute; top: 0; right: 0; heigth: auto"
                                    v-if="pictureViewmode.slider && sliderImageTwo != null"
                                    v-bind:style="{width: photoCompareWidth / 2+ 'px'}" 
                                    :src="bodyPictures[selectedBodyPart].pictures[sliderImageTwo].imageUrl"
                                    />
                                  
                                  <div v-if="pictureViewmode.slider && sliderImageTwo != null" class="slider-picture-date right"> {{ bodyPictures[selectedBodyPart].pictures[sliderImageTwo].dateAdded.seconds | moment("D MMM YYYY")}}</div>
                                </div>
                                
                                <div id="image-compare-handle" v-bind:style="{left: compareWidth + 'px'} " >
                                  <div class="border-top"></div>
                                  <div id="handle"></div>
                                  <div class="border-bottom"></div>
                                </div>
                              </div>
                            </div>

                            <div class="select-picture-wrapper clear">
                              <carousel :perPage="numberOfPictureThumbs" :mouse-drag="true" :centerMode="true">
                                <slide  v-for="(picture, index) in bodyPictures[selectedBodyPart].pictures" :key="index" >
                                  <div class="select-picture-preview-inner" :class="[{compareActiveOne: sliderImageOne == index},{compareActiveTwo: sliderImageTwo == index}]" @click="selectPictureForSliderCompare(index)">
                                  
                                    <div class="image-selected-number" v-if="index == sliderImageOne">1</div>
                                    <div class="image-selected-number" v-if="index == sliderImageTwo">2</div>
                                    
                                    <v-img
                                      :src="picture.imageThumbUrl"
                                      aspect-ratio="1"
                                      class="grey lighten-2"
                                      >
                                        <v-layout
                                          slot="placeholder"
                                          fill-height
                                          align-center
                                          justify-center
                                          ma-0
                                        >
                                        <v-progress-circular indeterminate color="grey lighten-5"></v-progress-circular>
                                      </v-layout>
                                    </v-img>
                                    <div class="select-picture-date"> {{ picture.dateAdded.seconds | moment("D MMM YYYY")}}</div> 
                                  </div>
                                </slide>
                              </carousel>
                            </div>

                          </div>

                        </div>

                        <div v-else class="text-center pa-4">
                          <p>Er zijn nog geen foto's gemaakt.</p>
                        </div>

                      </div>

                    </v-flex>

                  </v-layout>
                </v-container>
              </div>

            </v-card>
          </div>
          <!-- \ Client Photos / -->

          <!-- / Client Future \ -->
          <div v-if="selectedView('appointments')" class="mt-11">

            <div v-if="!futureBookingsDataLoading">
              <div class="no-data-message text-center" v-if="selectedClientFutureBookingsFiltered.length == 0">
                <v-avatar
                  size="58"
                  light
                  class="ma-0 mr-2 custom-avatar-style" 
                  rounded="lg"
                  :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" 
                  :style="{'color': $themes[$store.state.companyTheme][$store.state.themeModus].primary}" 
                  >
                  <v-icon size="32" :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary">mdi-calendar</v-icon>
                </v-avatar>
                <p v-html="$t('clients.noFutureAppointments', $store.state.locale)"></p>
              </div>

              <v-card flat v-else :color="$themes[$store.state.companyTheme][$store.state.themeModus].card" class="theme-card theme-shadow" :class="$store.state.companyTheme">
                <v-container grid-list-md class="pa-4" fluid>
                  <v-layout row wrap>
                    <v-flex xs12>
                      <v-switch
                        class="ma-0 pa-0"
                        :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary"
                        inset
                        v-model="showDeletedBookings"
                        :label="$t('clients.showDeletedBookings', $store.state.locale)"
                        hide-details
                        ></v-switch>
                    </v-flex>
                  </v-layout>
                </v-container>

                <v-data-table
                  :headers="futureTreatmentsHeader"
                  :items="selectedClientFutureBookingsFiltered"
                  :items-per-page="-1"
                  :search="futureBookingsSearch"
                  hide-default-footer
                  item-key="name"
                  :no-results-text="`${$t('clients.noResultsFoundFor', $store.state.locale)} ${futureBookingsSearch}`"
                  dense
                  class="dense-table"
                  >
                  <template v-slot:item="{ item}">
                    <tr>
                      <td>
                        <v-tooltip dark right v-if="item.deleted">
                          <template v-slot:activator="{ on }">
                            <v-icon v-on="on" small :color="$themes[$store.state.companyTheme][$store.state.themeModus].secondary" >mdi-calendar-remove</v-icon>
                          </template>
                          <span class="tooltip-text-white">Afspraak is verwijderd</span>
                        </v-tooltip>

                        <v-icon v-else small :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary">mdi-calendar</v-icon>
                        {{ item.treatmentStart }}
                      </td>
                      <td>{{ $autoLanguageSelector($store.state.locale, $store.state.companyLanguages, item.treatmentTitle) }}</td>
                      <td>
                        {{ $store.state.companyBranches.find((branch) => { return item.branchId == branch.id}).name }}
                        <span v-for="(branch, index) in branches" :key="index">
                          <span v-if="item.branch == branch.id"> {{ branch.name }}</span>
                        </span>
                      </td>
                      <td>
                        {{ getTreatmentEmployeesNames(item.employees) }}
                      </td>
                      <td>
                        <v-btn v-if="type != 'modal'" class="my-1" block dark x-small :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" outlined depressed @click="navigateBooking(item)">
                          {{ $t('clients.seeInCalendar', $store.state.locale) }}
                          <v-icon right>mdi-chevron-right</v-icon>
                        </v-btn>
                      </td>
                    </tr>
                  </template>
                </v-data-table>

                <div  class="text-center">
                  <v-btn
                    class="ma-2"
                    v-if="!extraLoading && moreFutureTreatmentsAvailable"
                    :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary"
                    @click="getMoreSelectedClientFutureBookings()"
                    >{{ $t('clients.moreFuture', $store.state.locale) }}</v-btn>
                  <div v-if="extraLoading" class="extra-loading-dots"></div>
                </div>
              </v-card>
            </div>

            <div v-if="selectedClientFutureBookings.length > 0 && futureBookingsDataLoading">
              <div style="position: relative; padding: 200px 0;">
                <div class="thrive-loader"></div>
              </div>
            </div>
          </div>
          <!-- \ Client Future / -->

          <!-- / Client Tags \ -->
          <div v-if="selectedView('tags')">
            <v-layout >
              <v-flex xs6></v-flex>
              <v-flex xs6 style="min-height: 40px;"></v-flex>
            </v-layout>
            <div class="no-data-message text-center" v-if="!selectedClientTags || selectedClientTags.length == 0">
              <v-avatar
                size="58"
                light
                class="ma-0 mr-2 custom-avatar-style" 
                rounded="lg"
                :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" 
                :style="{'color': $themes[$store.state.companyTheme][$store.state.themeModus].primary}" 
                >
                <v-icon size="32" :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary">mdi-tag</v-icon>
              </v-avatar>
              <p>{{ selectedClient.name }} {{ selectedClient.surname }} {{ $t('clients.clientHadNoTags', $store.state.locale) }}</p>
              <v-btn dark :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" @click="addSelectedClientTagDialog()">
                <v-icon left >mdi-plus</v-icon> {{ $t('clients.addNewClientTag', $store.state.locale) }}
              </v-btn>
            </div>

            <v-card flat v-else :color="$themes[$store.state.companyTheme][$store.state.themeModus].card" class="theme-card theme-shadow mt-1" :class="$store.state.companyTheme">
              <v-container grid-list-md class=" ma-0 pa-2 pt-2 pb-0" fluid>
                <v-layout row wrap>
                  <v-flex md4 xs12>
                    <div class="pt-1 pl-1">
                      <v-avatar
                        size="36"
                        light
                        style="float: left"
                        class="ma-0 mr-2 custom-avatar-style" 
                        rounded="lg"
                        :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" 
                        :style="{'color': $themes[$store.state.companyTheme][$store.state.themeModus].primary}" 
                        >
                        <v-icon :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary">mdi-tag</v-icon>
                      </v-avatar>
                      <h3 class="pt-1 pb-4" >Tags</h3>
                    </div>
                  </v-flex>
                  <v-flex md8 xs12 class="text-right">
                    <v-btn 
                      small
                      dark
                      height="36" 
                      :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary"
                      @click="addSelectedClientTagDialog()" 
                      class="mx-1 mt-1"
                      >
                      <v-icon left>mdi-plus</v-icon> {{ $t('clients.newTag', $store.state.locale) }}
                    </v-btn>
                  </v-flex>
                </v-layout>
              </v-container>

              <v-divider> </v-divider>
            
              <v-data-table
                :headers="tagsHeader"
                :items="selectedClientTags"
                :items-per-page="-1"
                hide-default-footer
                item-key="name"
                :no-results-text="`Geen resultaten gevonden voor: ${$t('clients.tags', $store.state.locale)}`"
                dense
                class="dense-table"
                >
                <template v-slot:item="{ item}">
                  <tr>
                    <td><span v-if="item.dateAdded">{{ convertTagDate(item.dateAdded) }}u</span></td>
                    <td>{{ getTagType(item.id).name }}</td>
                    <td>
                      <span v-if="item.name">{{ item.name }}</span>
                      <span v-if="getTagType(item.id).description">{{ getTagType(item.id).description }}</span>       
                    </td>
                    <td>
                      <v-btn class="mt-1" dark small icon :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" @click="removeTagDialog(item)">
                        <v-icon small>mdi-delete</v-icon>
                      </v-btn>
                    </td>
                  </tr>
                </template>
              </v-data-table>

            </v-card>
          </div>
          <!-- \ Client Tags / -->

          <!-- / Client Prepayments \ -->
          <div v-if="selectedView('prepayments')" >
            <v-layout >
              <v-flex xs6></v-flex>
              <v-flex xs6 style="min-height: 40px;"></v-flex>
            </v-layout>
            <div class="no-data-message text-center" v-if="!clientPrePayments || clientPrePayments.length == 0">
              <v-avatar
                size="58"
                light
                class="ma-0 mr-2 custom-avatar-style" 
                rounded="lg"
                :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" 
                :style="{'color': $themes[$store.state.companyTheme][$store.state.themeModus].primary}" 
                >
                <v-icon size="32" :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary">mdi-cash-check</v-icon>
              </v-avatar>
              <p>{{ $t('clients.noPrepaymentsFor', $store.state.locale) }} {{ selectedClient.name }} {{ selectedClient.surname }} </p>
            </div>

            <v-card flat v-else :color="$themes[$store.state.companyTheme][$store.state.themeModus].card" class="theme-card theme-shadow mt-1" :class="$store.state.companyTheme">
              <v-container grid-list-md class=" ma-0 pa-2 pt-2 pb-0" fluid>
                <v-layout row wrap>
                  <v-flex md4 xs12>
                    <div class="pt-1 pl-1">
                      <v-avatar
                        size="36"
                        light
                        style="float: left"
                        class="ma-0 mr-2 custom-avatar-style" 
                        rounded="lg"
                        :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" 
                        :style="{'color': $themes[$store.state.companyTheme][$store.state.themeModus].primary}" 
                        >
                        <v-icon :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary">mdi-cash-check</v-icon>
                      </v-avatar>
                      <h3 class="pt-1 pb-4" >{{ $t('clients.prepayments', $store.state.locale) }}</h3>
                    </div>
                  </v-flex>
                </v-layout>
              </v-container>

              <v-divider> </v-divider>
            
              <v-data-table
                :headers="prePaymentHeaderDynamic"
                :items="clientPrePayments"
                :page.sync="prePaymentsSelectedPage"
                :page-count="prePaymentsSelectorPages"
                :items-per-page="20"
                hide-default-footer
                item-key="name"
                :no-results-text="`Geen resultaten gevonden voor: ${$t('clients.tags', $store.state.locale)}`"
                dense
                class="dense-table"
                >
                <template v-slot:item="{ item}">
                  <tr>
                    <td v-if="($store.state.userRoleNumber < 2 && !$store.state.demoMode)" @click="copyTextToClipboard(item.id , 'downPaymentId')">
                      <span class="info-data"><i>{{ item.id }} </i>  
                        <span v-if="copyType =='downPaymentId' && copyValue == item.id" style="font-style: italic; font-size: 10px!important">Gekopieërd</span>
                        <v-icon v-else size="12" :color="$themes[$store.state.companyTheme][$store.state.themeModus].text">mdi-content-copy</v-icon>
                      </span>
                    </td>
                    <td class="py-1">
                      {{ getPrepaymentType(item.type) }}
                    </td>
                    <td>{{ item.timestamp.seconds | moment("D MMMM YYYY") }}</td>
                    <td>{{ item.timestamp.seconds | moment("H:mm") }}u</td>
                    <td>
                      {{ cashConvFilter( item.amount.number, item.amount.currency) }} 
                    </td>
                    <td class="py-1">
                     <v-chip x-small :color="getPrepaymentStatus(item.paymentStatus, item.status).color" :text-color="getPrepaymentStatus(item.paymentStatus, item.status).color" light class="mr-2 mb-1 custom-chip-style">{{ getPrepaymentStatus(item.paymentStatus, item.status).type.name }}</v-chip>

                     <span v-if="getPrepaymentStatus(item.paymentStatus, item.status).extra"><i>{{ getPrepaymentStatus(item.paymentStatus, item.status).extra }}</i> </span>
                     <span v-if="($store.state.userRoleNumber < 2 && !$store.state.demoMode)"><i>{{ item.paymentStatus }}</i> </span>
                     
              
                    </td>
                    <td class="py-1" v-if="($store.state.userRoleNumber < 2 && !$store.state.demoMode)" @click="copyTextToClipboard(item.id , 'downPaymentId')">
                      <v-btn x-small block outlined :loading="prePaymentCheckData.id == item.id  && prePaymentCheckData.loading" :disabled="prePaymentCheckData.loading" :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" @click="checkPrePaymentStatus(item)" :dark="!prePaymentCheckData.loading">Check status</v-btn>
                      <div class="pt-1" v-if="prePaymentCheckData.id == item.id  && !prePaymentCheckData.loading">
                        <div v-if="prePaymentCheckData.status.status">
                          <strong>Mollie status</strong>:  {{ prePaymentCheckData.status.status}}<br>
                          <span v-if="prePaymentCheckData.status.status == 'paid'"><strong>Mollie methode</strong>:  {{ prePaymentCheckData.status.details.method}}<br></span>
                          <span v-if="prePaymentCheckData.status.status == 'paid'"><strong>Mollie timestamp</strong>:  {{ prePaymentCheckData.status.details.paidAt | moment('YYYY-MM-DDTHH:mm')}}<br></span>
                          <strong>Mollie message</strong>:  {{ prePaymentCheckData.status.message }}
                        </div> 
                        <div v-else>
                          {{ prePaymentCheckData.status}}
                        </div> 
                      </div>
                    </td>
                  </tr>
                </template>
              </v-data-table>
              <v-divider v-if="clientPrePayments.length > 20"></v-divider>
              <div class="text-center pa-4" v-if="clientPrePayments.length > 20">
                
                <v-pagination
                :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary"
                  v-model="prePaymentsSelectedPage"
                  :length="prePaymentsSelectorPages"
                  :total-visible="6"
                ></v-pagination>
              </div>
            </v-card>
          </div>
          <!-- \ Client Prepayments / -->

          <!-- / Client Memberships \ -->
          <div v-if="selectedView('memberships')" >
            <v-layout >
              <v-flex xs6></v-flex>
              <v-flex xs6 style="min-height: 40px;"></v-flex>
            </v-layout>
            <div class="no-data-message text-center" v-if="!clientMemberships || clientMemberships.length == 0">
              <v-avatar
                size="58"
                light
                class="ma-0 mr-2 custom-avatar-style" 
                rounded="lg"
                :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" 
                :style="{'color': $themes[$store.state.companyTheme][$store.state.themeModus].primary}" 
                >
                <v-icon size="32" :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary">mdi-check</v-icon>
              </v-avatar>
              <p>{{ $t('memberships.multiSubscriptions', $store.state.locale) }} {{ selectedClient.name }} {{ selectedClient.surname }} </p>
            </div>

            <v-card flat v-else :color="$themes[$store.state.companyTheme][$store.state.themeModus].card" class="theme-card theme-shadow mt-1" :class="$store.state.companyTheme">
              <v-container grid-list-md class=" ma-0 pa-2 pt-2 pb-0" fluid>
                <v-layout row wrap>
                  <v-flex md4 xs12>
                    <div class="pt-1 pl-1">
                      <v-avatar
                        size="36"
                        light
                        style="float: left"
                        class="ma-0 mr-2 custom-avatar-style" 
                        rounded="lg"
                        :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" 
                        :style="{'color': $themes[$store.state.companyTheme][$store.state.themeModus].primary}" 
                        >
                        <v-icon :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary">mdi-check-bold</v-icon>
                      </v-avatar>
                      <h3 class="pt-1 pb-4" >{{ $t('memberships.multiSubscriptions', $store.state.locale) }}</h3>
                    </div>
                  </v-flex>
                </v-layout>
              </v-container>

              <v-divider> </v-divider>

              <v-data-table 
                :headers="membershipHeader" 
                :items="clientMemberships"
                hide-default-footer 
                dense
                :items-per-page="20"
                :no-results-text="`Geen resultaten gevonden voor: ${$t('clients.tags', $store.state.locale)}`"
                class="dense-table membership-table"
                >
                <template v-slot:item="{ item }">
                  <tr :disabled="item.deleted?.deleted" :class="{'deleted': item.deleted?.deleted}">
                    <td><v-icon class="px-2" :color="activeColor(item.status.status)" small>mdi-checkbox-blank-circle</v-icon></td>
                    <td>
                      <strong>{{ item.name[$store.state.locale.toLowerCase()] }}</strong>
                    </td>
                    <td>{{ item.start | moment("D MMMM YYYY")}}</td>
                    <td>
                      <div v-if="item.status.endDate">
                        {{ item.expireDate | moment("D MMMM YYYY")}}
                      </div>
                      <v-chip v-else small block label color="#56ca00" text-color="#56ca00" light class="ml-1 ma-0 px-1 custom-chip-style" >{{ $t('memberships.autoRenewShort', $store.state.locale) }}</v-chip>
                    </td>

                    <td>
                      <span v-if="item.usageNumber">{{ item.usageNumber }}x</span>
                    </td>
                    <td>
                      <span v-if="item.usageLeft">{{ item.usageLeft }}x</span>
                    </td>
                    <td>
                      <v-btn block small outlined class="my-2" @click="openMembershipInfo(item)" :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary">
                        {{ $t('payment.viewMembership', $store.state.locale) }} 
                      </v-btn>
                    </td>
                  </tr>
                </template>
              </v-data-table>

            </v-card>
          </div>
          <!-- \ Client Memberships / -->

          <!-- / Client Forms \ -->
          <div v-show="selectedView('forms')">
            <client-forms
              :clientInfo="selectedClient"
              :activeBranch="activeBranch"
              :loadedVia="'clientCardCalendar'"
            ></client-forms>
          </div>
          <!-- \ Client Forms / -->

          <!-- / Connected Clientcards \ -->
          <div v-show="selectedView('connected')">
            <v-layout >
              <v-flex xs6></v-flex>
              <v-flex xs6 style="min-height: 40px;"></v-flex>
            </v-layout>
           
            <div v-if="!selectedClientsLoading">
              <v-card flat v-if="clientsConnected && clientsConnected.length > 0" :color="$themes[$store.state.companyTheme][$store.state.themeModus].card" class="theme-card theme-shadow mt-1" :class="$store.state.companyTheme">
                <v-container grid-list-md class=" ma-0 pa-2 pt-2 pb-0" fluid>
                  <v-layout row wrap>
                    <v-flex md6 xs12>
                      <div class="pt-1 pl-1">
                        <v-avatar
                          size="36"
                          light
                          style="float: left"
                          class="ma-0 mr-2 custom-avatar-style" 
                          rounded="lg"
                          :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" 
                          :style="{'color': $themes[$store.state.companyTheme][$store.state.themeModus].primary}" 
                          >
                          <v-icon :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary">mdi-human-female-boy</v-icon>
                        </v-avatar>
                        <h3 class="pt-1 pb-4" >
                          {{ selectedClient.name }} {{ selectedClient.surname }} {{ $t('clients.clientsConnectedTitle', $store.state.locale) }}
                        </h3>
                      </div>
                    </v-flex>
                    <v-flex md6 xs12 class="text-right">
                      <v-btn 
                        small
                        dark
                        height="36" 
                        :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary"
                        @click="connectSelectedClientToClient('minor')" 
                        class="mx-1 mt-1"
                        >
                        <v-icon left>mdi-plus</v-icon> {{ $t('clients.connectToClient', $store.state.locale) }}
                      </v-btn>
                    </v-flex>
                  </v-layout>
                </v-container>

                <v-divider> </v-divider>
              
                <v-data-table
                  :headers="clientHeaders"
                  :items="clientsConnected"
                  :items-per-page="-1"
                  hide-default-footer
                  item-key="id"
                  :no-results-text="`Geen resultaten gevonden voor: ${$t('clients.tags', $store.state.locale)}`"
                  dense
                  class="dense-table"
                  >
                  <template v-slot:item="{ item}">
                    <tr @click="selectUser(item)">
                      <td>                    
                        <div style="display: block; float: left;" v-html="genAvatar(item.name + ' ' + item.surname)" class="avatarGenTable" ></div>
                        <div style="display: block; float: left;">
                          <v-icon v-if="item.minor" class="mt-1 ml-2" :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" >mdi-human-female-boy</v-icon>
                        </div>
                      </td>
                      <td>{{ item.name }}</td>
                      <td>{{ item.surname }}
                        <v-chip v-if="item.company" x-small :color="$themes[$store.state.companyTheme][$store.state.themeModus].tertiary" :text-color="$themes[$store.state.companyTheme][$store.state.themeModus].tertiary" light class="mx-1 my-1 custom-chip-style" ><v-icon class="ml-0" small :color="$themes[$store.state.companyTheme][$store.state.themeModus].tertiary" left>mdi-domain</v-icon>{{ item.company }}</v-chip>
                        <v-chip v-if="item.tags && item.tags.length > 0" x-small :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" :text-color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" light class="mx-1 my-1 custom-chip-style" >Lead</v-chip>
                      </td>
                      <td>
                        <span v-for="(phone, index) in item.phones" :key="index">
                          <span v-if="phone.primary">{{ phone.phone.national }}</span>
                        </span>
                      </td>
                      <td>
                        <span v-for="(email, index) in item.emails" :key="index">
                          <span v-if="email.primary">{{ email.email }}</span>
                        </span>
                      </td>
                      <td>
                        <span v-for="gender in genders" :key="gender.id">
                          <span v-if="gender.id == item.gender">{{ gender.name }}</span>
                        </span>
                      </td>

                      <td class="text-right">
                        <v-btn icon @click="removeConnectedClientToClient(item.id)">
                          <v-icon small :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary">mdi-delete</v-icon>
                        </v-btn>
                      </td>
                    </tr>
                  </template>
                </v-data-table>
                <v-divider v-if="clientPrePayments.length > 20"></v-divider>
                <div class="text-center pa-4" v-if="clientPrePayments.length > 20">
                  
                  <v-pagination
                  :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary"
                    v-model="prePaymentsSelectedPage"
                    :length="prePaymentsSelectorPages"
                    :total-visible="6"
                  ></v-pagination>
                </div>
              </v-card>

              <v-card flat v-if="connectedClients && connectedClients.length > 0" :color="$themes[$store.state.companyTheme][$store.state.themeModus].card" class="theme-card theme-shadow mt-1" :class="$store.state.companyTheme">
                <v-container grid-list-md class=" ma-0 pa-2 pt-2 pb-0" fluid>
                  <v-layout row wrap>
                    <v-flex md8 xs12 >
                      <div class="pt-1 pl-1">
                        <v-avatar
                          size="36"
                          light
                          style="float: left"
                          class="ma-0 mr-2 custom-avatar-style img-hor-vert" 
                          rounded="lg"
                          :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" 
                          :style="{'color': $themes[$store.state.companyTheme][$store.state.themeModus].primary}" 
                          >
                          <v-icon :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary">mdi-human-female-boy</v-icon>
                        </v-avatar>
                        <h3 class="pt-1 pb-4" > {{ $t('clients.connectedClientsTitle', $store.state.locale) }} {{ selectedClient.name }} {{ selectedClient.surname }}</h3>
                      </div>
                    </v-flex>
                    <v-flex md4 xs12 class="text-right">
                      <!-- <v-btn 
                        small
                        dark
                        height="36" 
                        :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary"
                        @click="connectSelectedClientToClient('guardian')" 
                        class="mx-1 mt-1"
                        >
                        <v-icon left>mdi-plus</v-icon> {{ $t('clients.connectToSelectedClient', $store.state.locale) }}
                      </v-btn> -->
                    </v-flex>
                  </v-layout>
                </v-container>

                <v-divider> </v-divider>
              
                <v-data-table
                  :headers="clientHeaders"
                  :items="connectedClients"
                  :items-per-page="-1"
                  hide-default-footer
                  item-key="name"
                  :no-results-text="`Geen resultaten gevonden voor: ${$t('clients.tags', $store.state.locale)}`"
                  dense
                  class="dense-table"
                  >
                  <template v-slot:item="{ item}">
                    <tr @click="selectUser(item)">
                      <td>                    
                        <div style="display: block; float: left;" v-html="genAvatar(item.name + ' ' + item.surname)" class="avatarGenTable" ></div>
                        <div style="display: block; float: left;">
                          <v-icon v-if="item.minor" class="mt-1 ml-2" :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" >mdi-human-female-boy</v-icon>
                        </div>
                      </td>
                      <td>{{ item.name }}</td>
                      <td>{{ item.surname }}
                        <v-chip v-if="item.company" x-small :color="$themes[$store.state.companyTheme][$store.state.themeModus].tertiary" :text-color="$themes[$store.state.companyTheme][$store.state.themeModus].tertiary" light class="mx-1 my-1 custom-chip-style" ><v-icon class="ml-0" small :color="$themes[$store.state.companyTheme][$store.state.themeModus].tertiary" left>mdi-domain</v-icon>{{ item.company }}</v-chip>
                        <v-chip v-if="item.tags && item.tags.length > 0" x-small :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" :text-color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" light class="mx-1 my-1 custom-chip-style" >Lead</v-chip>
                      </td>
                      <td>
                        <span v-for="(phone, index) in item.phones" :key="index">
                          <span v-if="phone.primary">{{ phone.phone.national }}</span>
                        </span>
                      </td>
                      <td>
                        <span v-for="(email, index) in item.emails" :key="index">
                          <span v-if="email.primary">{{ email.email }}</span>
                        </span>
                      </td>
                      <td>
                        <span v-for="gender in genders" :key="gender.id">
                          <span v-if="gender.id == item.gender">{{ gender.name }}</span>
                        </span>
                      </td>
                      <td>
                        <v-btn icon>
                          <v-icon :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary">mdi-delete</v-icon>
                        </v-btn>
                      </td>
                    </tr>
                  </template>
                </v-data-table>
                <v-divider v-if="clientPrePayments.length > 20"></v-divider>
                <div class="text-center pa-4" v-if="clientPrePayments.length > 20">
                  
                  <v-pagination
                  :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary"
                    v-model="prePaymentsSelectedPage"
                    :length="prePaymentsSelectorPages"
                    :total-visible="6"
                  ></v-pagination>
                </div>
              </v-card>

              <div class="no-data-message text-center" v-if="(!clientsConnected || clientsConnected.length == 0) && (!connectedClients || connectedClients.length == 0)">
                <v-avatar
                    size="58"
                    light
                    class="ma-0 mr-2 custom-avatar-style" 
                    rounded="lg"
                    :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" 
                    :style="{'color': $themes[$store.state.companyTheme][$store.state.themeModus].primary}" 
                    >
                    <v-icon size="32" :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary">mdi-human-female-boy</v-icon>
                  </v-avatar>
                  <p class="ma-0">{{ $t('clients.noClientsConnections', $store.state.locale) }} </p>

                  <v-btn 
                    small
                    dark
                    height="36" 
                    :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary"
                    @click="connectSelectedClientToClient('minor')" 
                    class="mx-1 mt-0"
                    >
                    <v-icon left>mdi-plus</v-icon> {{ $t('clients.connectToClient', $store.state.locale) }}
                  </v-btn>

                  <!-- <v-btn 
                    small
                    dark
                    outlined
                    height="36" 
                    :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary"
                    @click="connectSelectedClientToClient('guardian')" 
                    class="mx-1 mt-4"
                    >
                    <v-icon left>mdi-plus</v-icon> {{ $t('clients.connectToSelectedClient', $store.state.locale) }}
                  </v-btn> -->
              </div>
              <div v-else>
                <div class="no-data-message text-center" v-if="!clientsConnected || clientsConnected.length == 0">
                  <v-avatar
                    size="58"
                    light
                    class="ma-0 mr-2 custom-avatar-style" 
                    rounded="lg"
                    :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" 
                    :style="{'color': $themes[$store.state.companyTheme][$store.state.themeModus].primary}" 
                    >
                    <v-icon size="32" :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary">mdi-human-female-boy</v-icon>
                  </v-avatar>
                  <p class="ma-0">{{ selectedClient.name }} {{ selectedClient.surname }}  {{ $t('clients.noClientsConnected', $store.state.locale) }} </p>
                  <v-btn 
                    small
                    dark
                    height="36" 
                    :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary"
                    @click="connectSelectedClientToClient('minor')" 
                    class="mx-1 mt-0"
                    >
                    <v-icon left>mdi-plus</v-icon> {{ $t('clients.connectToClient', $store.state.locale) }}
                  </v-btn>
                </div>

                <div class="no-data-message text-center" v-if="!connectedClients || connectedClients.length == 0">
                  <v-avatar
                    size="58"
                    light
                    class="ma-0 mr-2 custom-avatar-style img-hor-vert" 
                    rounded="lg"
                    :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" 
                    :style="{'color': $themes[$store.state.companyTheme][$store.state.themeModus].primary}" 
                    >
                    <v-icon size="32" :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary">mdi-human-female-boy</v-icon>
                  </v-avatar>
                  <p class="ma-0">{{ $t('clients.noConnectedClients', $store.state.locale) }} {{ selectedClient.name }} {{ selectedClient.surname }}.</p>
                  <!-- <v-btn 
                    small
                    dark
                    outlined
                    height="36" 
                    :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary"
                    @click="connectSelectedClientToClient('guardian')" 
                    class="mx-1 mt-0"
                    >
                    <v-icon left>mdi-plus</v-icon> {{ $t('clients.connectToSelectedClient', $store.state.locale) }}
                  </v-btn> -->
                </div>
              </div>
            </div>

            <div v-if="selectedClientsLoading">
              <div style="position: relative; padding: 200px 0;">
                <div class="thrive-loader"></div>
              </div>
            </div>
          </div>
          <!-- \ Connected Clientcards / -->

          <!-- / Client Body analysis \ -->
          <div v-if="selectedView('body')">
            <v-card flat :color="$themes[$store.state.companyTheme][$store.state.themeModus].card">
              <v-container grid-list-md class="pa-4" fluid>
                <v-layout row wrap>
                  <v-flex xs6>
                    <h2>Lichaamsanalyse</h2>
                  </v-flex>
                  <v-flex xs6 >
                    <v-tooltip dark left v-if="bodyCompositions.length > 0 && !loadingCompositions && !retryLoadingCompositions && editClient">
                    <template v-slot:activator="{on}">
                      <v-btn
                        v-on="on"
                        v-if="bodyCompositions.length > 0" 
                        fab dark 
                        small
                        depressed
                        style="float: right;"
                        :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary"
                        @click="goToNewComposition()"
                        >
                        <v-icon darken>mdi-plus</v-icon>
                      </v-btn>
                    </template>
                    <span class="tooltip-text-white">Voeg een nieuwe lichaamssamenstelling toe.</span>
                  </v-tooltip>
                  </v-flex>
                  <v-flex xs12 v-if="!editClient">
                    <v-alert
                      dense
                      outlined
                      :border="'left'"
                      type="warning"
                      >
                      Geen bevoegdheid om te bewerken.
                    </v-alert>
                  </v-flex>
                </v-layout>
              </v-container>

              <div v-if="loadingCompositions">
                <div style="position: relative; padding: 200px 0;">
                  <div class="thrive-loader"></div>
                </div>
              </div>

              <div v-if="retryLoadingCompositions && !loadingCompositions" class="reload-button">
                <p>Gegevens ophalen niet gelukt. Probeer opnieuw.</p>
                <v-btn fab dark :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" @click="loadCompositions()">
                  <v-icon dark>mdi-plus</v-icon>
                </v-btn>
              </div>

              <div v-if="bodyCompositions.length == 0 && !loadingCompositions && !retryLoadingCompositions" class="text-center pa-4">
                <v-btn fab dark :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" @click="goToNewComposition()" class="ma-4">
                  <v-icon>mdi-plus</v-icon>
                </v-btn>
                <p class="pa-4">Voeg de eerste meting van {{ selectedClient.name }} {{ selectedClient.surname }} toe.</p>
              </div>

              <div class="composition-history-wrapper" v-if="!loadingCompositions && bodyCompositions.length > 0 ">
                <div class="composition-history-chart-wrapper">
                  <div class="measurement-chart" v-if="chartData.length > 1">
                    <GChart type="ColumnChart" :data="chartData" :options="chartOptions"/>
                  </div>
                </div>

                <h1>Details samenstelling</h1>
                
                <div class="choose-view-type-wrapper">
                  <div class="choose-view-type-buttons clear">
                    <div class="view-type-button one" v-bind:class="{active: kgView}" @click="setKg()">KG</div>
                    <div class="view-type-button two" v-bind:class="{active: percentageView}" @click="setPercentage()" >Percentages %</div>
                  </div>
                </div>

                <v-data-table
                  :headers="headersCompositions"
                  :items="bodyCompositions"
                  item-key="index"
                  sort-by="unix"
                  :sort-desc="false"
                  :items-per-page="-1"
                  hide-default-footer
                  dense
                  class="dense-table"
                  >
                  <template v-slot:item="{item}">
                    <tr>
                      <td>
                        <span class="composition-type" v-if="item.type == 'bia'">BIA</span>
                        <span class="composition-type" v-if="item.type == 'skinfold'">HP</span>
                      </td>
                      <td class="measure-date">{{ item.date }} {{ item.time }}</td>
                      <td>
                        <span class="measure-number">{{ item.weight}}</span>
                        <span class="measure-unit">kg</span>
                      </td>
                      <td>
                        <span v-if="kgView == true && item.fat.weight" class="measure-number">
                          {{ item.fat.weight }}
                          <span class="measure-unit">kg</span>
                        </span>
                        <span
                          v-if="kgView == true && !item.fat.weight"
                          class="measure-number"
                        >-</span>
                        <span
                          v-if="percentageView == true && item.fat.percentage"
                          class="measure-number"
                        >
                          {{ item.fat.percentage }}
                          <span class="measure-unit">%</span>
                        </span>
                        <span
                          v-if="percentageView == true && !item.fat.percentage"
                          class="measure-number"
                        >-</span>
                      </td>
                      <td>
                        <span v-if="item.type == 'skinfold'" class="measure-number">-</span>
                        <span v-else>
                          <span
                            v-if="kgView == true && item.visFat.weight"
                            class="measure-number"
                          >
                            {{ item.visFat.weight }}
                            <span class="measure-unit">kg</span>
                          </span>
                          <span
                            v-if="kgView == true && !item.visFat.weight"
                            class="measure-number"
                          >-</span>
                          <span
                            v-if="percentageView == true && item.visFat.percentage"
                            class="measure-number"
                          >
                            {{ item.visFat.percentage }}
                            <span class="measure-unit">%</span>
                          </span>
                          <span
                            v-if="percentageView == true && !item.visFat.percentage"
                            class="measure-number"
                          >-</span>
                        </span>
                      </td>
                      <td>
                        <span v-if="item.type == 'skinfold'" class="measure-number">-</span>
                        <span v-else>
                          <span
                            v-if="kgView == true && item.muscle.weight"
                            class="measure-number"
                          >
                            {{ item.muscle.weight }}
                            <span class="measure-unit">kg</span>
                          </span>
                          <span
                            v-if="kgView == true && !item.muscle.weight"
                            class="measure-number"
                          >-</span>
                          <span
                            v-if="percentageView == true && item.muscle.percentage"
                            class="measure-number"
                          >
                            {{ item.muscle.percentage }}
                            <span class="measure-unit">%</span>
                          </span>
                          <span
                            v-if="percentageView == true && !item.muscle.percentage"
                            class="measure-number"
                          >-</span>
                        </span>
                      </td>
                      <td>
                        <span v-if="item.type == 'skinfold'" class="measure-number">-</span>
                        <span v-else>
                          <span
                            v-if="kgView == true && item.bone.weight"
                            class="measure-number"
                          >
                            {{ item.bone.weight }}
                            <span class="measure-unit">kg</span>
                          </span>
                          <span
                            v-if="kgView == true && !item.bone.weight"
                            class="measure-number"
                          >-</span>
                          <span
                            v-if="percentageView == true && item.bone.percentage"
                            class="measure-number"
                          >
                            {{ item.bone.percentage }}
                            <span class="measure-unit">%</span>
                          </span>
                          <span
                            v-if="percentageView == true && !item.bone.percentage"
                            class="measure-number"
                          >-</span>
                        </span>
                      </td>
                      <td>
                        <span v-if="item.type == 'skinfold'" class="measure-number">-</span>
                        <span v-else>
                          <span
                            v-if="kgView == true && item.water.weight"
                            class="measure-number"
                          >
                            {{ item.water.weight }}
                            <span class="measure-unit">kg</span>
                          </span>
                          <span
                            v-if="kgView == true && !item.water.weight"
                            class="measure-number"
                          >-</span>
                          
                          <span
                            v-if="percentageView == true && item.water.percentage"
                            class="measure-number"
                          >
                            {{ item.water.percentage }}
                            <span class="measure-unit">%</span>
                          </span>
                          <span
                            v-if="percentageView == true && !item.water.percentage"
                            class="measure-number"
                          >-</span>
                        </span>
                      </td>
                      <td>
                        <v-btn icon class="mx-0" v-if="editClient" @click="editComposition(item.id)">
                          <v-icon small color="text">mdi-pencil</v-icon>
                        </v-btn>
                      </td>
                      <td>
                        <v-btn icon class="mx-0" v-if="editClient" @click="removeCompositionDialog(item)">
                          <v-icon small color="text">mdi-delete</v-icon>
                        </v-btn>
                      </td>
                    </tr>
                  </template>
                </v-data-table>
              </div>
            </v-card>
          </div>
          <!-- \ Client Body analysis / -->
                      
          <!-- / Client Measurements \ -->
          <div v-if="selectedView('measurements')">
            <v-card flat :color="$themes[$store.state.companyTheme][$store.state.themeModus].card">
              <v-container grid-list-md class="pa-4" fluid>
                <v-layout row wrap>
                  <v-flex xs6>
                    <h2>Omvangsmetingen</h2>
                  </v-flex>
                  <v-flex xs6>
                    <v-tooltip dark left v-if="measurements.length > 0 && !loadingMeasurements && !retryLoadingMeasurements && editClient">
                    <template v-slot:activator="{on}">
                      <v-btn
                        v-on="on"
                        v-if="measurementsDataAvaiable.length > 0" 
                        fab dark 
                        small
                        depressed
                        style="float: right;"
                        :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" 
                        @click="goToNewMeasurement()"
                        >
                        <v-icon darken>mdi-plus</v-icon>
                      </v-btn>
                    </template>
                    <span class="tooltip-text-white">Voeg een nieuwe omvangsmeting toe.</span>
                  </v-tooltip>
                  </v-flex>
                  <v-flex xs12 v-if="!editClient">
                    <v-alert
                      dense
                      outlined
                      :border="'left'"
                      type="warning"
                      >
                      Geen bevoegdheid om te bewerken.
                    </v-alert>
                  </v-flex>
                </v-layout>
              </v-container>

              <div v-if="loadingMeasurements == true">
                <div style="position: relative; padding: 200px 0;">
                  <div class="thrive-loader"></div>
                </div>
              </div>

              <div v-if="retryLoadingMeasurements== true && loadingMeasurements == false" class="reload-button">
                <p>Gegevens ophalen niet gelukt. Probeer opnieuw.</p>
                <v-btn fab dark :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" @click="loadMeasurements()">
                  <v-icon dark>mdi-plus</v-icon>
                </v-btn>
              </div>

              <div v-if="measurementsDataAvaiable.length == 0 && loadingMeasurements == false && retryLoadingMeasurements == false" class="text-center pa-4">
                <v-btn fab dark :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" @click="goToNewMeasurement()" class="ma-4">
                  <v-icon dark>mdi-plus</v-icon>
                </v-btn>
                <p class="pa-4">Voeg de eerste omvangsmeting van {{ selectedClient.name }} {{ selectedClient.surname }} toe.</p>
              </div>

              <div v-if="measurementsDataAvaiable.length > 0 && !loadingMeasurements && !retryLoadingMeasurements" class="measurement-items-wrapper"  v-bind:class="activeGender">
      
                <v-expansion-panels accordion>
                  <v-expansion-panel v-for="(measurement, index) in measurementsDataAvaiable" :key="index">
                    <v-expansion-panel-header color="background">
                      <div class="measurement-item-icon" :class="measurement.class"></div>
                      <div class="measurement-item-text">{{ measurement.name }}</div>
                    </v-expansion-panel-header>
                    <v-expansion-panel-content>
                      <div class="measurement-chart" v-if="measurement.data.length > 1">
                        <GChart
                          type="LineChart"
                          :data="chartMeasureData[measurement.id]"
                          :options="chartOptionsMeasurements"
                        />
                      </div>
                      <p class="measurement-numbers">Aantal metingen: {{ measurement.data.length }}</p>
                      <v-data-table
                        :headers="headersMeasurement"
                        :items="measurement.data"
                        :items-per-page="-1"
                        sort-by="unix"
                        :sort-desc="false"
                        hide-default-footer
                        dense
                        class="dense-table"
                        >
                        <template v-slot:item="{item}">
                          <tr>
                            <td class="measure-date">{{ item.date }} <span v-if="item.time">- {{item.time}}u</span></td>
                            <td class="measure-number">{{ item.value }} <span class="measure-unit">cm</span></td>
                            <td>
                              <v-btn icon class="mx-0" @click="editMeasurement(item.id)" v-if="editClient">
                                <v-icon small color="text">mdi-pencil</v-icon>
                              </v-btn>
                            </td>
                            <td>
                              <v-btn icon class="mx-0" @click="removeMeasurementDialog(item)" v-if="editClient">
                                <v-icon small color="text">mdi-delete</v-icon>
                              </v-btn>
                            </td>
                          </tr>
                        </template>
                      </v-data-table>
                    </v-expansion-panel-content>
                  </v-expansion-panel>
                </v-expansion-panels>

              </div>
            </v-card>
          </div>
          <!-- \ Client Measurements / -->

        </div>

      </div>

    </div>

    <div v-else>
      <v-alert dense outlined :border="'left'" type="warning">{{ $t('general.noEditPermission', $store.state.locale)}}</v-alert>
    </div>

    <!-- / CONNECT CLIENT DIALOG \ -->
    <v-dialog v-model="connectClientModal.dialog" width="500" :content-class="`custom-dialog ${$store.state.companyTheme}`">
      <v-card :content-class="`custom-dialog ${$store.state.companyTheme}`">

        <v-card-title class="pr-2" :style="{ backgroundColor: $themes[$store.state.companyTheme][$store.state.themeModus].cardHeader }">
          <h3>{{ $t("clients.connectToClientCard", $store.state.locale) }}</h3>
          <v-spacer></v-spacer>
          <v-btn icon dark @click="closeConnectSelectedClientToClient()">
            <v-icon dark :color="$themes[$store.state.companyTheme][$store.state.themeModus].text">mdi-close</v-icon>
          </v-btn>
        </v-card-title>

        <v-divider></v-divider>

        <v-card-text>
          <div style="text-align: center!important;">
            <p v-html='$t("clients.connectToClientCardHint", $store.state.locale)'></p>
          </div>

          <div v-if="!connectClientModal.clientData">
            <clientSearch :source="'clientInformation'" :exclude="clientsExcluded" @clientSelect="setConnectClient"></clientSearch>  
          </div>
          <v-card outlined v-if="connectClientModal.clientData">
            <v-list class="pa-0 ma-0">
              <v-list-item class="pa-2 py-1 ma-0">
                <v-list-item-avatar color="#f1f1f1">
                  <div class="avatarGen header-avatar" v-html="genAvatar(connectClientModal.clientData.firstName + ' ' + connectClientModal.clientData.surname)"></div>
                  <!-- <v-icon>mdi-account</v-icon> -->
                </v-list-item-avatar>

                <v-list-item-content class="py-1">
                  <v-list-item-title>
                    {{ connectClientModal.clientData.name }} {{ connectClientModal.clientData.surname }}
                    <v-chip x-small v-if="connectClientModal.clientData.gender">{{ getGender(connectClientModal.clientData.gender) }}</v-chip>
                  </v-list-item-title>
                  <v-list-item-subtitle v-if="connectClientModal.clientData.emails">
                    <span v-for="(email, index) in connectClientModal.clientData.emails" :key="index">
                      <span v-if="email.primary"><v-icon size="12" :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" >mdi-email</v-icon> {{ email.email }} </span>
                    </span>
                  </v-list-item-subtitle>
                  <v-list-item-subtitle v-if="connectClientModal.clientData.phones">
                    <span v-for="(phone, index) in connectClientModal.clientData.phones" :key="index">
                      <span v-if="phone.primary"><v-icon size="12" :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" >mdi-phone</v-icon> {{ phone.phone.international }}</span>
                    </span>
                  </v-list-item-subtitle>
                </v-list-item-content>          

                <v-list-item-action>
                  <v-btn icon @click="removeConnectClient()">
                    <v-icon text>mdi-close</v-icon>
                  </v-btn>
                </v-list-item-action>
              </v-list-item>
            </v-list>
          </v-card>
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-container grid-list-md fluid class="ma-0 pa-0">
            <v-layout row wrap class="ma-0 pa-0">
              <v-flex xs12 md6 class="text-left">
                <v-btn
                  outlined
                  depressed
                  :color="$themes[$store.state.companyTheme][$store.state.themeModus].text"
                  @click="closeConnectSelectedClientToClient()"
                  >
                  {{ $t('general.cancel', $store.state.locale) }}
                </v-btn>
              </v-flex>
              <v-flex xs12 md6 class="text-right">
                <v-btn 
                  :dark="connectClientModal.clientData != null"
                  :disabled="!connectClientModal.clientData || !connectClientModal.clientData.objectID"
                  :loading="connectClientModal.loading"
                  :block="$vuetify.breakpoint.name == 'sm' || $vuetify.breakpoint.name == 'xs'"
                  :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" 
                  @click="saveConnectSelectedClientToClient()"
                  >
                  {{ $t('general.connect', $store.state.locale) }}  
                  <v-icon right dark>mdi-chevron-right</v-icon>
                </v-btn>
              </v-flex>
            </v-layout>
          </v-container>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- \ CONNECT CLIENT DIALOG / -->

    <!-- / DELETE TAG DIALOG \ -->
    <v-dialog v-model="deletedSelectedTagDialog" width="350" :content-class="`custom-dialog ${$store.state.companyTheme}`">
      <v-card :content-class="`custom-dialog ${$store.state.companyTheme}`">

        <v-card-title class="pr-2" :style="{ backgroundColor: $themes[$store.state.companyTheme][$store.state.themeModus].cardHeader }">
          <h3>{{ $t("tags.removeTag", $store.state.locale) }}</h3>
          <v-spacer></v-spacer>
          <v-btn icon dark @click="deletedSelectedTagDialog = false">
            <v-icon dark :color="$themes[$store.state.companyTheme][$store.state.themeModus].text">mdi-close</v-icon>
          </v-btn>
        </v-card-title>

        <v-divider></v-divider>

        <v-card-text>
          <div style="text-align: center!important;">
            {{ $t("tags.removeTagInfo", $store.state.locale) }}
          </div>
          <div class="mt-4" v-if="deletedSelectedTagData.leadDocId" style="text-align: center!important;" v-html="$t('tags.removeTagInfoLead', $store.state.locale)"></div>
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-container grid-list-md fluid class="ma-0 pa-0">
            <v-layout row wrap class="ma-0 pa-0">
              <v-flex xs12 md6 class="text-left">
                <v-btn
                  outlined
                  depressed
                  :color="$themes[$store.state.companyTheme][$store.state.themeModus].text"
                  @click="deletedSelectedTagDialog = false"
                  >
                  {{ $t('general.cancel', $store.state.locale) }}
                </v-btn>
              </v-flex>
              <v-flex xs12 md6 class="text-right">
                <v-btn
                  dark  
                  :block="$vuetify.breakpoint.name == 'sm' || $vuetify.breakpoint.name == 'xs'"
                  :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" 
                  @click="removeTag()"
                  >
                  {{ $t('general.remove', $store.state.locale) }}  
                  <v-icon right dark>mdi-chevron-right</v-icon>
                </v-btn>
              </v-flex>
            </v-layout>
          </v-container>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- \ DELETE TAG DIALOG / -->

    <!-- / DELETE PICTURE DIALOG \ -->
    <v-dialog v-model="deleteSelectedPictureDialog" width="350" :content-class="`custom-dialog ${$store.state.companyTheme}`">
      <v-card class="dialog-round-wrapper">
      
        <v-card-title class="hogans-modal-header">
          <h2>Foto verwijderen</h2>
          <div class="hogans-modal-header-close" @click="deleteSelectedPictureDialog = false"></div>
        </v-card-title>

        <v-divider></v-divider>

        <v-card-text>
          <span style="text-align: center!important;">De foto zal worden verwijderd. Dit kan niet ongedaan worden gemaakt.</span>
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-container grid-list-md fluid class="ma-0 pa-0">
            <v-layout row wrap class="ma-0 pa-0">
              <v-flex xs12 md6 class="text-left">
                <v-btn
                  outlined
                  depressed
                  color="tertiary"
                  @click="deleteSelectedPictureDialog = false"
                  >
                  Annuleren
                </v-btn>
              </v-flex>
              <v-flex xs12 md6 class="text-right">
                <v-btn
                  dark
                  rounded
                  color="secondary"
                  :block="$vuetify.breakpoint.name == 'sm' || $vuetify.breakpoint.name == 'xs'"
                  @click="deletePicture()"
                  >
                  Verwijderen  
                </v-btn>
              </v-flex>
            </v-layout>
          </v-container>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- \ DELETE PICTURE DIALOG / -->

    <!-- / DELETE COMPOSITION DIALOG \ -->
    <v-dialog v-model="deleteSelectedCompositionDialog" width="350" :content-class="`custom-dialog ${$store.state.companyTheme}`">
      <v-card class="dialog-round-wrapper">
      
        <v-card-title class="hogans-modal-header">
          <h2>Lichaamsanalyse verwijderen</h2>
          <div class="hogans-modal-header-close" @click="deleteSelectedCompositionDialog = false"></div>
        </v-card-title>

        <v-divider></v-divider>

        <v-card-text>
          <span style="text-align: center!important;">De lichaamsanalyse zal worden verwijderd. Dit kan niet ongedaan worden gemaakt.</span>
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-container grid-list-md fluid class="ma-0 pa-0">
            <v-layout row wrap class="ma-0 pa-0">
              <v-flex xs12 md6 class="text-left">
                <v-btn
                  outlined
                  depressed
                  color="tertiary"
                  @click="deleteSelectedCompositionDialog = false"
                  >
                  Annuleren
                </v-btn>
              </v-flex>
              <v-flex xs12 md6 class="text-right">
                <v-btn
                  dark
                  rounded
                  color="secondary"
                  :block="$vuetify.breakpoint.name == 'sm' || $vuetify.breakpoint.name == 'xs'"
                  @click="removeComposition()"
                  >
                  Verwijderen  
                </v-btn>
              </v-flex>
            </v-layout>
          </v-container>
        </v-card-actions>

      </v-card>
    </v-dialog>
    <!-- \ DELETE COMPOSITION DIALOG / -->

    <!-- / DELETE MEASUREMENTS DIALOG \ -->
    <v-dialog v-model="deleteSelectedMeasurementDialog" width="350" :content-class="`custom-dialog ${$store.state.companyTheme}`">
      <v-card class="dialog-round-wrapper">
      
        <v-card-title class="hogans-modal-header">
          <h2>Omvangsmeting verwijderen</h2>
          <div class="hogans-modal-header-close" @click="deleteSelectedMeasurementDialog = false"></div>
        </v-card-title>

        <v-divider></v-divider>

        <v-card-text>
          <span style="text-align: center!important;">De omvangsmeting zal worden verwijderd. Dit kan niet ongedaan worden gemaakt.</span>
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-container grid-list-md fluid class="ma-0 pa-0">
            <v-layout row wrap class="ma-0 pa-0">
              <v-flex xs12 md6 class="text-left">
                <v-btn
                  outlined
                  depressed
                  color="tertiary"
                  @click="deleteSelectedCompositionDialog = false"
                  >
                  Annuleren
                </v-btn>
              </v-flex>
              <v-flex xs12 md6 class="text-right">
                <v-btn
                  dark
                  rounded
                  color="secondary"
                  :block="$vuetify.breakpoint.name == 'sm' || $vuetify.breakpoint.name == 'xs'"
                  @click="removeMeasurement()"
                  >
                  Verwijderen  
                </v-btn>
              </v-flex>
            </v-layout>
          </v-container>
        </v-card-actions>

      </v-card>
    </v-dialog>
    <!-- \ DELETE MEASUREMENTS DIALOG / -->

    <!-- / PICTURE DETAILS DIALOG \ -->
    <v-dialog v-model="pictureDetailsDialog" scrollable max-width="500px" :content-class="`custom-dialog ${$store.state.companyTheme}`">
      <v-card v-if="pictureDetailsDialog">
        
        <v-toolbar :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" dark>
          <v-app-bar-nav-icon @click="deleteSelectedPictureDialog = true">
            <v-icon dark>mdi-delete</v-icon>
          </v-app-bar-nav-icon>
          <v-toolbar-title>Foto details</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn icon @click="pictureDetailsDialog = false">
            <v-icon dark>mdi-close</v-icon>
          </v-btn>
        </v-toolbar>

        <v-alert
          class="pa-2 ma-0"
          dismissible
          v-if="pictureDetailsFeedback.message"
          ::value="true"
          :type="pictureDetailsFeedback.type"
        >
        {{ pictureDetailsFeedback.message }} 
        </v-alert>

        <v-divider></v-divider>

      <v-card-text class="pa-0" style="margin: -1px 0 0 0">
        <div id="import-details-wrapper" class="content bg-grey">
          <div class="image-details-wrapper" >
            
            <div class="image-preview-wrapper">
              <div class="image-detail-picture" v-if="bodyPictures[selectedBodyPart].pictures.length > 0">
                <v-img :src="bodyPictures[selectedBodyPart].pictures[bodyPictures[selectedBodyPart].selectedPicture].imageUrl" class="grey lighten-2" max-height="400">
                  <v-layout slot="placeholder" fill-height align-center justify-center ma-0>
                    <v-progress-circular indeterminate color="grey lighten-5"></v-progress-circular>
                  </v-layout>
                </v-img>

                <div class="main-picture-date"> {{ bodyPictures[selectedBodyPart].pictures[bodyPictures[selectedBodyPart].selectedPicture].dateAdded.seconds | moment("D MMM YYYY")}}</div>
              </div>
            </div>
  
            <div class="image-detail-notes">
              <h2>Notitie</h2>
              <p ma-0>Schrijf een korte notitie</p>
              <v-textarea class="notes-textarea ma-0" outlined background-color="background" no-resize counter="180" rows="3" maxlength="180" v-model="pictureDetailsNotesClone"></v-textarea>
              <v-btn block :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" :depressed="!notesChanged"  :disabled="!notesChanged" @click="saveSelectedPictureNotes()">Notities opslaan</v-btn>   
            </div>
            <div class="image-detail-specs">
              <h2>Foto specificaties</h2>
              <p class="subtitle ma-0">Gedetaileerde specificaties per foto</p>
              <div class="image-spec-item-wrapper clear">
                <div class="image-spec-item-icon client"></div>
                <div class="image-spec-item-text">
                  <p class="image-spec-item-text-title ma-0">Clientnaam</p>
                  <p class="image-spec-item-text-data ma-0">{{ selectedClient.name }} {{ selectedClient.surname }}</p>
                </div>
              </div>

              <div class="image-spec-item-wrapper clear">
                <div class="image-spec-item-icon email"></div>
                <div class="image-spec-item-text">
                  <p class="image-spec-item-text-title ma-0">Client e-mail</p>
                  <p class="image-spec-item-text-data ma-0">{{ selectedClient.email}} </p>
                </div>
              </div>

              <div class="image-spec-item-wrapper clear">
                <div class="image-spec-item-icon prof-id"></div>
                <div class="image-spec-item-text">
                  <p class="image-spec-item-text-title ma-0">Professional</p>
                  <p class="image-spec-item-text-data ma-0">{{ bodyPictures[selectedBodyPart].pictures[bodyPictures[selectedBodyPart].selectedPicture].professional }} - {{ $store.state.activeCompany.name }} </p>
                </div>
              </div>

              <div class="image-spec-item-wrapper clear">
                <div class="image-spec-item-icon bodypart"></div>
                <div class="image-spec-item-text">
                  <p class="image-spec-item-text-title ma-0">Lichaamsdeel</p>
                  <p class="image-spec-item-text-data ma-0">
                    <span v-for="bodypart in bodyparts" :key="bodypart.name">
                      <span v-if="bodypart.id == bodyPictures[selectedBodyPart].pictures[bodyPictures[selectedBodyPart].selectedPicture].bodypart "> {{ bodypart.name }}</span>
                    </span>
                    -
                    <span v-for="position in positions" :key="position.name">
                      <span v-if="position.id == bodyPictures[selectedBodyPart].pictures[bodyPictures[selectedBodyPart].selectedPicture].position "> {{ position.name }}</span>
                    </span>
                  </p>
                </div>
              </div>

              <div class="image-spec-item-wrapper clear">
                <div class="image-spec-item-icon image-size"></div>
                <div class="image-spec-item-text">
                  <p class="image-spec-item-text-title ma-0">Afmetingen foto</p>
                  <p class="image-spec-item-text-data ma-0">{{bodyPictures[selectedBodyPart].pictures[bodyPictures[selectedBodyPart].selectedPicture].imageSize}} px</p>
                </div>
              </div>

              <div class="image-spec-item-wrapper clear">
                <div class="image-spec-item-icon device"></div>
                <div class="image-spec-item-text">
                  <p class="image-spec-item-text-title ma-0">Device</p>
                  <p class="image-spec-item-text-data ma-0">{{bodyPictures[selectedBodyPart].pictures[bodyPictures[selectedBodyPart].selectedPicture].device}}</p>
                </div>
              </div>

              <div class="image-spec-item-wrapper clear">
                <div class="image-spec-item-icon software"></div>
                <div class="image-spec-item-text">
                  <p class="image-spec-item-text-title ma-0">Software versie</p>
                  <p class="image-spec-item-text-data ma-0">{{bodyPictures[selectedBodyPart].pictures[bodyPictures[selectedBodyPart].selectedPicture].software}}</p>
                </div>
              </div>

            </div>
          </div>
        </div>
        </v-card-text>

        <v-divider></v-divider>
      </v-card>
    </v-dialog>
    <!-- \ PICTURE DETAILS DIALOG / -->

    <!-- / Ideal Weight Dialog \ -->
    <v-dialog v-model="idealWeightExplanationDialog" width="400" :content-class="`custom-dialog ${$store.state.companyTheme}`">
      <v-card class="dialog-round-wrapper">
        <v-card-title class="hogans-modal-header">
          <h2>Ideale gewicht berekenen</h2>
          <div class="hogans-modal-header-close" @click="idealWeightExplanationDialog = false"></div>
        </v-card-title>

        <v-divider></v-divider>

        <v-card-text>
          <div class="explain-weigth-woman"></div>
          <p >Doormiddel van de lichaamslengte en polsomvang wordt de lichaamsbouw berekend. Het ideale gewicht van de cli&euml;nt wordt vastgesteld aan de hand van de lichaamslengte en lichaamsbouw.</p>
        </v-card-text>

      </v-card>
    </v-dialog>
    <!-- \ Ideal WeightDialog / -->

    <!-- / New Client Composition Dialog \ -->
    <v-dialog v-model="addClientCompositionDialog" scrollable max-width="600px" :content-class="`custom-dialog ${$store.state.companyTheme}`">
      <add-composition-dialog
        :clientInfo="selectedClient"
        :$store.state.activeCompany="$store.state.activeCompany"
        @closeAddBodyCompositionDialogChange="addClientCompositionDialog = $event"
        @updateData="loadCompositions()"
      ></add-composition-dialog>
    </v-dialog>
    <!-- \ New Client Composition Dialog / -->

    <!-- / Edit Client Composition Dialog \ -->
    <v-dialog v-model="editClientCompositionDialog" scrollable max-width="600px" :content-class="`custom-dialog ${$store.state.companyTheme}`">
      <edit-composition-dialog
        :clientInfo="selectedClient"
        :selectedComposition="selectedComposition"
        :selectedCompositionOriginal="selectedCompositionOriginal"
        :date="selectedCompositionDate"
        :dateOriginal="selectedCompositionDateOriginal"
        :$store.state.activeCompany="$store.state.activeCompany"
        @closeEditBodyCompositionDialogChange="editClientCompositionDialog = $event"
        @updateData="loadCompositions()"
        @clearEditCompositionData="clearEditCompositionData()"
      ></edit-composition-dialog>
    </v-dialog>
    <!-- \ Edit Client Composition Dialog / -->

    <!-- / New Client Measurement Dialog \ -->
    <v-dialog v-model="addClientMeasurementDialog" scrollable max-width="600px" :content-class="`custom-dialog ${$store.state.companyTheme}`">
      <add-measurement-dialog
        :clientInfo="selectedClient"
        :$store.state.activeCompany="$store.state.activeCompany"
        @closeAddBodyMeasurementDialogChange="addClientMeasurementDialog = $event"
        @updateData="loadMeasurements()"
      ></add-measurement-dialog>
    </v-dialog>
    <!-- \ New Client Measurement Dialog / -->

    <!-- / Edit Client Composition Dialog \ -->
    <v-dialog v-model="editClientMeasurementDialog" scrollable max-width="600px" :content-class="`custom-dialog ${$store.state.companyTheme}`">
      <edit-measurement-dialog
        :clientInfo="selectedClient"
        :$store.state.activeCompany="$store.state.activeCompany"
        :selectedMeasurement="selectedMeasurement"
        :selectedMeasurementOriginal="selectedMeasurementOriginal"
        :date="selectedMeasurementDate"
        :dateOriginal="selectedMeasurementDateOriginal"
        @closeEditBodyMeasurementDialogChange="editClientMeasurementDialog = $event"
        @updateData="loadMeasurements()"
        @clearEditMeasurementData="clearEditMeasurementData()"
      ></edit-measurement-dialog>
    </v-dialog>
    <!-- \ Edit Client Composition Dialog / -->

    <!-- / Import Pictures Dialog \ -->
    <v-dialog v-model="importPicturesDialog" scrollable max-width="600px" :content-class="`custom-dialog ${$store.state.companyTheme}`">
      <import-pictures-dialog
        :clientInfo="selectedClient"
        :$store.state.activeCompany="$store.state.activeCompany"
        @closeImportPicturesDialogChange="importPicturesDialog = $event"
        @updateData="loadBodyPictures()"
      ></import-pictures-dialog>
    </v-dialog>
    <!-- \ Import Pictures Dialog / -->

    <!-- / Add New Client Tag Dialog \ -->
    <v-dialog v-model="newTag.dialog" scrollable max-width="600px" :content-class="`custom-dialog ${$store.state.companyTheme}`">
      <v-card>
        <v-card-title class="pr-2" :style="{ backgroundColor: $themes[$store.state.companyTheme][$store.state.themeModus].cardHeader }">
          <h3>{{ $t('clients.addNewClientTag', $store.state.locale) }}</h3>
          <v-spacer></v-spacer>
          <v-btn icon dark @click="closeAddTagDialog()">
            <v-icon dark :color="$themes[$store.state.companyTheme][$store.state.themeModus].text">mdi-close</v-icon>
          </v-btn>
        </v-card-title>

        <v-alert v-if="newTag.feedback" class="ma-0 pa-2" style="border-radius: 0" type="warning">{{ newTag.feedback }}</v-alert>

        <v-divider></v-divider>

        <v-card-text>
          <v-container grid-list-md class="ma-0 pa-0">
            <v-layout row wrap class="pt-5">
              <v-flex xs12>
                <v-select
                  outlined
                  :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary"
                  :item-color="$themes[$store.state.companyTheme][$store.state.themeModus].primary"
                  background-color="background"
                  :item-text="item =>  $autoLanguageSelector($store.state.nativeLanguage, $store.state.companyLanguages, item.name)"
                  :hint="$autoLanguageSelector($store.state.nativeLanguage, $store.state.companyLanguages, newTag.type.description) ? $autoLanguageSelector($store.state.nativeLanguage, $store.state.companyLanguages, newTag.type.description) : ''"
                  persistent-hint
                  item-value="id"
                  dense
                  return-object
                  :items="tagTypes"
                  v-model="newTag.type"
                  label="Type"
                  v-on:input="getLeadCampaigns()"
                ></v-select>
                <v-select
                  class="mt-4"
                  v-if="newTag.type =='lead' && leadCampaigns.length > 0"
                  outlined
                  dense
                  :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary"
                  :item-color="$themes[$store.state.companyTheme][$store.state.themeModus].primary"
                  background-color="background"
                  item-text="name"
                  item-value="id"
                  hide-details
                  :items="leadCampaigns"
                  v-model="newTag.id"
                  label="Campagne"
                  clearable
                  v-on:input="setTagName()"
                  :loading="campaignsLoading"
                ></v-select>
              </v-flex>
              <v-flex xs12 v-if="newTag.type =='lead'">
                <v-switch
                  :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary"
                  label="Lead toevoegen aan leadoverzicht"
                  v-model="newTag.createLeadDoc"
                  hide-details
                ></v-switch>
              </v-flex>
              <v-flex xs12 v-if="newTag.createLeadDoc">
                <v-select
                  class="mt-4"
                  outlined
                  dense
                  :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary"
                  :item-color="$themes[$store.state.companyTheme][$store.state.themeModus].primary"
                  background-color="background"
                  item-text="name"
                  item-value="id"
                  hide-details
                  :items="branches"
                  v-model="newTag.branch"
                  label="Vestiging"
                ></v-select>
              </v-flex>
              <v-flex xs12>
                <v-text-field
                  v-if="type == 'lead'"
                  outlined
                  dense
                  :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary"
                  :item-color="$themes[$store.state.companyTheme][$store.state.themeModus].primary"
                  background-color="background"
                  label="Naam"
                  hide-details
                  class="mt-4"
                  :read-only="tagNameDisabled"
                  :disabled="tagNameDisabled"
                  v-model="newTag.name"
                ></v-text-field>
              </v-flex>
            </v-layout>
          </v-container>
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-container grid-list-md fluid class="ma-0 pa-0">
            <v-layout row wrap class="ma-0 pa-0">
              <v-flex xs12 md4 class="text-left" v-if="$vuetify.breakpoint.name != 'sm' && $vuetify.breakpoint.name != 'xs'">
                <v-btn
                  outlined
                  depressed
                  :color="$themes[$store.state.companyTheme][$store.state.themeModus].text"
                  @click="closeAddTagDialog()"
                  >
                  {{ $t('general.cancel', $store.state.locale)}}
                </v-btn>
              </v-flex>
              <v-flex xs12 md8 class="text-right">
                <v-btn
                  :block="$vuetify.breakpoint.name == 'sm' || $vuetify.breakpoint.name == 'xs'"
                  :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary"
                  dark
                  @click="addselectedClientTag()"
                  >
                  {{ $t('general.add', $store.state.locale)}}
                  <v-icon right dark>mdi-chevron-right</v-icon>
                </v-btn>
              </v-flex>
            </v-layout>
          </v-container>
        </v-card-actions>
      </v-card>

    </v-dialog>
    <!-- \ Add New Client Tag Dialog / -->

    <!-- / New Phonenumber Dialog \ -->
    <add-phonenumber-dialog :type="'clientInformation' + componentType" @newPhoneNumber="addNewPhonenumber($event)"></add-phonenumber-dialog>
    <!-- \ New Phonenumber Dialog / --> 
    
    <!-- / New Email Dialog \ -->
    <add-emailaddress-dialog :type="'clientInformation' + componentType" @newEmailaddress="addNewEmailaddress($event)"></add-emailaddress-dialog>
    <!-- \ New Email Dialog / -->    

    <!-- / score Dialog \ -->
		<v-dialog v-model="dialogScore" max-width="350">
			<v-card>
				<v-card-title class="text-h5">Email score information</v-card-title>

				<v-card-text v-if="scoreInfo.error == null">
					Email score is: {{ scoreInfo.score }}
					Everything looks fine!
				</v-card-text>
				<v-card-text v-else>
					Email score is: {{ scoreInfo.score }}
					That is not good!
					<div class="codeBlockError">

            {{ $autoLanguageSelector($store.state.nativeLanguage, $store.state.companyLanguages, scoreInfo.error) }}
						

					</div>
				</v-card-text>

				<v-card-actions>
					<v-spacer></v-spacer>

					<v-btn color="primary" text @click="dialogScore = false" :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary">Ok</v-btn>
				</v-card-actions>
			</v-card>
		</v-dialog>
		<!-- \ score Dialog / -->

  </div>
</template>
    
<script>


import '@firebase/firestore';
import db from "../firebase/init";
import _ from "lodash";
import { bus } from "../main";
import moment from "moment";
import axios from "axios";
import clientForms from "../views/clientForms.vue";
import AddBodyCompositionDialog from "../components/modals/AddBodyCompositionDialog.vue";
import AddPhonenumber from "../components/modals/AddPhoneNumber.vue";
import AddEmailaddress from "../components/modals/AddEmailaddress.vue";
import EditBodyCompositionDialog from "../components/modals/EditBodyCompositionDialog.vue";
import AddBodyMeasurementDialog from "../components/modals/AddBodyMeasurementDialog.vue";
import EditBodyMeasurementDialog from "../components/modals/EditBodyMeasurementDialog.vue";
import ImportPicturesDialog from "../components/modals/ImportPicturesDialog.vue";
import { Carousel, Slide } from 'vue-carousel';
import GmapCustomMarker from 'vue2-gmap-custom-marker';
import { formatToCurrency } from "../modules/quickWins";
import { generateMeshAvatar } from "../modules/avatarGen";
import clientSearch from "@/components/elements/clientSearch.vue";

export default {
  components: {
    'gmap-custom-marker': GmapCustomMarker,
    "client-forms": clientForms,
    "add-composition-dialog": AddBodyCompositionDialog,
    "edit-composition-dialog": EditBodyCompositionDialog,
    "add-measurement-dialog": AddBodyMeasurementDialog,
    "edit-measurement-dialog": EditBodyMeasurementDialog,
    "import-pictures-dialog": ImportPicturesDialog,
    "add-phonenumber-dialog": AddPhonenumber,
    "add-emailaddress-dialog": AddEmailaddress,
    clientSearch,
    Carousel,
    Slide
  },
  props: {
    activeBranch: String,
    type: String, 
    componentType: String
  },

  name: "client-information",
  data () {
    return {
    showDeletedBookings: false,
    updateIndex: 0,
    tabTransition: false,
    tab: "summary",
    now: null,
    activePicker: null,
    emailShowAll: false,
    birthdayDateDialog: false,
    birthdayDateRaw: null,
    extraLoading: false,
    selectedUserTreatments: [],
    removeCompositionItem: null, 
    removeMeasurementItem: null, 
    deleteSelectedCompositionDialog: false,
    deleteSelectedMeasurementDialog: false,
    dialogScore: false,
    scoreInfo: new Object(),
    changes: 0,
    treatments: new Array(),
    products: new Array(),

    deletedSelectedTagDialog: false,
    deletedSelectedTagData: new Object(),
    addAddressManual: false,
    addressNotFound: false,
    clientAddressChecked: new Object(),
    addressChecked: false,
    newTag: {
      feedback: null,
      dialog: false,
      type: "custom",
      name: "",
      id: null,
      dateAdded: null,
      leadDocId: null,
      createLeadDoc: false,
    },
    leadCampaigns: new Array(),
    campaignsLoading: true,

    connectedClients: new Array(),
    clientsConnected: new Array(),
    // tagTypes: [
    //   {
    //     id: "lead",
    //     name: {
    //       en: "Lead",
    //       nl: "Lead"
    //     }
    //   },
    //   {
    //     id: "custom",
    //     name: {
    //       en: "Custom",
    //       nl: "Custom"
    //     }
    //   }
    // ],
    phoneHeaders: [
      {
        text: this.$t('general.type', this.$store.state.locale),
        align: "center",
        value: "type",
        width: "50px",
        sortable: false,
      },
      {
        text: this.$t('general.phone', this.$store.state.locale),
        align: "left",
        value: "phone",
        sortable: false,
      },
      {
        text: "",
        align: "center",
        value: "primary",
        width: "40px",
        sortable: false,
      },
      {
        text: "",
        align: "center",
        value: "primary",
        width: "40px",
        sortable: false,
      }  
    ],
    emailHeaders: [
      {
        text: this.$t('general.email', this.$store.state.locale),
        align: "left",
        value: "email",
        sortable: false,
      },
		{
        text: "",
        align: "center",
        value: "primary",
        width: "30px",
        sortable: false,
      },
      {
        text: "",
        align: "center",
        value: "primary",
        width: "30px",
        sortable: false,
      },
      {
        text: "",
        align: "center",
        value: "primary",
        width: "30px",
        sortable: false,
      },
      {
        text: "",
        align: "center",
        value: "primary",
        width: "30px",
        sortable: false,
      }  
    ],
    tagsHeader: [
       {
        text: "Aangemaakt",
        align: "left",
        sortable: false,
        value: "dataAdded.seconds",
        width: "200px"
      },
      {
        text: "Naam",
        align: "left",
        sortable: false,
        value: "type",
        width: "180px"
      },
      {
        text: "Omschrijving",
        align: "left",
        sortable: false,
        value: "name"
      },
      
      {
        text: "",
        align: "right",
        sortable: false,
        value: "delete",
        width: "40px"
      }
    ],
    headersMeasurement: [
      {
        text: "Datum",
        align: "left",
        sortable: false,
        value: "unix"
      },
      {
        text: "Omvangsmeting (cm)",
        align: "left",
        sortable: false,
        value: "value"
      },
      {
        text: "",
        align: "right",
        sortable: false,
        value: "edit",
        width: "80px"
      },
      {
        text: "",
        align: "right",
        sortable: false,
        value: "delete",
        width: "80px"
      }
    ],

    clientHeaders: [
        {
          text: "",
          align: "left",
          value: "avatar",
          width: "80px",
          sortable: false,
        },

        {
          text: this.$t('general.name', this.$store.state.locale),
          align: "left",
          value: "name",
          width: "150px",
          sortable: true,
        },
        {
          text: this.$t('general.lastName', this.$store.state.locale),
          align: "left",
          value: "surname",
          sortable: true,
        },
        {
          text: this.$t('general.phone', this.$store.state.locale),
          align: "left",
          value: "phone",
          width: "130px",
          sortable: false,
        },
        {
          text: this.$t('general.email', this.$store.state.locale),
          align: "left",
          value: "email",
          sortable: false,
        },
        {
          text: this.$t('general.gender', this.$store.state.locale),
          align: "left",
          value: "gender",
          sortable: false,
        },
        {
          text: "",
          align: "right",
          value: "id",
          sortable: false,
        }
      ],
    historyTreatmentsHeader: [
      {
        text: "Datum",
        align: "left",
        sortable: true,
        width: "100px",
        value: "date.seconds"
      },
      {
        text: "Naam",
        align: "left",
        sortable: false,
        value: "name"
      },
      {
        text: "Prijs",
        align: "left",
        sortable: false,
        value: "name"
      },
      {
        text: "Medewerker",
        align: "left",
        sortable: false,
        width: "120px",
        value: "resourceId"
      },
    ],
    historyReceiptsHeader: [
      {
        text: this.$t('general.date', this.$store.state.locale),
        align: "left",
        sortable: true,
        width: "100px",
        value: "date.seconds"
      },
      {
        text: this.$t('general.employee', this.$store.state.locale),
        align: "left",
        width: "150px",
        sortable: false,
        value: "name"
      },
      {
        text: this.$t('general.description', this.$store.state.locale),
        align: "left",
        sortable: false,
        value: "name"
      },
      {
        text: this.$t('general.time', this.$store.state.locale),
        align: "left",
        sortable: false,
        width: "80px",
        value: "resourceId"
      },
    ],
    futureTreatmentsHeader: [
      {
        text: "Datum",
        align: "left",
        sortable: true,
        width: "275px",
        value: "visit"
      },
      {
        text: "Naam",
        align: "left",
        sortable: true,
        value: "treatmentTitle"
      },
      {
        text: "Vestiging",
        align: "left",
        sortable: true,
        value: "branch"
      },
      {
        text: "Medewerker",
        align: "left",
        sortable: false,
        width: "150px",
        value: "resourceId"
      },
      
      {
        text: "",
        width: "120px",
        align: "right",
        sortable: false,
        value: "id"
      },
    ],
    historyProductsHeader: [
      {
        text: "Datum",
        align: "left",
        sortable: true,
        width: "100px",
        value: "date.seconds"
      },
      {
        text: "Naam",
        align: "left",
        sortable: false,
        value: "name"
      },
      {
        text: "Prijs",
        align: "left",
        sortable: false,
        value: "name"
      },
      {
        text: "Medewerker",
        align: "left",
        sortable: false,
        width: "120px",
        value: "resourceId"
      },
    ],
    compClients: [],
    pages: -1,
    futureBookingsSearch: "",
    selectedClientFutureBookings: [],
    selectedClientHistoryBookings: [],
    historyBookingsLoading: true,
    futureBookingsLoading: true,
    companies: [],
    dateDialog: false,
    idealWeightExplanationDialog: false,
    birthdayFormat: "##-##-####",
    clientView: 'clients',
    pictureViewmode: {
      preview: true,
      compare: false,
      slider: false
    },
    max: null,
    today: null,
    date: null,
    selectClient: false,
    clientUseStartGoalDate: false,
    weightItems: [],
    clientIdealWeightSaved: false,
    clientIdealWeight: null,
    feedbackClientOverview: null,
    clientIdealWeightFeedback: null,
    weightgoalData: {
      gender: null,
      length: null,
      wristSize: null
    },
    showUpgradeModal: false,

    clientTypes: [
      {
        name: "Privé",
        id: 'person'
      },
      {
        name: "Zakelijk",
        id: 'company'
      }
    ],
    
    // CLIENT LIST
    snackbar: {
      active: false,
      text: "",
      icon: "",
      timeout: -1,
      color: "",
    },

    // CLIENT OVERVIEW
    goals: [
      {
        id: "no",
        name: "Geen doel"
      },
      {
        id: "gain",
        name: "Aankomen"
      },
      {
        id: "lose",
        name: "Afvallen"
      }
    ],
    genders: [
      {
        id: 1,
        name: "Man"
      },
      {
        id: 2,
        name: "Vrouw"
      }
    ],
    lifestyle: [
      {
        id: "sitting",
        name: "Zittend"
      },
      {
        id: "reasonablyActive",
        name: "Redelijk actief"
      },
      {
        id: "active",
        name: "Actief"
      }
    ],

    // CLIENT COMPOSITION
    editClientCompositionDialog: false,
    selectedComposition: {
      type: null,
      time: null
    },
    selectedCompositionOriginal: null,
    selectedCompositionDate: null,
    selectedCompositionDateOriginal: null,
    kgView: true,
    percentageView: false,
    addClientCompositionDialog: false,
    compositionLimited: true,
    bodyCompositions: [],
    loadingCompositions:false,
    feedbackCompositions: false,
    retryLoadingCompositions: false,
    chartData: [["Year", "Totaalgewicht", "Vet"]],
    chartOptions: {
      bars: "vertical",
      height: 200,
      colors: new Array(),
      legend: { position: "none" },
      isStacked: true,
      vAxis: {
        minValue: 0,
        textColor: "#646464",
        format: "#",
        baselineColor: "#cccccc",
        gridlineColor: "#cccccc",
        textStyle: {
          fontSize: 13
        }
      },
      chartArea: {
        left: 40,
        right: 0,
        top: 30,
        bottom: 30
      },
      hAxis: {
        stroke: "white",
        format: "date",
        baselineColor: "#cccccc",
        gridlineColor: "#cccccc",
        textColor: "#646464",
        textStyle: {
          fontSize: 13
        }
      },
      backgroundColor: "#f9f9f9"
    },
    paginationComposition: {
      // descending: true,
      // sortBy: "dateAdded.seconds",
      rowsPerPage: 30
    },
    headersCompositions: [
      {
        text: "Meth.",
        align: "left",
        sortable: false,
        value: "dateAdded"
      },
      {
        text: "Datum",
        align: "left",
        sortable: false,
        value: "value"
      },
      {
        text: "Gewicht",
        align: "left",
        sortable: false,
        value: "value"
      },
      {
        text: "Vet",
        align: "left",
        sortable: false
      },
      {
        text: "Vis. Vet",
        align: "left",
        sortable: false
      },
      {
        text: "Spiermassa",
        align: "left",
        sortable: false,
        value: "value"
      },
      {
        text: "Botmassa",
        align: "left",
        sortable: false,
        value: "value"
      },
      {
        text: "Vocht",
        align: "left",
        sortable: false,
        value: "value"
      },
      {
        text: "",
        align: "right",
        sortable: false,
        width:"80px",
        value: "edit"
      },
      {
        text: "",
        align: "right",
        sortable: false,
        width:"80px",
        value: "remove"
      }
    ],
    resources: new Array(),
    branches: new Array(),
    clientHistoryBookingsContinueAt: 0,
    clientFutureBookingsContinueAt: 0,
    activeClientView: "summary",

    devices: [],
    deviceResources: [],
    // CLIENT MEASUREMENTS

    loadingMeasurements: true,
    retryLoadingMeasurements: false,
    addClientMeasurementDialog: false,
    editClientMeasurementDialog: false,
    selectedMeasurement: {},
    selectedMeasurementOriginal: {},
    selectedMeasurementDate: null,
    selectedMeasurementDateOriginal: null,
    feedbackMeasurements: null,
    measurements: [],
    measurementsUpperArmLeft: [],
    measurementsUpperArmRight: [],
    measurementsChest: [],
    measurementsUpperBelly: [],
    measurementsLowerBelly: [],
    measurementsHip: [],
    measurementsButtocks: [],
    measurementsUpperLegLeft: [],
    measurementsUpperLegRight: [],
    measurementsCalfLeft: [],
    measurementsCalfRight: [],
    measurementsAnkleLeft: [],
    measurementsAnkleRight: [],

    copyType: null,
    copyValue: null,

    chartMeasureData: {
      upperArmLeft: [["Datum", "Omvang"]],
      upperArmRight: [["Datum", "Omvang"]],
      chest: [["Datum", "Omvang"]],
      upperBelly: [["Datum", "Omvang"]],
      lowerBelly: [["Datum", "Omvang"]],
      hip: [["Datum", "Omvang"]],
      buttocks: [["Datum", "Omvang"]],
      upperLegLeft: [["Datum", "Omvang"]],
      upperLegRight: [["Datum", "Omvang"]],
      calfLeft: [["Datum", "Omvang"]],
      calfRight: [["Datum", "Omvang"]],
      ankleLeft: [["Datum", "Omvang"]],
      ankleRight: [["Datum", "Omvang"]]
    },
    measurementsData: null,
    chartOptionsMeasurements: {  
      height: 200,
      colors: new Array(),
      legend: { position: "none" },

      vAxis: {
        textColor: "#646464",
        baselineColor: "#cccccc",
        gridlineColor: "#cccccc",
        textStyle: {
          fontSize: 13
        }
      },
      hAxis: {
        stroke: "#cccccc",
        format: "date",
        textColor: "#646464",
        textStyle: {
          fontSize: 13
        }
      },
      chartArea: {
        left: 40,
        right: 0,
        top: 20,
        bottom: 40
      },
      backgroundColor: "#f9f9f9"
    },
    clientDataLoading: true,
    selectedClient: new Object(),
    selectedClientOriginal: new Object(),
    showSalonhubTreatmentsDate: moment().format("X"),

    clientPrePayments: new Array(),

    prePaymentHeader: [
        {
					text: this.$t('general.type', this.$store.state.locale),
					align: "left",
					sortable: false,
					value: "type",
          width: "120px",
          active: false,
          hide: true
				},
				{
					text: this.$t('general.date', this.$store.state.locale),
					align: "left",
					sortable: false,
					value: "timestamp.seconds",
          width: "150px"
				},
        {
					text: this.$t('general.time', this.$store.state.locale),
					align: "left",
					sortable: false,
					value: "timestamp.seconds",
          width: "80px"
				},
				{
					text: this.$t('general.amount', this.$store.state.locale),
					align: "left",
					sortable: false,
					value: "amount.number",
				},
				{
					text: this.$t('payment.status', this.$store.state.locale),
					align: "left",
					sortable: false,
					value: "created",
          width: "250px"
				}
    ],

    prepaymentTypes: [
				{
					name: this.$t('payment.downPayment', this.$store.state.locale),
					id: "downPayment",
				},
				{
					name: this.$t('payment.prePayment', this.$store.state.locale),
					id: "prePayment",
				},
			],

			prepaymentReturnTypes: [
				{
					name: this.$t('payment.refundData', this.$store.state.locale),
					id: "refunded",
				},
				{
					name: this.$t('payment.settled', this.$store.state.locale),
					id: "settled",
				},
				{
					name: this.$t('payment.compensation', this.$store.state.locale),
					id: "compensation",
				},
				{
					name: this.$t('payment.partialSettled', this.$store.state.locale),
					id: "partialSettled",
				},
		],
    prePaymentsSelectedPage: 1,

    prePaymentCheckData: {
      id: null,
      loading: false,
      status: null
    },
    minorTypes: [ 
      {name:'child', id:'child'}, 
      {name:'needy', id:'needy'}, 
      {name:'partner', id:'partner'},   
    ],

    // CLIENT PHOTO'S
    pictureDetailsDialog: false,
    pictureDetailsFeedback: {
      type: "error",
      message: null
    },
    selectedImage: null,
    photoCompareWidth: 0,
    compareImageOne: 0,
    compareImageTwo: 1,
    sliderImageOne: 0,
    sliderImageTwo: 1,
    compareWidth: 187,
    compareWidthNegative: 188,
    deleteSelectedPictureDialog: false,
    deletingImage: false,
    loadingBodyPictures: true,
    pictureDetailsNotesClone: null,
    retryLoadingBodyPictures: false,
    feedbackBodyPictures: null,
    bodyPictures: null,
    numberOfPictureThumbs: 5,
    selectedClientHistory: null,
    importPicturesDialog: false,
    futureBookingsDataLoading: true,
    zones: [
      {name: "Gezicht", id: "face"},
      {name: "Borst", id: "chest"},
      {name: "Rug", id: "back"},
      {name: "Armen", id: "arms"},
      {name: "Buik", id: "belly"},
      {name: "Billen", id: "buttocks"},
      {name: "Benen", id: "legs"},
      {name: "Kuiten", id: "calfs"},
    ],
    bodyparts: [
      {name: "Gezicht", id: "head"},
      {name: "Bovenlichaam", id: "upperbody"},
      {name: "Onderlichaam", id: "lowerbody"}
    ],
    positions: [
      {id: "front", name: "Voorzijde"},
      {id: "back", name: "Achterzijde"},
      {id: "left", name: "Linkerzijde"},
      {id: "right", name: "Rechterzijde"}
    ],
    measurementTypes: [
      {id:"upperArmLeft", name: "Bovenarm Links", class: "upper-arm-left"},
      {id:"upperArmRight", name: "Bovenarm Rechts", class: "upper-arm-right"},
      {id:"chest", name: "Borst", class: "chest"},
      {id:"upperBelly", name: "Buik boven navel", class: "upper-belly"},
      {id:"lowerBelly", name: "Buik onder navel", class: "lower-belly"},
      {id:"hip", name: "Heupen", class: "hip"},
      {id:"buttocks", name: "Billen", class: "buttocks"},
      {id:"upperLegLeft", name: "Bovenbeen Links", class: "upperleg-left"},
      {id:"upperLegRight", name: "Bovenbeen Rechts", class: "upperleg-right"},
      {id:"calfLeft", name: "Kuit Links", class: "calf-left"},
      {id:"calfRight", name: "Kuit Rechts", class: "calf-right"},
      {id:"ankleLeft", name: "Enkel Links", class: "ankle-left"},
      {id:"ankleRight", name: "Enkel Rechts", class: "ankle-right"}
    ],

    connectClientModal: {
      dialog: false,
      type: 'minor',
      clientData: null,
      search: "",
      loading: false
    },
    clientMemberships: new Array(),
    selectedClientsLoading: true,
    membershipHeader: [
      { 
        text: this.$t('general.status', this.$store.state.locale),
        value: 'status.status',
        width: "40px",
        sortable: false
      },
      {
        text: this.$t('general.name', this.$store.state.locale),
        align: "left",
        sortable: false,
        value: "id",
      },
      { 
        text: this.$t('general.startDate', this.$store.state.locale),
        value: 'start',
        width: "160px"
      },
      { 
        text: this.$t('memberships.expiresOn', this.$store.state.locale),
        value: 'expireDate',
        width: "180px"
      },
      { 
        text: this.$t('memberships.usageNumber', this.$store.state.locale),
        value: 'usageNumber',
        width: "100px"
      },
      { 
        text: this.$t('memberships.usageLeft', this.$store.state.locale),
        value: 'usageLeft',
        width: "100px"
      },
      {
        text: "",
        align: "right",
        sortable: false,
        width: "120px",
      },
    ],
   };
  },

  created() {
    // this.checkPackages()
    bus.$on("resetClientView", () => {
      this.activeClientView = 'summary';
      this.birthdayDateRaw = null;
      this.showDeletedBookings = false;
    })

    bus.$on("resetClientInformationView", () => {
      // console.log("bus on - customerDialogChange")
      this.activeClientView = 'summary';
    }),

    bus.$on("getClientInformation", (clientId) => {

      this.clientDataLoading = true;
      this.birthdayDateRaw = null;
      this.loadClientInformation(clientId);
      // this.getDevices();
      // this.getDeviceResources();
      
      // this.loadCompClients();
      this.today = moment().format("YYYY-MM-DD");
      this.now = moment().add(1, 'hour').format("YYYY-MM-DDTHH:mm");

      if(this.branches.length == 0){
        this.loadBranches();
      }
      if(this.resources.length == 0){
        this.loadResources();
      }
      this.showSalonhubTreatmentsDate = moment().subtract(8, 'weeks').format('X')
    })

    this.chartOptions.colors = new Array();
    this.chartOptions.colors.push(this.$themes[this.$store.state.companyTheme][this.$store.state.themeModus].primary);
    this.chartOptions.colors.push(this.$themes[this.$store.state.companyTheme][this.$store.state.themeModus].chartSecondary);
    this.chartOptions.background = this.$themes[this.$store.state.companyTheme][this.$store.state.themeModus].cardOption;
    this.chartOptionsMeasurements.colors = new Array();
    this.chartOptionsMeasurements.colors.push(this.$themes[this.$store.state.companyTheme][this.$store.state.themeModus].primary);
    this.chartOptionsMeasurements.background = this.$themes[this.$store.state.companyTheme][this.$store.state.themeModus].cardOption;

  },

  watch: {
    birthdayDateDialog (val) {
      val && setTimeout(() => (this.activePicker = 'YEAR'))
    }
  },

  filters: {
    reverse: function (array) {
      return array.slice().reverse()
    }
  },

  methods: {

    openSpellingData(item){
      bus.$emit("spellingHelpModal", item, 'open', 'clientInformation');
    },

    genAvatar(name){
      return generateMeshAvatar(name);
    },

    /**
     * Open Membership in general modal through App.vue
     * @param { Object } membership - Membership data
     */
    openMembershipInfo(membership){
      bus.$emit("openMembershipInformationDialog", membership);
    },

    setMinorData(){
      if(!this.selectedClient.minor){
        // this.selectedClient.guardianClientIds = new Array();
        this.selectedClient.minorType = null;
      }
      else{
        this.selectedClient.minorType = 'child';
      }
    },

    connectSelectedClientToClient(type){
      this.connectClientModal = {
        dialog: true,
        type: type,
        clientData: null,
        search: "",
        loading: false,
      }
    },

    setConnectClient(data){
      console.log("setConnectClient: ", data)
      this.connectClientModal.clientData = data;
    },

    removeConnectClient(data){
      this.connectClientModal.clientData = null;
    },

    closeConnectSelectedClientToClient(){
      this.connectClientModal = {
        dialog: false,
        type: null,
        clientData: null,
        search: "",
        loading: false,
      }
    },

    async saveConnectSelectedClientToClient(){
      this.connectClientModal.loading = true;
      if(!this.selectedClient.guardianClientIds){
        this.selectedClient.guardianClientIds = new Array();
      }
      this.selectedClient.guardianClientIds.push(this.connectClientModal.clientData.objectID)

      await db.collection("clients").doc(this.selectedClient.id).set({
        guardianClientIds: this.selectedClient.guardianClientIds
      },{merge: true})
      this.getSelectedClientGuardians();
      this.closeConnectSelectedClientToClient();
    },

    async removeConnectedClientToClient(id){
      this.selectedClient.guardianClientIds =  this.selectedClient.guardianClientIds.filter((client)=> { return client != id})
      await db.collection("clients").doc(this.selectedClient.id).set({
        guardianClientIds: this.selectedClient.guardianClientIds
      },{merge: true})
      this.getSelectedClientGuardians();
    },

    checkZipcode(){
      this.selectedClient.address.postalCode = this.selectedClient.address.postalCode.replace(/\s/g, '')
    },

    copyTextToClipboard(text, type) {
      if (!navigator.clipboard) {
        fallbackCopyTextToClipboard(text);
        return;
      }

      navigator.clipboard.writeText(text).then(
        () => {
          this.copyType = type;
          this.copyValue = text;
          setTimeout(() => {
            this.copyType = type;
            this.copyValue = null;
          }, 1000);
        },
        function (err) {
          console.error("Async: Could not copy text: ", err);
        }
      );
    },

    selectClientView(filter){
      
      this.activeClientView = filter;

      this.loadingCompositions = true;
      if(this.activeClientView == 'compositions'){
        this.loadCompositions();
      } else if(this.activeClientView  == 'measurements'){
        this.loadMeasurements();
      }  else if(this.activeClientView  == 'photo'){
        let contentWrapperWidth;

        if(this.type == 'modal'){
          contentWrapperWidth = document.getElementById('client-modal-wrapper').offsetWidth - 50;
        }
        else{
          contentWrapperWidth = document.getElementById('content').offsetWidth - 50;
        }
        
        let photosWrapperWidth = contentWrapperWidth * 0.66;
        this.numberOfPictureThumbs =  Math.floor(photosWrapperWidth / 110);
        if(contentWrapperWidth >= 790){
          this.photoCompareWidth = 750;
        } 
        else {
          this.photoCompareWidth = (contentWrapperWidth - 40) / 2;
        }
        this.compareWidth = this.photoCompareWidth / 4;
        this.compareWidthNegative = this.photoCompareWidth / 4;
        
        this.loadBodyPictures()
      } else if(this.activeClientView  == 5){
        this.loadResources()
      } else if(this.activeClientView === 'forms' ) {
         
          bus.$emit("loadClientForms", null);
      }
    },

    getGender(gender){
      if(gender == 1){
        return "Man"
      }
      else if(gender == 2){
        return "Vrouw"
      }
      else{
        return "Onbekend"
      }
    },


    async loadClientInformation(clientId){ 
      // console.log("loadClientInformation: ", clientId)
      this.clientDataLoading = true;
      await db.collection("clients")
      .doc(clientId)
      .get()
      .then(doc =>{
        if(doc.exists){
          this.selectedClient = doc.data();
          this.selectedClient.id = doc.id;
          this.selectedClient.address = this.selectedClient.address ? this.selectedClient.address : {
            country: 'netherlands'
          }
          this.selectedClient.address.country = this.selectedClient.address.country ? this.selectedClient.address.country : 'netherlands'
          this.selectedClient.emails = this.selectedClient.emails ? this.selectedClient.emails : new Array();
          this.selectedClient.phones = this.selectedClient.phones ? this.selectedClient.phones : new Array();
          this.selectedClient.emailSubscriptions = this.selectedClient.emailSubscriptions ? this.selectedClient.emailSubscriptions : new Object();
          this.selectedClientOriginal = _.cloneDeep(this.selectedClient);
          this.selectedClient.salonhubTreatmentHistory = new Array();
          this.selectedClient.salonhubColoringHistory = new Array();
          this.selectedClient.salonhubProductHistory = new Array();
          this.selectedClient.alert = this.selectedClient.alert ? this.selectedClient.alert : null;
          this.selectedClient.icon = this.selectedClient.icon ? this.selectedClient.icon : null;
          this.getSalonhubTreatments(clientId);
          this.getSalonhubColoringHistory(clientId);
          this.getSalonhubProductHistory(clientId);
          this.getSelectedClientData();
          this.getSelectedClientHistoryBookings();
          this.getSelectedClientFutureBookings();
          this.getSelectedClientPrepayments();
          this.getSelectedClientMemberships();
          this.getSelectedClientGuardians();
          this.getSelectedGuardianClients();
        }
      })
      this.clientDataLoading = false;
    },

    async getSalonhubTreatments(clientId){
      await db.collection("clients")
      .doc(clientId)
      .collection("treatmentHistory")
      .get()
      .then(snap =>{
        snap.forEach(doc=>{
          if(doc.data().createdBy == 'salonhub'){
            this.selectedClient.salonhubTreatmentHistory = doc.data().treatmentHistory
          }
        })
      })
    },

    async getSalonhubColoringHistory(clientId){
      await db.collection("clients")
      .doc(clientId)
      .collection("coloringHistory")
      .get()
      .then(snap =>{
        snap.forEach(doc=>{
          if(doc.data().createdBy == 'salonhub'){
            this.selectedClient.salonhubColoringHistory = doc.data().coloringHistory
          }
        })
      })
    },

    async getSalonhubProductHistory(clientId){
      await db.collection("clients")
      .doc(clientId)
      .collection("productHistory")
      .get()
      .then(snap =>{
        snap.forEach(doc=>{
          if(doc.data().createdBy == 'salonhub'){
            this.selectedClient.salonhubProductHistory = doc.data().productHistory
          }
        })
      })
    },

    triggerLoadClientForm() {
      // console.log("Load Client Forms and Template Forms...")
      bus.$emit("loadClientForms", null);
    },

    convertTagDate(date){
      return moment(date, "X").format("D MMMM YYYY H:mm");
    },

    treatmentEmployees(item){ // Find employee names that will execute the treatment
      if(item.employees && item.employees.length > 0){
        let employees = new Array();
        item.employees.forEach(employee=>{
          this.resources.forEach(resource=>{
            if(resource.id == employee){
              employees.push(resource.resourceName);
            }
          })
        })
        return employees.join();
      }
      else{
        return "";
      }
    },
    // Gebruikt als test, is niet nodig omdat deze informatie ook dagelijks via een cron wordt opgehaald.
    // fetchMailsInformation(id, messageID){
    //   const bookingSummaryIndex = _.findIndex(this.selectedClientHistoryBookings, { 'id': id }); // Find message index for fast updates and compare
    //   axios
    //     .get(`${this.$store.state.messagesApi.url}/emailDetails?sumId=${id}&msgId=${messageID}`)
    //     .then((response) => {
    //       if (response) {       
    //         if(response.status == 201){
    //           this.selectedClientHistoryBookings[bookingSummaryIndex].emailHistory = response.data; // Doc is updated in the API
    //         }
    //       }
    //     })
    //     .catch((error) => {
    //       console.error(error.message);
    //     });
    // },

    transformTimestamp(timestamp){
      return moment(timestamp, "X").format("D-MM-YYYY")
    },

    removeTagDialog(item){
      this.deletedSelectedTagDialog = true;
      this.deletedSelectedTagData = item;
    },

    removeTag(){
      this.selectedClient.tags = this.selectedClient.tags.filter(tag => tag.name != this.deletedSelectedTagData.name);
      new Promise((resolve)=>{
        db.collection("clients")
        .doc(this.selectedClient.id)
        .set({
          tags: this.selectedClient.tags
        },{merge: true})
        .then(()=> {
          if(this.deletedSelectedTagData.leadDocId){ // Tag is gekoppeld aan Lead Document
            db.collection("leads") // Lead Document bijwerken
            .doc(this.deletedSelectedTagData.leadDocId)
            .set({
              updated: new Date(),
              deleted: {
                deleted: true,
                deletedTime: new Date(),
                deletedBy: this.userFirstName + ' ' + this.userLastName
              }
            },{merge: true})
            .then(()=> {
              resolve()
            })
            .catch(error=> {
              resolve()
              console.error("Error updating lead Doc: ", error)
            })
          }
          else{
            resolve()
          }
        })
        .catch(error=> {
          resolve()
          console.error("Error updating Client Doc: ", error)
        })
      })
      .then(()=>{
        this.selectedClientOriginal.tags = _.cloneDeep(this.selectedClient.tags);
        this.deletedSelectedTagDialog = false;
      })
    }, 

    closeAddTagDialog(){
      this.newTag.feedback = null;
      this.newTag.dialog = false;
      this.newTag.type = "custom";
      this.newTag.name = "";
      this.newTag.id = null;
      this.newTag.dateAdded = null;
      this.newTag.leadDocId = null;
      this.newTag.createLeadDoc = false;
      this.newTag.branch = false;
    },

    getTagType(id){
      let tagType = new Object();
      this.tagTypes.forEach(tag=>{
        if(tag.id == id){
          tagType = {
            name: this.$autoLanguageSelector(this.$store.state.locale, this.$store.state.companyLanguages, tag.name),
            description: tag.description ? this.$autoLanguageSelector(this.$store.state.locale, this.$store.state.companyLanguages, tag.description) : false,
            color: tag.color
          }
        }
      })
      return tagType;
    },

    addSelectedClientTagDialog(){
      this.newTag.dialog = true;
    },

    addselectedClientTag(){
      if(((this.newTag.createLeadDoc && this.newTag.branch) || (!this.newTag.createLeadDoc))){
        let newTag = {
          companyId: this.$store.state.activeCompany.id,
          dateAdded: Number(moment().format("X")),
          id: this.newTag.type.id,
          leadCampaign: this.newTag.id,
          name: this.newTag.name,
          leadDocId: null,
        };
        new Promise((resolve, reject)=>{
          if(this.newTag.createLeadDoc){
            db.collection("leads")
            .add({
              companyId: this.$store.state.activeCompany.id,
              branch: this.newTag.branch,
              campaign: this.newTag.id ? this.newTag.id : null,
              clientId: this.selectedClient.id,
              questions: null,
              actions: [],
              language: this.selectedClient.language ? this.selectedClient.language : 'nl',
              type: 'open',
              source: "",
              clientType: "existingClient",
              updated: new Date(),
              clientInfo: {
                email: this.selectedClient.email,
                firstName: this.selectedClient.name,
                lastName: this.selectedClient.surname,
                phone: this.selectedClient.phone ? this.selectedClient.phone : null
              },
              customSource: null,
              created: new Date()
            })
            .then((docRef) => {
              newTag.leadDocId = docRef.id;
              resolve() // Go to Next Step
            })
            .catch(error => {
              reject(error)
            });
          }
          else{ // No Lead Doc Created. Go to Next Step
            resolve() 
          }
        })
        .then(()=>{
          this.selectedClient.tags.push(newTag); 
          db.collection("clients") // Client Document bijwerken
            .doc(this.selectedClient.id)
            .set({
              tags: this.selectedClient.tags
            },{merge: true})
            .then(()=> {
              this.selectedClientOriginal.tags = _.cloneDeep(this.selectedClient.tags)
              this.closeAddTagDialog();
            })
            .catch(error=> {
              this.selectedClient.tags = _.cloneDeep(this.selectedClientOriginal.tags)
              this.newTag.feedback = this.$t('clients.addTagError', this.$store.state.locale);
              console.error("Error updating lead Doc: ", error);
            })
        })
        .catch(error=>{
          this.newTag.feedback = this.$t('clients.addTagError', this.$store.state.locale);
          console.error("Error adding Lead Doc : ", error);
        })
      }
      else{
        this.newTag.feedback = this.$t('clients.addTagFeedback', this.$store.state.locale);
      }
    },

    addEmailaddress(){
      bus.$emit('addEmailDialog', this.selectedClient.emails, "clientInformation"+this.componentType);
      if(!this.selectedClient.emails){
        this.selectedClient.emails = new Array();
        this.selectedClientOriginal.emails = new Array();
      }
      
    },

    addNewEmailaddress(data){
      this.selectedClient.emails.push(data);
      this.changes++
      this.updateClient('email')
    },

    removeEmail(item, itemIndex){
      //this.selectedClient.emails = this.selectedClient.emails.filter(email => {return email.email != item.email}) // Old, removes all emails that are equal
      this.selectedClient.emails = this.selectedClient.emails.filter((email, index) => {return index != itemIndex})
      this.checkForPrimaryEmail();
      this.updateClient('email')
    },

    async checkForPrimaryEmail(){
      let primary = this.selectedClient.emails.filter(email => {return email.primary})
      if(!primary || primary.length == 0){
        this.setEmailPrimary(this.selectedClient.emails[0])
      }
    },
    
    setEmailPrimary(item){
      this.selectedClient.emails.map(email => {
        email.email == item.email ? email.primary = true : email.primary = false 
      })
      this.selectedClient.emails.sort(function (a, b) {
        return b.primary - a.primary;
      });
      this.updateClient('email')
    },

    emailScoreColor(score) {
			//Score from 10 to 8 "green"; 8 to 5 "yellow"; 5 to 0 "red"
			if (score >= 8) {
				return "green";
			} else if (score >= 5) {
				return "yellow";
			} else {
				return "red";
			}
		},
    
    emailScoreDialog(score, error) {
			this.dialogScore = !this.dialogScore;
			this.scoreInfo = {
				error: Object.keys(error).length > 0 ? error : null,
				score: score,
			}
		},


    addPhonenumber(){
      bus.$emit('addPhonenumberDialog', this.selectedClient.phones, "clientInformation"+this.componentType);
      if(!this.selectedClient.phones){
        this.selectedClient.phones = new Array();
        this.selectedClientOriginal.phones = new Array();
      }
    },

    addNewPhonenumber(data){
      this.selectedClient.phones.push(data);
      this.changes++
      this.updateClient('phone');
    },

    removePhone(item, itemIndex){
      // this.selectedClient.phones = this.selectedClient.phones.filter(phone => {return phone.phone.e164 != item.phone.e164}) // Old, removes all phonenumbers that are equal
      this.selectedClient.phones = this.selectedClient.phones.filter((phone, index) => {return index != itemIndex})
      this.checkForPrimaryNumber();
      this.updateClient('phone');
    },

    

    async checkForPrimaryNumber(){
      let primary = this.selectedClient.phones.filter(phone => {return phone.primary})
      if(!primary || primary.length == 0){
        let mobilePhones = this.selectedClient.phones.filter(phone => {return phone.type == 'mobile'})
        if(!mobilePhones || primary.mobilePhones == 0){
          this.setPhonePrimary(this.selectedClient.phones[0])
        }
        else{
          this.setPhonePrimary(mobilePhones[0])
        }
      }
    },
    
    setPhonePrimary(item){
      // Set all phones to not primary
      this.selectedClient.phones.map((phone, index) => {
        phone.primary = false; 
      })

       // Set one phone to  primary
      for (let index = 0; index < this.selectedClient.phones.length; index++) {
        if(this.selectedClient.phones[index].phone.e164 == item.phone.e164){
          this.selectedClient.phones[index].primary = true;
          break
        }
      }

      // Sort phones and set Primry to top
      this.selectedClient.phones.sort(function (a, b) {
        return b.primary - a.primary ;
      });
      this.updateClient('phone');
    },
    
    
    getLeadCampaigns(){
      if(this.campaignsLoading && this.newTag.type =='lead'){
        this.leadCampaigns = new Array();
        db.collection("leadCampaigns")
          .where("companyId", "==", this.$store.state.activeCompany.id)
          .where("deleted.deleted", "==", false)
          .get()
          .then(snap => {
            snap.forEach(doc => {
              let campaign = doc.data();
              campaign.id = doc.id;
              this.leadCampaigns.push(campaign);
            });
          })
          .then(()=>{
            this.campaignsLoading = false;
          })
          .catch(error=>{
            console.error("Error bij laden lead campaigns: ", error);
          })
      }
    },

    setTagName(){
      this.newTag.name = _.find(this.leadCampaigns, {'id': this.newTag.id }) ? _.find(this.leadCampaigns, {'id': this.newTag.id }).name : "";
      if(!this.newTag.id){
        this.newTag.createLeadDoc = false;
      }      
    },

    // Naar nieuwe lichaamssamenstellings pagina gaan. Mist hiervoor toestemming is.
    goToNewComposition() {
      if(this.limited == true){
        this.showUpgradeModal = true
      }
      else {
        this.addClientCompositionDialog = true
        bus.$emit("setCurrentTime");
      }
    },

    goToNewMeasurement(){
      this.addClientMeasurementDialog = true;
      bus.$emit("setCurrentTime");
    },

    selectBodyPictureView(view, selectedBodyPart){
      if(view == 'preview'){
        this.pictureViewmode.preview = true
        this.pictureViewmode.compare = false
        this.pictureViewmode.slider = false
      } else if(view == 'compare' && this.bodyPictures[selectedBodyPart].pictures.length != 1){
        this.pictureViewmode.preview = false
        this.pictureViewmode.compare = true
        this.pictureViewmode.slider = false
      } else if(view == 'slider' && this.bodyPictures[selectedBodyPart].pictures.length != 1){
        this.pictureViewmode.preview = false
        this.pictureViewmode.compare = false
        this.pictureViewmode.slider = true
        this.slider()
      } else {
        this.pictureViewmode.preview = true
        this.pictureViewmode.compare = false
        this.pictureViewmode.slider = false
      }
    },

    selectBodyPictureArea(id){
      if(this.bodyPictures[id].pictures.length > 0){
        Object.keys(this.bodyPictures).map(key => {
          if(key == id && this.bodyPictures[key].pictures.length > 0){
            this.bodyPictures[key].selected = true
            this.compareImageOne = 0;
            this.compareImageTwo = 1;
            this.sliderImageOne = 0;
            this.sliderImageTwo = 1;
          } else{
            this.bodyPictures[key].selected = false
          }
        });
      }
      // Keuze van vergelijking resetten
      this.selectBodyPictureView()
    },

    // Afbeelding selecteren om naast elkaar te vergelijken
    selectPictureForCompare(index) {
      console.log("Comparen");
      if (this.compareImageOne == null && !this.compareImageTwo == null) {
        console.log("Beide leeg");
        this.compareImageOne = index;
      } else if (this.compareImageOne != null && this.compareImageTwo == null) {
        this.compareImageTwo = index;
        console.log("Tweede leeg");
      } else if (this.compareImageOne != null && this.compareImageTwo != null) {
        console.log("Beide gekozen");

        this.compareImageOne = index;
        this.compareImageTwo = null;
      }
    },

    // Afbeelding selecteren om de vergelijken in slider
    selectPictureForSliderCompare(index) {
      if (this.sliderImageOne == null && !this.sliderImageTwo == null) {
        console.log("Beide leeg");
        this.sliderImageOne = index;
      } else if (this.sliderImageOne != null && this.sliderImageTwo == null) {
        this.sliderImageTwo = index;
        console.log("Tweede leeg");
      } else if (this.sliderImageOne != null && this.sliderImageTwo != null) {
        console.log("Beide gekozen");
        this.sliderImageOne = index;
        this.sliderImageTwo = null;
      }
    },

    goToImportPictures(){
      this.importPicturesDialog = true
    },

    // Picture Details Dialog laten zien en notes clonen om aanpassingen te detecteren
    showPictureDetails(){
      this.pictureDetailsNotesClone = _.cloneDeep(this.bodyPictures[this.selectedBodyPart].pictures[this.bodyPictures[this.selectedBodyPart].selectedPicture].notes)
      this.pictureDetailsDialog = true
    },

    // Aantekeningen bij foto opslaan
    saveSelectedPictureNotes() {
      this.pictureDetailsFeedback.message = null
      if (this.notesChanged == true) {
        db.collection("bodypictures")
          .doc(this.bodyPictures[this.selectedBodyPart].pictures[this.bodyPictures[this.selectedBodyPart].selectedPicture].id)
          .update({
            notes: this.pictureDetailsNotesClone
          })
          .then(()=>{
            this.bodyPictures[this.selectedBodyPart].pictures[this.bodyPictures[this.selectedBodyPart].selectedPicture].notes  = _.cloneDeep(this.pictureDetailsNotesClone)
            this.pictureDetailsFeedback.type = "success"
            this.pictureDetailsFeedback.message = "Notitie is opgeslagen"
          })
          .catch(error=>{
            console.error("Opslaan van notities niet gelukt. ", error)
            this.pictureDetailsFeedback.type = "warning"
            this.pictureDetailsFeedback.message = "Opslaan van notities niet gelukt. Probeer opnieuw."
          })
      }
    },

    // Compare slider gereed maken
    slider() {
      let vm = this;
      let screenWidth = this.photoCompareWidth;
      let screenCenter = this.photoCompareWidth / 4;
      console.log("#image-compare-slider", document.getElementById("image-compare-slider"))
      let dragItem = document.querySelector("#handle");
      let container = document.querySelector("#image-compare-slider");

      // let dragItem = document.getElementById("handle");
      // let container = document.getElementById("image-compare-slider");

      let active = false;
      let currentX;
      let initialX;
      let xOffset = 0;

      container.addEventListener("touchstart", dragStart, false);
      container.addEventListener("touchend", dragEnd, false);
      container.addEventListener("touchmove", drag, false);

      container.addEventListener("mousedown", dragStart, false);
      container.addEventListener("mouseup", dragEnd, false);
      container.addEventListener("mousemove", drag, false);

      function dragStart(e) {
        if (e.type === "touchstart") {
          initialX = e.touches[0].clientX - xOffset;
        } else {
          initialX = e.clientX - xOffset;
        }

        if (e.target === dragItem) {
          active = true;
        }
      }

      function dragEnd(e) {
        initialX = currentX;
        active = false;
      }

      function drag(e) {
        if (active) {
          e.preventDefault();

          if (e.type === "touchmove") {
            currentX = e.touches[0].clientX - initialX;
          } else {
            currentX = e.clientX - initialX;
          }

          let leftLimit = 0 - screenCenter + 40;
          let rightLimit = 0 + screenCenter - 40;
          if (currentX < leftLimit) {
            currentX = leftLimit;
          }

          if (currentX > rightLimit) {
            currentX = rightLimit;
          }
          xOffset = currentX;
          setTranslate(currentX, dragItem);
        }
      }

      function setTranslate(xPos, el) {
        vm.compareWidth = screenCenter + xPos;
        vm.compareWidthNegative = screenWidth - (screenCenter + xPos);
      }
    },
                           
    // Afbeelding, thumb en bijbehorende data uit storage en database verwijderen
    deletePicture(){
      let selectedImage = _.cloneDeep(this.bodyPictures[this.selectedBodyPart].selectedPicture)
      let selectedImageNew
      // this.selectedImage = this.bodyPictures[this.selectedBodyPart].selectedPicture
      this.pictureDetailsFeedback.message = null,
      this.deletingImage = true
      console.log("Start delete function");
      
      
      if(this.bodyPictures[this.selectedBodyPart].pictures.length > 1 && selectedImage == this.bodyPictures[this.selectedBodyPart].pictures.length - 1){
        selectedImageNew = this.bodyPictures[this.selectedBodyPart].pictures.length - 2
      } else if(this.bodyPictures[this.selectedBodyPart].pictures.length == 1) {
        selectedImageNew = 0
      } else {
        selectedImageNew = selectedImage
      }

      db.collection("bodypictures")
        .doc(this.bodyPictures[this.selectedBodyPart].pictures[selectedImage].id)
        .update({
          deleted: {
            deleted: true,
            deletedTime: new Date(),
            deletedBy: this.userFirstName + ' ' + this.userLastName,
          },
        })

        .then(()=> {
          console.log("Document bodypictures successfull deleted");
          this.deleteSelectedPictureDialog = false
          this.bodyPictures[this.selectedBodyPart].selectedPicture = _.cloneDeep(selectedImageNew)
          this.bodyPictures[this.selectedBodyPart].pictures.splice(selectedImage, 1)
          
          this.deletingImage = false
          
        })
        .then(() => {
          this.pictureDetailsDialog = false
        })
        .then(() => {
          // Log Action
          db.collection('log').add({
            time: new Date(),
            logType: "info",
            user : this.userFirstName + ' ' + this.userLastName,
            userType: this.userRole,
            applicationType: "desktop-application",
            company: this.userCompany,
            actionType: 'User Action',
            action : 'Cliëntfoto verwijderd',
            extra:  '<b>Cliënt</b>: ' + this.selectedClient.name + ' ' + this.selectedClient.surname 
          })
        })
        .catch(error=> {
          console.error("Error deleting bodypictures document", error);
          this.deleteSelectedPictureDialog = false
          this.deletingImage = false
          this.pictureDetailsFeedback.type = "warning"
          this.pictureDetailsFeedback.message = "Verwijderen van de foto is niet gelukt."
        });
    },
    
    selectPictureForPreview(index){
      this.bodyPictures[this.selectedBodyPart].selectedPicture = index
    },

    editComposition(id){
      this.selectedComposition = _.find(this.bodyCompositions, function(o) { return o.id == id; });
      this.selectedCompositionOriginal = _.cloneDeep(this.selectedComposition)
      this.selectedCompositionDate = moment.unix(this.selectedComposition.dateAdded.seconds).format("YYYY-MM-DD");
      this.selectedCompositionDateOriginal = _.cloneDeep(this.selectedCompositionDate)
      this.editClientCompositionDialog = true
    },

    removeCompositionDialog(item){
      this.removeCompositionItem = item.id;
      this.deleteSelectedCompositionDialog = true;
    },

    removeComposition(){
      db.collection("compositions").doc(this.removeCompositionItem).delete()
        .then(() => {
          this.removeCompositionItem = null;
          this.deleteSelectedCompositionDialog = false;
          this.loadCompositions();
        })
    },

    removeMeasurementDialog(item){
      this.removeMeasurementItem = item.id;
      this.deleteSelectedMeasurementDialog = true;
    },

    removeMeasurement(){
      db.collection("measurements").doc(this.removeMeasurementItem).delete()
        .then(() => {
          this.removeMeasurementItem = null;
          this.deleteSelectedMeasurementDialog = false;
          this.loadMeasurements();
      })
    },

    clearEditCompositionData(){
      this.selectedComposition = {};
      this.selectedCompositionOriginal = null
      this.selectedCompositionDate = null
      this.selectedCompositionDateOriginal = null
    },

    editMeasurement(id){
      this.selectedMeasurement = _.find(this.measurements, function(o) { return o.id == id; });
      this.selectedMeasurementOriginal = _.cloneDeep(this.selectedMeasurement)
      this.selectedMeasurementDate = moment.unix(this.selectedMeasurement.dateAdded.seconds).format("YYYY-MM-DD");
      this.selectedMeasurementDateOriginal = _.cloneDeep(this.selectedMeasurementDate)
      this.editClientMeasurementDialog = true
    },

    clearEditMeasurementData(){
      this.selectedMeasurement = {}
      this.selectedMeasurementOriginal = {}
      this.selectedMeasurementDate = null
      this.selectedMeasurementDateOriginal = null
    },

    clearClientIdealWeight(){
      this.clientIdealWeight = null
    },
    
    // Percentages weergeven ipv kg
    setPercentage() {
      this.percentageView = true;
      this.kgView = false;
    },

    // Kg weergeven ipv percentages
    setKg() {
      this.kgView = true;
      this.percentageView = false;
    },

    loadCompClients(){
      db.collection("companyClients")
        .where("companyId", "==", this.$store.state.activeCompany.id)
        .get()
        .then(snap => {
          this.compClients = [];
          snap.forEach(doc => {
            let company = doc.data();
            company.id = doc.id;
            this.compClients.push(company);
          });
        })
    },

    loadResources(){
      // console.log("loadResources")
      this.futureBookingsDataLoading = true;
      db.collection("employees")
        .where("companyId", "==", this.$store.state.activeCompany.id)
        .get()
        .then(snap => {
          this.resources = new Array();
          snap.forEach(doc => {
            let resource = doc.data();
            resource.id = doc.id;
            this.resources.push(resource);
          });
        })
        .then(()=>{
          this.futureBookingsDataLoading = false;
        })
    },

    loadBranches(){
      db.collection("branches")
        .where("companyId", "==", this.$store.state.activeCompany.id)
        .get()
        .then(snap => {
          this.branches = [];
          snap.forEach(doc => {
            let branch = doc.data();
            branch.id = doc.id;
            this.branches.push(branch);
          });
        })
    },

    // Alle foto's van selecteerde client binnenhalen
    loadBodyPictures(){
      this.loadingBodyPictures = true;
      this.retryLoadingBodyPictures = false;
      this.feedbackBodyPictures = null;
      this.bodyPictures = {
        headfront: { 
          name: 'Gelaat <span class="extra-label">voor</span>',
          pictures: [],
          selected: false,
          selectedPicture: 0
        },
        headleft: { 
          name: 'Gelaat <span class="extra-label">links</span>',
          pictures: [],
          selected: false,
          selectedPicture: 0
        },
        headright:{ 
          name: 'Gelaat <span class="extra-label">rechts</span>',
          pictures: [],
          selected: false,
          selectedPicture: 0
        },
        upperbodyfront:{ 
          name: 'Bovenlichaam <span class="extra-label">voor</span>',
          pictures: [],
          selected: false,
          selectedPicture: 0
        },
        upperbodyback:{ 
          name: 'Bovenlichaam <span class="extra-label">achter</span>',
          empty: true,
          pictures: [],
          selected: false,
          selectedPicture: 0
        },
        upperbodyleft:{ 
          name: 'Bovenlichaam <span class="extra-label">links</span>',
          pictures: [],
          selected: false,
          selectedPicture: 0
        },
        upperbodyright:{ 
          name: 'Bovenlichaam <span class="extra-label">rechts</span>',
          pictures: [],
          selected: false,
          selectedPicture: 0
        },
        lowerbodyfront:{ 
          name: 'Onderlichaam <span class="extra-label">voor</span>',
          pictures: [],
          selected: false,
          selectedPicture: 0
        },
        lowerbodyback:{ 
          name: 'Onderlichaam <span class="extra-label">achter</span>',
          pictures: [],
          selected: false,
          selectedPicture: 0
        },
        lowerbodyleft:{ 
          name: 'Onderlichaam <span class="extra-label">links</span>',
          pictures: [],
          selected: false,
          selectedPicture: 0
        },
        lowerbodyright:{ 
          name: 'Onderlichaam <span class="extra-label">rechts</span>',
          pictures: [],
          selected: false,
          selectedPicture: 0
        },
      },

      db.collection("bodypictures")
        .where("companyId", "==", this.$store.state.activeCompany.id)
        .where("clientId", "==", this.selectedClient.id)
        .where("deleted.deleted", "==", false)
        .orderBy("dateAdded", "desc")
        .get()
        .then(snap => {
          snap.forEach(doc => {
            if (doc.data().bodypart == "head") {
              if (doc.data().position == "front") {
                let pictureData = doc.data();
                pictureData.id = doc.id;
                this.bodyPictures.headfront.pictures.push(pictureData);
              }
              if (doc.data().position == "left") {
                let pictureData = doc.data();
                pictureData.id = doc.id;
                this.bodyPictures.headleft.pictures.push(pictureData);
              }
              if (doc.data().position == "right") {
                let pictureData = doc.data();
                pictureData.id = doc.id;
                this.bodyPictures.headright.pictures.push(pictureData);
              }
            }
            if (doc.data().bodypart == "upperbody") {
              if (doc.data().position == "front") {
                let pictureData = doc.data();
                pictureData.id = doc.id;
                this.bodyPictures.upperbodyfront.pictures.push(pictureData);
              }
              if (doc.data().position == "back") {
                let pictureData = doc.data();
                pictureData.id = doc.id;
                this.bodyPictures.upperbodyback.pictures.push(pictureData);
              }
              if (doc.data().position == "left") {
                let pictureData = doc.data();
                pictureData.id = doc.id;
                this.bodyPictures.upperbodyleft.pictures.push(pictureData);
              }
              if (doc.data().position == "right") {
                let pictureData = doc.data();
                pictureData.id = doc.id;
                this.bodyPictures.upperbodyright.pictures.push(pictureData);
              }
            }
            if (doc.data().bodypart == "lowerbody") {
              if (doc.data().position == "front") {
                let pictureData = doc.data();
                pictureData.id = doc.id;
                this.bodyPictures.lowerbodyfront.pictures.push(pictureData);
              }
              if (doc.data().position == "back") {
                let pictureData = doc.data();
                pictureData.id = doc.id;
                this.bodyPictures.lowerbodyback.pictures.push(pictureData);
              }
              if (doc.data().position == "left") {
                let pictureData = doc.data();
                pictureData.id = doc.id;
                this.bodyPictures.lowerbodyleft.pictures.push(pictureData);
              }
              if (doc.data().position == "right") {
                let pictureData = doc.data();
                pictureData.id = doc.id;
                this.bodyPictures.lowerbodyright.pictures.push(pictureData);
              }
            }
          });
        })
        .then(()=>{
          let bodypartsWithPictures = []
          
          Object.keys(this.bodyPictures).map(key => {
            if(this.bodyPictures[key].pictures && this.bodyPictures[key].pictures.length > 0){
              bodypartsWithPictures.push(key)
            } 
          });

          if(bodypartsWithPictures.length > 0){
            let firstItemWithPictures = bodypartsWithPictures[0]
            this.bodyPictures[firstItemWithPictures].selected = true
          }
          
        })
        .then(() => {
          this.loadingBodyPictures = false;
          this.retryLoadingBodyPictures = false;
          this.feedbackBodyPictures = null;
        })
        .catch(error => {
          console.error(error);
          this.loadingBodyPictures = false;
          this.feedbackBodyPictures = "Het laden is niet gelukt. Probeer het opniew.";
          this.retryLoadingBodyPictures = true;
        });
    },

    updateData() {
      // this.loadClients();
    },

    updateDataCompClient(){
      //  this.loadCompClients();
    },

    // Alle lichaamssamenstellingen binnenhalen uit de database
    loadCompositions() {
      this.chartData = [["Year", "Totaalgewicht", "Vet"]];
      this.bodyCompositions = [];
      this.loadingCompositions = true;
      this.retryLoadingCompositions = false;
      this.feedbackCompositions = null;    
      db.collection("compositions")
        .where("companyId", "==", this.$store.state.activeCompany.id)
        .where("clientId", "==", this.selectedClient.id)
        .orderBy("dateAdded")
        .get()
        .then(snap => {
          snap.forEach(doc => {
            let composition = doc.data();
            composition.id = doc.id;
            composition.unix = doc.data().dateAdded.seconds;
            if(composition.time) {
              console.log("composition.time: ", composition.time)
              let date = moment.unix(composition.unix).format("YYYY-MM-DD");
              let dateAndTime = date + "T" + composition.time;
              console.log("date: ", date)
              console.log("dateAndTime: ", dateAndTime);
              composition.time = "- " + composition.time + "u";
              composition.unix = String(moment(dateAndTime, "YYYY-MM-DDTHH:mm").format("X"));
              console.log("new unix: ", composition.unix);
            }
            composition.date = moment.unix(doc.data().dateAdded.seconds).local('nl').format("D MMM YYYY");
            console.log("composition.date: ", composition.date)
            this.bodyCompositions.push(composition);
          });
        })
        .then(()=>{
          this.bodyCompositions = _.orderBy(this.bodyCompositions, ['unix'], ['asc'])
          console.log("this.bodyCompositions: ", this.bodyCompositions)
           this.bodyCompositions.forEach(composition=>{
              let compositionChart = [];
              let chartLabel = composition.time ? (moment.unix(composition.dateAdded.seconds).format("D MMM YYYY") + " "+  composition.time) : moment.unix(composition.dateAdded.seconds).format("D MMM YYYY");
              compositionChart.push(chartLabel);
              compositionChart.push(composition.weight);
              if (composition.fat.weight) {
                compositionChart.push(composition.fat.weight);
              } else {
                compositionChart.push(0);
              }
              this.chartData.push(compositionChart);
            })
        })
        .then(() => {
          this.loadingCompositions = false;
          this.retryLoadingCompositions = false;
          this.feedbackCompositions = null;

          if(this.companyPackages.type == "freeVersion"){
            if(this.bodyCompositions.length > 4){
            this.compositionLimited = true
            } else {
              this.compositionLimited = false
            }
          } else{
            if(!this.companyPackages.list.yQeERImQd1L88wabarS4){
              if(this.bodyCompositions.length > 4){
                this.compositionLimited = true
                } else {
                  this.compositionLimited = false
                }
            }
          }         
        })
        .catch(error => {
          console.error("Binnenhalen van Composition is niet gelukt. ", error);        
          this.loadingCompositions = false;
          this.retryLoadingCompositions = true;
        });
    },

    setBookingTreatmentParametersZones(index, device){
      console.log("setBookingTreatmentParametersZones Device: ",index,  device, this.selectedClientHistoryBookings[index].treatmentParameters)
      if(!this.selectedClientHistoryBookings[index].treatmentParameters[device].zoneParameters){
        this.selectedClientHistoryBookings[index].treatmentParameters[device].zoneParameters = {};
      }
      this.selectedClientHistoryBookings[index].treatmentParameters[device].zones.forEach(zone=>{
        if(!this.selectedClientHistoryBookings[index].treatmentParameters[device].zoneParameters[zone.id]){
          this.selectedClientHistoryBookings[index].treatmentParameters[device].zoneParameters[zone.id] = {
            program: null,
            time: null,
            intensity: null
          }
        }
      })
    },

    updateBookingSummary(id, index){
      let message = "Behandelparameters succesvol toegevoegd";
      if(!this.selectedClientHistoryBookings[index].treatmentParameters){
        message = "Behandelparameters succesvol toegevoegd";
      }
       db.collection("bookingsummary")
        .doc(id)
        .set({
          treatmentParameters: this.selectedClientHistoryBookings[index].treatmentParameters
        }, {merge: true})
        .then(() => {
          this.selectedClientHistoryBookings[index].treatmentParametersOriginal = _.cloneDeep(this.selectedClientHistoryBookings[index].treatmentParameters);
          this.selectedClientHistoryBookings[index].changed = false;
        })
        .then(()=>{
          this.snackbar.active = true;
          this.snackbar.timeout = 3000;
          this.snackbar.color = "rgba(1, 230, 110, 0.7)";
          this.snackbar.icon = "mdi-thumb-up";
          this.snackbar.text = message;
        })
    },

    checkTreatmentParameters(booking, device){
      if(booking.treatmentParameters){
        if(_.isEqual(booking.treatmentParameters, booking.treatmentParametersOriginal) == false){
          booking.changed = true;
        }

        if(booking.treatmentParameters[device].zones && booking.treatmentParameters[device].zones.length > 0){
          let counter = 0;
          let validCounter = 0;
          booking.treatmentParameters[device].zones.forEach(zone=>{
            if(booking.treatmentParameters[device].device && booking.treatmentParameters[device].zoneParameters[zone.id].program && booking.treatmentParameters[device].zoneParameters[zone.id].time && booking.treatmentParameters[device].zoneParameters[zone.id].intensity){
              validCounter++
            }
            counter++
            if(booking.treatmentParameters[device].zones.length == counter){
              if(booking.treatmentParameters[device].zones.length == validCounter){
                booking.treatmentParameters[device].valid = true;
              }
              else{
                booking.treatmentParameters[device].valid = false;
              }
            }
          })
        }
      }
      else{
        booking.changed = false;
      }
      this.updateIndex++

    },

    navigateBooking(booking){
      if(!booking.deleted){
        // console.log(booking);
        this.$router.push({ name: `agenda`, params:{
          type: "gotobooking",
          data: booking
        }});
      }
    },

    async updateClient(type) {
      let data = this.selectedClient
      if(type == 'email'){
        data = {emails: this.selectedClient.emails}
      }
      else if(type == 'phone'){
        data = {phones: this.selectedClient.phones}
      }
      // console.log("UPdate CLIENT: ", data)
      this.feedbackClientOverview = null
      this.selectedClient.birthday = this.birthdayDate.firebase; 
      let docId = this.selectedClient.ObjectId ? this.selectedClient.ObjectId : this.selectedClient.id
      await db.collection("clients")
      .doc(docId)
      .set(data, {merge: true})
      .then(() => {
        if(type == 'email'){
          this.selectedClientOriginal.email = _.cloneDeep(this.selectedClient.email);
        }
        else if(type == 'phone'){
          this.selectedClientOriginal.phones = _.cloneDeep(this.selectedClient.phones);
        }
        else{
          this.selectedClientOriginal = _.cloneDeep(this.selectedClient);
        } 
      })
      .then(()=>{
        bus.$emit("updateClient", this.selectedClient);
      })
      .catch(error => {
        this.feedbackClientOverview = "Het opslaan is niet gelukt. Probeer het opnieuw";
        console.error("Error updating document: ", error);
      });

      if(this.type == 'modal'){
        bus.$emit("clientChanged", docId); // Trigger Agenda to refresh needed client data
      }
      bus.$emit("clientEditted", this.selectedClient); 
    },

    async getSelectedClientData(){
      let paidReceipts = new Array();
      let historyItemsTreatments = new Array();
      let historyItemsProducts = new Array();
      this.treatments = new Array();
      this.products = new Array();

      await db.collection("receiptsPaid")
      .where("companyId", "==", this.$store.state.activeCompany.id)
      .where("clientId", "==", this.selectedClient.id)
      .get()
      .then(snap => {
        snap.forEach(doc => {
          let receipt = doc.data();
          receipt.id = doc.id;
          paidReceipts.push(receipt);
        });
      })
      if(paidReceipts.length > 0 ){  // There are (Paid) receipts from history
        await new Promise((resolve) => {
          let receiptCounter = 0
          paidReceipts.forEach(receipt => {
            
            if(receipt.paymentTableObj && receipt.paymentTableObj.items && receipt.paymentTableObj.items.length > 0){
              receipt.paymentTableObj.items.forEach((item)=>{
                let price = "-";

                if(item.priceBruto && item.newPriceTotalBruto){
                  let discount = item.newPriceTotalBruto != item.priceBruto ? item.priceBruto - item.newPriceTotalBruto : 0
                  if(discount > 1){
                    price = `${formatToCurrency('nl-NL', 'EUR', item.priceBruto)} (-${formatToCurrency('nl-NL', 'EUR', discount)})`;
                  }
                  else{
                    price = formatToCurrency('nl-NL', 'EUR', item.newPriceTotalBruto) 
                  }
                }

                if(item.typeItem == 'treatment'){
                  this.getTreatment(item.id)

                  // Format price and check if there is any discount and show it like (-€10)
                  historyItemsTreatments.push(
                    {
                      date: moment(receipt.created.seconds, "X").format("DD-MM-YYYY"),
                      dateFormatted: moment(receipt.created.seconds, "X").format("YYYY-MM-DD"),
                      id:item.id,
                      name: item.id,
                      resourceIds: item.sub.map(subItem=>{return subItem.subResourceId}),
                      price: price
                    }
                  )
                }
                else if(item.typeItem == 'product'){
                  this.getProduct(item.id)
                  historyItemsProducts.push(
                    {
                      date: moment(receipt.created.seconds, "X").format("DD-MM-YYYY"),
                      dateFormatted: moment(receipt.created.seconds, "X").format("YYYY-MM-DD"),
                      id:item.id,
                      name: item.id,
                      resourceId: item.resourceId,
                      price: price
                    }
                  )
                }
                else{
                  // console.log("item.typeItem: ", item.typeItem)
                }
              })
            }

            receiptCounter++
            if(receiptCounter == paidReceipts.length){
              resolve()
            } 
          })
        })
      }

      // console.log("historyItemsTreatments: ", historyItemsTreatments.length)


      this.selectedClientHistory = {
        treatments: historyItemsTreatments.sort((a, b) => b.dateFormatted.localeCompare(a.dateFormatted)), 
        products: historyItemsProducts.sort((a, b) => b.dateFormatted.localeCompare(a.dateFormatted))  
      };
    },

    getDeviceName(id){
      let name = null;
      this.devices.forEach(device=>{
        if(device.deviceId == id){
          name = device.deviceName;
        }
      })
      return name;
    },

    getAvailableDevices(id){
      let devices = [];
      this.deviceResources.forEach(device=>{
        if( device.deviceTypes && device.deviceTypes.length > 0){
          device.deviceTypes.forEach(deviceType=>{
            if(deviceType == id){
              devices.push(device);
            }
          })
        }
      })
      return devices;
    },

    getTreatmentEmployeesNames(employees){
      let uniqEmployees = [...new Set(employees)]
      let uniqEmployeesNamesArray = uniqEmployees.map((id)=>{ return this.getEmployeeName(id) })
      let uniqEmployeesNames = uniqEmployeesNamesArray.join(", ");

      return uniqEmployeesNames.replace(/,(?=[^,]*$)/, " en");
    },


    getEmployeeName(id){
      let employee = this.$store.state.allCompanyEmployees.find((employee)=>{ return employee.id == id });
      return employee != undefined ? employee.name : null;
    },

    getTreatmentName(id){
      let treatment = this.treatments.find((treatment)=>{ return treatment.id == id });
      return treatment != undefined ? this.$autoLanguageSelector(this.$store.state.locale, this.$store.state.companyLanguages, treatment.treatmentTitle) : null;
    },

    getProductName(id){
      let product = this.products.find((product)=>{ return product.id == id });
      return product != undefined ? product.name : null;
    },

    getTreatment(id){
      db.collection("treatments").doc(id).get()
      .then(doc => {
        if(doc.exists){
          let treatment = doc.data()
          treatment.id = doc.id
          this.treatments.push(treatment)
        }
      })
    },

    getProduct(id){
      db.collection("products").doc(id).get()
      .then(doc => {
        if(doc.exists){
          let product = doc.data()
          product.id = doc.id
          this.products.push(product)
        }
      })
    },

    getDevices(){
      db.collection("devices")
        .get()
        .then(snap => {
          this.devices = [];
          snap.forEach(doc => {
            let device = doc.data();
            device.id = doc.id;
            this.devices.push(device);
          });
        })
    },

    getDeviceResources(){
      db.collection("resources")
        .where("companyId", "==", this.$store.state.activeCompany.id)
        .where("resourceType", "==", "device")
        .get()
        .then(snap => {
          this.deviceResources = [];
          snap.forEach(doc => {
            let device = doc.data();
            device.id = doc.id;
            this.deviceResources.push(device);
          });
        })
    },


    async getSelectedClientGuardians(){
      this.selectedClientsLoading = true;
      this.clientsConnected = new Array();
      if(this.selectedClient.guardianClientIds && this.selectedClient.guardianClientIds.length > 0){
        for (let index = 0; index < this.selectedClient.guardianClientIds.length; index++) {
          await db.collection("clients").doc(this.selectedClient.guardianClientIds[index]).get()
          .then((doc) => {
            let guardian = doc.data();
            guardian.id = doc.id;
            this.clientsConnected.push(guardian);
          })
          .catch((err) => {
            console.error("Error getting guarian clients", err);
          });
        }
      }
      this.selectedClientsLoading = false;
      
      // this.clientPrePayments.sort((a, b) => b.timestamp.seconds - a.timestamp.seconds);
      return;	
    },

    async getSelectedGuardianClients(){
      this.selectedClientsLoading = true;
      this.connectedClients = new Array();
      await db.collection("clients")
      .where("guardianClientIds", "array-contains", this.selectedClient.id)
      .get()
      .then((snap) => {
        snap.forEach((doc) => {
          let clientMinor = doc.data();
          clientMinor.id = doc.id;
          this.connectedClients.push(clientMinor);
        });
      })
      .catch((err) => {
        console.error("Error GuardianClients", err);
      });
      this.selectedClientsLoading = false;
    },

    async getSelectedClientPrepayments(){
      this.clientPrePayments = new Array();
      this.prePaymentsSelectedPage = 1;
      await db.collection("prepayments")
      .where("companyId", "==", this.$store.state.activeCompany.id)
      .where("clientId", "==", this.selectedClient.id)
      // .where("status.returned", "==", false)
      .get()
      .then((snap) => {
        snap.forEach((doc) => {
          let prePayment = doc.data();
          prePayment.id = doc.id;
          this.clientPrePayments.push(prePayment);
        });
      })
      .catch((err) => {
        console.error("Error prePayments", err);
      });
      this.clientPrePayments.sort((a, b) => b.timestamp.seconds - a.timestamp.seconds);
      return;	
    },

    async getSelectedClientMemberships(){
      this.clientMemberships = new Array();
      await db.collection("memberships")
      .where("companyId", "==", this.$store.state.activeCompany.id)
      .where("clientId", "==", this.selectedClient.id)
      // .where("status.returned", "==", false)
      .get()
      .then((snap) => {
        snap.forEach((doc) => {
          let item = doc.data();
          item.id = doc.id;
          item.start = moment(item.startDate).format("YYYY-MM-DD");
          item.expireDate = item.status.endDate ? item.status.endDate : this.$t('memberships.autoRenewShort', this.$store.state.locale);
          [item.usageNumber, item.usageLeft] = this.calculateUsage(item);
          this.clientMemberships.push(item);
        });
      })
      .catch((err) => {
        console.error("Error memberships", err);
      });
      this.clientMemberships.sort((a, b) => b.created.seconds - a.created.seconds);
      return;	
    },

    /**
     * Calculate the usage of the membership
     * @param {Object} item  - Membership data
     */
    calculateUsage(item){
      let usage = item.usage && item.usage.length > 0 ? item.usage.length : 0;
      let totalUsageNumber = 0;

      if(item.usageRules?.limitType == 'total'){
        totalUsageNumber = item.usageRules.limitNumber
      }
      else{ 
        totalUsageNumber = item.usageRules.limitNumber * item.duration
      }

      let usageLeft = (totalUsageNumber - usage) > 0 ? (totalUsageNumber - usage)  : 0

      return [totalUsageNumber,usageLeft]
    },

    activeColor(active){
      return active == 'active' ? this.$themes[this.$store.state.companyTheme][this.$store.state.themeModus].quaternary : this.$themes[this.$store.state.companyTheme][this.$store.state.themeModus].secondary; 
    },

    getPrepaymentType(type) {
			return this.prepaymentTypes.find((prepaymentType) => prepaymentType.id == type)
				? this.prepaymentTypes.find((prepaymentType) => prepaymentType.id == type).name
				: "";
		},

		getPrepaymentStatus(paymentStatus, status) {

			//partialSettled
			if(paymentStatus == "paid" && status.type == "partialSettled"){
				return {
					type: {
						name: "Betaald (gedeeltelijk verrekend)",
						id: "partialSettled",
					},
          color: "#56ca00",
          extra: null,
					timestamp: status.timestamp ? status.timestamp : null,
					receiptId: status.receiptId ? status.receiptId : null,
				};
			}
      else if (paymentStatus == "paid" && status.type == "remainingAmount") {
				return {
					type: {
						name: "Nog niet verekkende restanten",
						id: "remainingAmount",
					},
          extra: null,
					timestamp: status.timestamp ? status.timestamp : null,
					receiptId: status.receiptId ? status.receiptId : null,
				};
			}
      else if (paymentStatus == "paid" && status.type == "settled") {
				return {
					type: {
						name: "Betaald (volledig verrekend)",
						id: "settled",
					},
          color: "#56ca00",
          extra: null,
					timestamp: status.timestamp ? status.timestamp : null,
					receiptId: status.receiptId ? status.receiptId : null,
				};
			} 
			else if (paymentStatus == "paid" && status.type == "compensation") {
				return {
					type: {
						name: "Betaald (Cancel / No-show compensatie)",
						id: "compensation",
					},
          color: "#56ca00",
          extra: null,
					timestamp: status.timestamp ? status.timestamp : null,
					receiptId: status.receiptId ? status.receiptId : null,
				};
			}
      else if (paymentStatus == "paid" && status.type == 'refunded') {		

					return {
						type: {
							name: this.prepaymentReturnTypes.find((prepaymentReturnType) => prepaymentReturnType.id == status.type)
								? this.prepaymentReturnTypes.find((prepaymentReturnType) => prepaymentReturnType.id == status.type).name
								: "",
							id: status.type,
						},
            color: "#56ca00",
            extra: moment(status.timestamp.seconds, "X").format("D-M-YYYY H:mm") + "u",
						timestamp: status.timestamp ? status.timestamp : null,
						receiptId: status.receiptId ? status.receiptId : null,
					};
      }
      else if (paymentStatus == "paid" && !status.type) {		
					return {
						type: {
							name: "Betaald (Nog niet verrekend)",
							id: "paidNotReturned",
						},
            color: this.$themes[this.$store.state.companyTheme][this.$store.state.themeModus].primary,
            extra: null,
						timestamp: null,
						receiptId: null,
					};
				
			} else {
				//paymentStatus == "open"
				return {
					type: {
						name: paymentStatus == "expired || 'failed'" ? "Betaling verlopen" : "Betaling nog niet gedaan",
						id: paymentStatus,
					},
          color: paymentStatus == "expired || 'failed'" ? "#ff4c51" : "#ffb400",
          extra: null,
					timestamp: null,
					receiptId: null,
				};
			}
		},

    cashConvFilter(number, currency){
      return `${formatToCurrency('nl-NL', currency, number)}`
    },

    async checkPrePaymentStatus(item){
      this.prePaymentCheckData = {
        id: item.id,
        loading: true,
        status: '',
      };
      await axios.post(`${this.$store.state.connectionsApi.url}/mollie/payment/status`, {
      //await axios.post(`https://connections.thrive365.nl/mollie/paymentRefund`, {
        id: item.bookingSummaryId,
        // type: "bookingSummary",
        // description: "bookingCanceled"
      })
      .then((response) => {
        // console.log("Mollie Payment Status Success:", response.data);
        this.prePaymentCheckData.status = response.data;
      })
      .catch(error=>{
        this.prePaymentCheckData.status = error.response.data;
        console.error("Error Mollie Payment Status : ", error.response.status, error.response.data)
      })
      this.prePaymentCheckData.loading = false;
    },

    async getSelectedClientFutureBookings(){
      this.futureBookingsLoading = false;
      this.selectedClientFutureBookings = new Array();
      let today = moment().format("YYYY-MM-DD");
      await db.collection("bookingsummary")
      .where("companyId", "==", this.$store.state.activeCompany.id)
      .where("clientId", "==", this.selectedClient.id)
      .where("visit", ">", today)
      .orderBy("visit", "asc")
      .limit(25)
      .get()
      .then(snap => {
        snap.forEach(doc => {
          let clientBooking = doc.data(); 
          clientBooking.id = doc.id;
          let treatmentStartDate =  moment(doc.data().visit, "YYYY-MM-DD").locale('nl').format('dddd D MMM YYYY');
          let treatmentStartTime = moment(doc.data().start, "YYYY-MM-DDTHH:mm").locale('nl').format('H:mm')+'u';
          clientBooking.treatmentStart = treatmentStartDate + ' - ' + treatmentStartTime;

          let devices = [];
          if(doc.data().treatmentDeviceTypes && doc.data().treatmentDeviceTypes.length > 0){
            doc.data().treatmentDeviceTypes.forEach(device=>{
              if(device != null){
                devices.push(device)
              }
            })
          }
          clientBooking.treatmentDeviceTypes = devices;

          if(doc.data().treatmentParameters){
            clientBooking.treatmentParametersOriginal = _.cloneDeep(doc.data().treatmentParameters) 
          }
          else{
            clientBooking.treatmentParameters = {};
            if(clientBooking.treatmentDeviceTypes.length > 0){
              clientBooking.treatmentDeviceTypes.forEach(device=>{
                clientBooking.treatmentParameters[device] = {
                  valid: false,
                  zones: [],
                  zoneParameters: {}
                };
              })
            }
          }
          clientBooking.color = this.$themes[this.$store.state.companyTheme][this.$store.state.themeModus].primary;
          if(doc.data().deleted){
            clientBooking.color = this.$themes[this.$store.state.companyTheme][this.$store.state.themeModus].secondary;
            clientBooking.showInfo = false;
          }          
          clientBooking.treatmentCreated = moment(doc.data().created.seconds, "X").locale('nl').format('D MMMM YYYY - H:mm')+'u';
          this.selectedClientFutureBookings.push(clientBooking);
        });

        this.clientFutureBookingsContinueAt = snap.docs[snap.docs.length - 1];
        
      })
      .catch(error =>{
        console.error("Error getting client booking history: ", error)
      })
      this.futureBookingsLoading = false;
    },

    getMoreSelectedClientFutureBookings(){
      this.extraLoading = true;
      let today = moment().format("YYYY-MM-DD");
      db.collection("bookingsummary")
        .where("companyId", "==", this.$store.state.activeCompany.id)
        .where("clientId", "==", this.selectedClient.id)
        .where("visit", ">", today)
        .orderBy("visit", "asc")
        .startAfter(this.clientFutureBookingsContinueAt)
        .limit(25)
        .get()
        .then(snap => {
          snap.forEach(doc => {
            let clientBooking = doc.data();
            clientBooking.id = doc.id;
            
            let treatmentStartDate =  moment(doc.data().visit).locale('nl').format('dddd D MMMM YYYY');
            let treatmentStartTime = moment(doc.data().start).locale('nl').format('H:mm')+'u';
            clientBooking.treatmentStart = treatmentStartDate + ' - ' + treatmentStartTime;
            if(doc.data().treatmentParameters){
              clientBooking.treatmentParametersOriginal = _.cloneDeep(doc.data().treatmentParameters) 
            }
            clientBooking.color = this.$themes[this.$store.state.companyTheme][this.$store.state.themeModus].primary;
            if(doc.data().deleted){
              clientBooking.color = this.$themes[this.$store.state.companyTheme][this.$store.state.themeModus].secondary;
              clientBooking.showInfo = false;
            }          
            clientBooking.treatmentCreated = moment(doc.data().created, "YYYY-MM-DDTHH:mm").locale('nl').format('dddd D MMMM YYYY - H:mm')+'u';
            this.selectedClientFutureBookings.push(clientBooking)
          });

          this.clientFutureBookingsContinueAt = snap.docs[snap.docs.length - 1];
          this.extraLoading = false;
        })
        .catch(error =>{
          console.error("Error getting client booking history: ", error)
        })
    },

    async getSelectedClientHistoryBookings(){
      this.selectedClientHistoryBookings = new Array();
      this.historyBookingsLoading = true;
      let today = moment().format("YYYY-MM-DD");
      await db.collection("bookingsummary")
        .where("companyId", "==", this.$store.state.activeCompany.id)
        .where("clientId", "==", this.selectedClient.id)
        .where("visit", "<=", today)
        .orderBy("visit", "desc")
        .limit(25)
        .get()
        .then(snap => {
          snap.forEach(doc => {
            let clientBooking = doc.data(); 
            clientBooking.id = doc.id;

            
            
            let treatmentStartDate =  moment(doc.data().visit, "YYYY-MM-DD").locale('nl').format('dddd D MMM YYYY');
            let treatmentStartTime = moment(doc.data().start, "YYYY-MM-DDTHH:mm").locale('nl').format('H:mm')+'u';
            clientBooking.treatmentStart = treatmentStartDate + ' - ' + treatmentStartTime;

            let devices = [];
            if(doc.data().treatmentDeviceTypes && doc.data().treatmentDeviceTypes.length > 0){
              doc.data().treatmentDeviceTypes.forEach(device=>{
                if(device != null){
                  devices.push(device)
                }
              })
            }
            clientBooking.treatmentDeviceTypes = devices;

            if(doc.data().treatmentParameters){
              clientBooking.treatmentParametersOriginal = _.cloneDeep(doc.data().treatmentParameters) 
            }
            else{
              clientBooking.treatmentParameters = {};
              if(clientBooking.treatmentDeviceTypes.length > 0){
                clientBooking.treatmentDeviceTypes.forEach(device=>{
                  clientBooking.treatmentParameters[device] = {
                    valid: false,
                    zones: [],
                    zoneParameters: {}
                  };
                })
              }
            }
            clientBooking.color = this.$themes[this.$store.state.companyTheme][this.$store.state.themeModus].primary;
            if(doc.data().deleted){
              clientBooking.color = this.$themes[this.$store.state.companyTheme][this.$store.state.themeModus].secondary;
              clientBooking.showInfo = false;
            }          
            clientBooking.treatmentCreated = moment(doc.data().created.seconds, "X").locale('nl').format('D MMMM YYYY - H:mm')+'u';
            this.selectedClientHistoryBookings.push(clientBooking)
          });

          this.clientHistoryBookingsContinueAt = snap.docs[snap.docs.length - 1];
          
        })
        .catch(error =>{
          console.error("Error getting client booking history: ", error)
        })
        if(this.selectedClientHistoryBookings.length > 0){
          await new Promise((resolve)=>{
            let counter = 0;
            this.selectedClientHistoryBookings.forEach((booking)=>{
              if(!booking.emailHistory){
                this.getMessageHistory(booking.id).then((data)=>{
                  booking.emailHistory = data;
                  counter++
                  if(counter == this.selectedClientHistoryBookings.length){
                    resolve()
                  }
                })
              }
              else{
                counter++
                if(counter == this.selectedClientHistoryBookings.length){
                  resolve()
                }
              }
            })
          })
        }

        this.historyBookingsLoading = false;
    },

    async getMessageHistory(id){
      let messagesHistory = new Array();
      await db.collection('sendMessages')
      .where("bookingSummaryId", "==", id)
      .orderBy("sendDate", "asc")
      .get()
      .then(snap => {
        snap.forEach(doc => {
          let message = doc.data();
          message.id = doc.id;
          messagesHistory.push(message);
        });
      })
      return  messagesHistory
    },

    getMoreSelectedClientHistoryBookings(){
      this.extraLoading = true;
      let today = moment().format("YYYY-MM-DD");
      db.collection("bookingsummary")
        .where("companyId", "==", this.$store.state.activeCompany.id)
        .where("clientId", "==", this.selectedClient.id)
        .where("visit", "<=", today )
        .orderBy("visit", "desc")
        .startAfter(this.clientHistoryBookingsContinueAt)
        .limit(25)
        .get()
        .then(snap => {
          snap.forEach(doc => {
            let clientBooking = doc.data();
            clientBooking.id = doc.id;
            
            let treatmentStartDate =  moment(doc.data().visit).locale('nl').format('dddd D MMMM YYYY');
            let treatmentStartTime = moment(doc.data().start).locale('nl').format('H:mm')+'u';
            clientBooking.treatmentStart = treatmentStartDate + ' - ' + treatmentStartTime;
            if(doc.data().treatmentParameters){
              clientBooking.treatmentParametersOriginal = _.cloneDeep(doc.data().treatmentParameters) 
            }
            clientBooking.color = this.$themes[this.$store.state.companyTheme][this.$store.state.themeModus].primary;
            if(doc.data().deleted){
              clientBooking.color = this.$themes[this.$store.state.companyTheme][this.$store.state.themeModus].secondary;
              clientBooking.showInfo = false;
            }          
            clientBooking.treatmentCreated = moment(doc.data().created, "YYYY-MM-DDTHH:mm").locale('nl').format('dddd D MMMM YYYY - H:mm')+'u';
            this.selectedClientHistoryBookings.push(clientBooking)
          });

          this.clientHistoryBookingsContinueAt = snap.docs[snap.docs.length - 1];
          this.extraLoading = false;
        })
        .catch(error =>{
          console.error("Error getting client booking history: ", error)
        })
    },

    // Start Datum van Cliëntgoal instellen. Zodat deze als startdatum in de berekening kan worden meegenomen
    setStartDate() {
      if (this.selectedClient.health.startGoalDate) {
        // console.log("Wel een datum gevonden");
        this.date = moment(this.selectedClient.health.startGoalDate.seconds).format("YYYY-MM-DD");
      }
      else {
        // console.log("Geen datum gevonden"); 
        this.date = moment().format("YYYY-MM-DD");
        this.dateDialog;
      }
    },

    // Berekende Ideale gewicht opslaan
    saveIdealWeight() {
      let idealWeight = this.clientIdealWeight;
      let clientHealthData = {};
      clientHealthData.health = this.selectedClient.health;
      clientHealthData.health.idealWeight = this.clientIdealWeight;
      db.collection("clients")
        .doc(this.selectedClient.id)
        .update(clientHealthData)
        .then(() => {
          console.log("Update client ideal weight successfull");
        })
        .then(() => {
          this.selectedClient.health.idealWeight = this.clientIdealWeight;  
        })
        .then(()=>{
          this.clientIdealWeight = null
        })
        .catch(error => {
          console.error(error);
        });
    },

    // Bereken ideale gewicht van Cliënt
    getWeightgoal() {
      this.weightgoalData = {
        gender: this.selectedClient.gender,
        length: this.selectedClient.health.length,
        wristSize: this.selectedClient.health.wristSize
      }
      this.clientIdealWeightFeedback = null;

      db.collection("weightgoals")
        .where("lengthStart", "<=", this.selectedClient.health.length)
        .get()
        .then(snap => {
          this.weightItems = [];
          snap.forEach(doc => {
            if (doc.data().lengthEnd >= this.selectedClient.health.length) {
              let weightItem = doc.data();
              this.weightItems.push(weightItem);
            }
          });
        })
        .then(() => {
          if (this.weightItems.length > 0) {
            if ((this.selectedClient.gender == 1)) {
              this.clientIdealWeight =
                this.weightItems[0].physique.male[this.clientPhysique].start +
                "-" +
                this.weightItems[0].physique.male[this.clientPhysique].end;
            } else if ((this.selectedClient.gender == 2)) {
              this.clientIdealWeight =
                this.weightItems[0].physique.female[this.clientPhysique].start +
                "-" +
                this.weightItems[0].physique.female[this.clientPhysique].end;
            } else {
              this.clientIdealWeight = null;
            }
          } else {
            this.clientIdealWeight = null;
            this.clientIdealWeightFeedback = "Het ideale bericht kan niet berekend worden.";
          }
        });
    },

    // Bereken aantal items in een Object
    getObjectSize(obj){
      var size = 0, key;
        for (key in obj) {
            if (obj.hasOwnProperty(key)) size++;
        }
        return size;
    },

    formatDate(date) {
      if (!date) return null;
      else if (date) {
        const [year, month, day] = date.split("-");
        return `${day}-${month}-${year}`;
      }
    },

    // Alle omvangsmetingen binnenhalen uit de database
    loadMeasurements() {
      this.loadingMeasurements = true
      this.retryLoadingMeasurements = false
      this.feedbackMeasurements = null

      this.measurements = []
      this.measurementsUpperArmLeft = []
      this.measurementsUpperArmRight = []
      this.measurementsChest = []
      this.measurementsUpperBelly = []
      this.measurementsLowerBelly = []
      this.measurementsHip = []
      this.measurementsButtocks = []
      this.measurementsUpperLegLeft = []
      this.measurementsUpperLegRight = []
      this.measurementsCalfLeft = []
      this.measurementsCalfRight = []
      this.measurementsAnkleLeft = []
      this.measurementsAnkleRight = []

      this.measurementsData = {
        upperArmLeft: { order: 1, data: []},
        upperArmRight: { order: 2, data: []},
        chest: { order: 3, data: []},
        upperBelly: { order: 4, data: []},
        lowerBelly: { order: 5, data: []},
        hip: { order: 6, data: []},
        buttocks: { order: 7, data: []},
        upperLegLeft: { order: 8, data: []},
        upperLegRight: { order: 9, data: []},
        calfLeft: { order: 10, data: []},
        calfRight: { order: 11, data: []},
        ankleLeft: { order: 12, data: []},
        ankleRight: { order: 13, data: []},
      };

      this.chartMeasureData = {
        upperArmLeft: [["Datum", "Omvang"]],
        upperArmRight: [["Datum", "Omvang"]],
        chest: [["Datum", "Omvang"]],
        upperBelly: [["Datum", "Omvang"]],
        lowerBelly: [["Datum", "Omvang"]],
        hip: [["Datum", "Omvang"]],
        buttocks: [["Datum", "Omvang"]],
        upperLegLeft: [["Datum", "Omvang"]],
        upperLegRight: [["Datum", "Omvang"]],
        calfLeft: [["Datum", "Omvang"]],
        calfRight: [["Datum", "Omvang"]],
        ankleLeft: [["Datum", "Omvang"]],
        ankleRight: [["Datum", "Omvang"]]
      };

      db.collection("measurements")
        .where("companyId", "==", this.$store.state.activeCompany.id)
        .where("clientId", "==", this.selectedClient.id)
        .orderBy("dateAdded")
        .get()
        .then(snap => {
          snap.forEach(doc => {
            let measurement = doc.data();
            measurement.id = doc.id;
            measurement.unix = measurement.dateAdded.seconds;
            if(measurement.time) {
              let date = moment.unix(measurement.unix).format("YYYY-MM-DD");
              let dateAndTime = date + "T" + measurement.time;
              measurement.unix = moment(dateAndTime, "YYYY-MM-DDTHH:mm").format("X");
            }
            else{
              measurement.time = null;
            }
            this.measurements.push(measurement);

          });
        })
        .then(() => {
          if(this.measurements.length > 0){
             this.measurements.sort(function(a, b){
              return a.unix - b.unix;
            });
          
            this.measurements.forEach(measurement=>{

              this.measurementTypes.forEach(measurementType=>{
                if(measurement[measurementType.id]){
                  let dateAdded = moment.unix(measurement.dateAdded.seconds).format("D MMM YYYY");

                  // Prepare MeasurmentData
                  let measurementData = {};
                  measurementData.id = measurement.id;
                  measurementData.time = measurement.time;
                  measurementData.date = moment.unix(measurement.dateAdded.seconds).local('nl').format("D MMM YYYY");
                  measurementData.value = measurement[measurementType.id];
                  measurementData.unix = measurement.dateAdded.seconds;

                  if(measurement.time){
                    let hourUnix = Number(measurement.time.substring(0, 2)) * 60 * 60;
                    let minutesUnix = Number(measurement.time.substring(3,5)) * 60;
                    let extraUnix = hourUnix + minutesUnix;
                    let lastUnix = String(measurement.dateAdded.seconds).substring(7,10);
                    if(lastUnix == '000'){
                      measurementData.unix = measurementData.unix + extraUnix;
                    }
                    dateAdded = dateAdded + " - " + measurement.time + "u";
                  }
                  // Prepare Chartdata
                  let chartData = [];
                  chartData.push(dateAdded);
                  chartData.push(measurement[measurementType.id]);
                  
                  // Push  and MeasurmentData to Arrays
                  this.measurementsData[measurementType.id].data.push(measurementData); 
                  // this.measurementsData[measurementType.id].data = _.orderBy(this.measurementsData[measurementType.id].data, ['unix'], ['desc'])
                  this.chartMeasureData[measurementType.id].push(chartData);
                  console.log("chartdata:", chartData)
                }
              })
            })
          }

          this.loadingMeasurements = false;
        })
        .catch((error)=>{
          this.loadingMeasurements = false
          this.retryLoadingMeasurements = true
          console.error("Fout bij binnenladen van omvangsmetingen:" + error)
        })
    },

     async checkAddress(){
      if(this.selectedClient.address.country && this.selectedClient.address.postalCode && this.selectedClient.address.number){
        this.addressChecked = false;
        this.addressNotFound = false;
        this.clientAddressChecked = {
          postalCode: _.cloneDeep(this.selectedClient.address.postalCode),
          number: _.cloneDeep(this.selectedClient.address.number ),
          numberAddition: _.cloneDeep(this.selectedClient.address.numberAddition )
        }
        let url = `${this.$store.state.locationsApi.url}/getLocation?country=${this.selectedClient.address.country}&postalcode=${this.selectedClient.address.postalCode}&number=${this.selectedClient.address.number}`;
       await axios
        .get(url)
        .then((response) => {
          // console.log("checkAddress response: ", response, url)
          if(response.status == 200 && response.data){
            this.selectedClient.address.city = response.data.city;
            this.selectedClient.address.street = response.data.street;
            this.selectedClient.address.coordinates = response.data.coordinates;       
          }
          else{
            this.addressNotFound = true;
          }
        })
        .catch(error => {
          console.error("Postcode API error: ", error);
          this.addressNotFound = true;
        })
        this.addressChecked = true;
      }
    },

    durationSuffix(item) {
				if (item.paymentPeriod == "daily") {
					if (item.duration == 1) {
						return "dag";
					} else {
						return "dagen";
					}
				} else if (item.paymentPeriod == "everyWeek") {
					if (item.duration == 1) {
						return "week";
					} else {
						return "weken";
					}
				} else if (item.paymentPeriod == "everyFourWeeks") {
					return "vierweken";
				} else if (item.paymentPeriod == "everyMonth") {
					if (item.duration == 1) {
						return "maand";
					} else {
						return "maanden";
					}
				} else if (item.paymentPeriod == "everyQuarter") {
					if (item.duration == 1) {
						return "kwartaal";
					} else {
						return "kwartalen";
					}
				} else if (item.paymentPeriod == "everyHalfYear") {
					if (item.duration == 1) {
						return "halfjaar";
					} else {
						return "halfjaren";
					}
				} else if (item.paymentPeriod == "everyYear") {
					if (item.duration == 1) {
						return "jaar";
					} else {
						return "jaren";
					}
				} else {
					return `${item.paymentPeriod}`;
				}
			},


    // Controleer welke Pakketten het bedrijf heeft.
    checkPackages(){
      let companyDataPackages = this.$store.state.companySubscription.packages;
      let companyPackages = {}
      companyPackages.type = null
      companyPackages.list = {}

      if(this.getObjectSize(companyDataPackages) > 0){
        // Packages gevonden. Nu kijken of ze nog actief zijn.
        let activePackages = 0
        let today = moment().unix()
        let counter = 0

         // Door Object heen loopen met Promise
        new Promise((resolve, reject) => {
          for (var key in companyDataPackages) {
            if(companyDataPackages[key].endDate){
              // Kijken of pakket opgezegd is.
              if(companyDataPackages[key].endDate.seconds > today){
                // Pakket is opgezegd. Kijken of de einddatum in de toekomst ligt
                companyPackages.list[key] = true
                activePackages++
              } 
            } else{
              companyPackages.list[key] = true
              activePackages++
            }
            counter++
            if(counter == this.getObjectSize(companyDataPackages)){
              resolve()
            }
          }
        })
        .then(()=>{
          if(activePackages > 0){
            // Actieve pakketen gevonden.

            this.companyPackageStatus = 'paidVersion'
            companyPackages.type = 'paidVersion'

            this.$store.dispatch("companyPackages", companyPackages);
          } else{
            // Geen actieve pakketen gevonden. De gebruiker maakt gebruik van de gratis versie
            this.companyPackageStatus = 'freeVersion'
            companyPackages.type = 'freeVersion'
            this.$store.dispatch("companyPackages", companyPackages);
          }
        })
      } else {
        // Geen pakketen gevonden. De gebruiker maakt gebruik van de gratis versie
        this.companyPackageStatus = 'freeVersion'
        companyPackages.type = 'freeVersion'
        this.$store.dispatch("companyPackages", companyPackages);
      }
    }
  },

  computed: {

    selectedClientHistoryBookingsFiltered(){
      if(this.showDeletedBookings){
        return this.selectedClientHistoryBookings;
      }
      else{
        return this.selectedClientHistoryBookings.filter(item=>{ return !item.deleted })
      }
    },

    selectedClientHistoryBookingsDeletedFiltered(){
      return this.selectedClientHistoryBookings && this.selectedClientHistoryBookings.length > 0 ? this.selectedClientHistoryBookings.filter(item=>{ return item.deleted }) : new Array()
    },


    clientsExcluded(){
      let excludedClients = _.cloneDeep(this.clientsConnected);
      excludedClients.push(this.selectedClient);
      return excludedClients;
    },


    prePaymentsSelectorPages(){
      return Math.ceil(this.clientPrePayments.length / 20)
    },

    prePaymentHeaderDynamic(){
      let headers = _.cloneDeep(this.prePaymentHeader);
      if(this.$store.state.userRoleNumber < 2 && !this.$store.state.demoMode){
        headers.unshift({
					text: "DocId",
					align: "left",
					sortable: false,
          width: "220px",
					value: "id",
				})
        headers.push({
					text: "",
					align: "left",
					sortable: false,
          width: "250px",
					value: "id",
				})
      }
      return headers
    },

    tagTypes(){
      let leadTag = {
        id: 'lead',
        color: 'primary',
        name: {
          nl:  'Lead',
          en:  'Lead'
        },
        description: {
          nl:  '',
          en:  ''
        }
      }
      let companyTags = _.cloneDeep(this.$store.state.activeCompanyTags)
      companyTags.push(leadTag)
      return companyTags
    },


    selectedView(){
        return id => {
        if(this.activeClientView == id){
          return true;
        }
        else {
          return false;
        }
      }
    },

    availableLanguages(){
      let availableLanguages = this.$countries.filter(language => { // Filter rich languages data from company languages
        if(this.$store.state.activeCompany.languages.includes(language.id)){ return language.id }
      });
      // Show languages in the same order as company languages
      return availableLanguages.sort((a, b) => this.$store.state.activeCompany.languages.indexOf(a.id) - this.$store.state.activeCompany.languages.indexOf(b.id)); 
    },

    addressChanged(){
      let clientAddress = Object.assign({ postalCode: this.selectedClient.address.postalCode, number: this.selectedClient.address.number, numberAddition: this.selectedClient.address.numberAddition})
      let clientAddressChecked = this.clientAddressChecked;

      return _.isEqual(clientAddress, clientAddressChecked) || _.isEqual(this.selectedClient.address, this.selectedClientOriginal.address)? false : true;
    },

    clientAddressChanged(){
      return _.isEqual(this.selectedClient.address, this.selectedClientOriginal.address)? false : true;
    },


    tagNameDisabled(){
      return this.newTag.id ? true : false;
    },

    selectedClientTags(){
      return this.selectedClient.tags && this.selectedClient.tags.length > 0 ? this.selectedClient.tags.filter(tag => tag.companyId == this.$store.state.activeCompany.id && !tag.deleted) : new Array(); 
    },

    viewClient() {
      if(this.$store.state.userRules){
        if(this.$store.state.userRules.free && (_.indexOf(this.$store.state.userRules.free, 'viewClient') >= 0)){
          return true; // Rule found in free
        }
        else{ // Check if some user is logged in
          if(this.$store.state.connectedEmployee){ // Employee is logged in
            if(this.$store.state.activeUserRoleNumber < 4){
              return true; // userRole OVERRULES the rules
            }
            else{
              if(this.$store.state.userRules.restricted && (_.indexOf(this.$store.state.userRules.free, 'viewClient') >= 0)){
                return true; // Rule found in restricted
              }
              else{ return false;  } // Rule NOT found in restricted
            }
          }
          else{ return false; } // Employee is NOT logged in
        }
      }
      else{ return true; } // No branch is logged in
    },

    editClient() {
      if(this.$store.state.userRules){
        if(this.$store.state.userRules.free && (_.indexOf(this.$store.state.userRules.free, 'editClient') >= 0)){
          return true; // Rule found in free
        }
        else{ // Check if some user is logged in
          if(this.$store.state.connectedEmployee){ // Employee is logged in
            if(this.$store.state.activeUserRoleNumber < 4){
              return true; // userRole OVERRULES the rules
            }
            else {
              if(this.$store.state.userRules.restricted && (_.indexOf(this.$store.state.userRules.free, 'editClient') >= 0)){
                return true; // Rule found in restricted
              }
              else{ return false; } // Rule NOT found in restricted
            }
          }
          else{ return false; } // Employee is NOT logged in
        }
      }
      else{ return true; } // No branch is logged in
    },

    version() {
      return this.$store.state.version;
    },
    // Kijken wanneer het doel van de klant veranderd
    weightgoalDataChanged(){
      if(this.selectedClientOriginal.gender == this.selectedClient.gender && this.selectedClientOriginal.health.length == this.selectedClient.health.length  && this.selectedClientOriginal.health.wristSize == this.selectedClient.health.wristSize ){
        return false
      } else {
        return true
      }
    },

    columnWidth(){
      // if(this.type == 'modal'){
      //   return 'xs12'
      // }
      // else{
      //   return 'xs6'
      // }
      return 'xs6'
    },

    selectedClientFutureBookingsFiltered(){
      if(this.showDeletedBookings){
        return this.selectedClientFutureBookings
      }
      else{
        let bookings = [];
        this.selectedClientFutureBookings.forEach(booking=>{
          if(!booking.deleted){
            bookings.push(booking);
          }
        })
        return bookings;
      }
    },

    selectedClientFutureBookingsActive(){
      let bookings = [];
      this.selectedClientFutureBookings.forEach(booking=>{
        if(!booking.deleted){
          bookings.push(booking);
        }
      })
      return bookings;
    },

    measurementsDataAvaiable(){
      let data = [];
      if(this.measurementsData){
        this.measurementTypes.forEach(measurementType=>{
          if(this.measurementsData[measurementType.id].data && this.measurementsData[measurementType.id].data.length > 0){
            let measurementsData = {
              order: this.measurementsData[measurementType.id].order,
              data: this.measurementsData[measurementType.id].data,
              id: measurementType.id,
              name: measurementType.name,
              class: measurementType.class
            }
            data.push(measurementsData);
          }
        })
        data = _.sortBy(data, 'order');
      }

      return data;
    },

    bodypartsNavigationWidth(){
      if(this.type == 'modal'){
        return 'md4';
      }
      else{
        return 'md3';
      }
    },

    bodypartsContentWidth(){
      if(this.type == 'modal'){
        return 'md8';
      }
      else{
        return 'md9';
      }
    },

    userCompanies() {
      return this.$store.state.connectedEmployee ? this.$store.state.connectedEmployee.accessCompanies : this.$store.state.userCompanies;
    },
    userCompany() {
      return this.$store.state.connectedEmployee ? this.$store.state.connectedEmployee.company : this.$store.state.userCompany;
    },
    userFirstName() {
      return this.$store.state.connectedEmployee ? this.$store.state.connectedEmployee.name : this.$store.state.userFirstName;
    },
    userLastName() {
      return this.$store.state.connectedEmployee ? this.$store.state.connectedEmployee.surname : this.$store.state.userLastName;
    },
    userRole() {
      return this.$store.state.connectedEmployee ? this.$store.state.connectedEmployee.userRole : this.$store.state.userRole;
    },
    userId() {
      return this.$store.state.connectedEmployee ? this.$store.state.connectedEmployee.userId : this.$store.state.userId;
    },
    companySubscription() {
      return this.$store.state.companySubscription;
    },
    companyPackages() {
      return this.$store.state.companyPackages;
    },

    moreHistoryTreatmentsAvailable(){
      return Number.isInteger((this.selectedClientHistoryBookings.length / 25) )
    },
    moreFutureTreatmentsAvailable(){
      return Number.isInteger((this.selectedClientFutureBookings.length / 25) )
    },

    filteredCompanies(){
      if (this.searchCompanies && this.searchCompanies.length > 0){
        return this.companies.filter(company => {
          return company.name.toLowerCase().includes(this.searchCompanies.toLowerCase());
        });
      } else {
        return this.companies;
      }
    },
    selectedBodyPart() {
      return _.findKey(this.bodyPictures, function(o) { return o.selected == true; });
    },

    activeGender() {
      if (this.selectedClient.gender == 1) {
        return "man";
      } else {
        return "woman";
      }
    },
    // Bereken de bouw van de klant
    clientPhysique() {
      if (this.selectedClient.health.wristSize && this.selectedClient.health.length) {
        //console.log("Ingevuld");
        if (this.selectedClient.gender == 1) {
          if (this.selectedClient.health.length / this.selectedClient.health.wristSize > 10.4) {
            //console.log("Man - Smalle bouw");
            return 'small';
          } else if (
            10.4 >= this.selectedClient.health.length / this.selectedClient.health.wristSize &&
            this.selectedClient.health.length / this.selectedClient.health.wristSize >= 9.6
          ) {
            //console.log("Man - Gemiddelde bouw");
            return "medium";
          } else if (this.selectedClient.health.length / this.selectedClient.health.wristSize < 9.6) {
            //console.log("Man - Stevige bouw");
            return "large";
          }
        }
        if (this.selectedClient.gender == 2) {
          if (this.selectedClient.health.length / this.selectedClient.health.wristSize > 10.9) {
            //console.log("Vrouw - Smalle bouw");
            return 'small';
          } else if (
            10.9 >= this.selectedClient.health.length / this.selectedClient.health.wristSize &&
            this.selectedClient.health.length / this.selectedClient.health.wristSize >= 9.9
          ) {
            //console.log("Vrouw - Gemiddelde bouw");
            return "medium";
          } else if (this.selectedClient.health.length / this.selectedClient.health.wristSize < 9.9) {
            //console.log("Vrouw - Stevige bouw");
            return "large";
          }
        }
      } else {
        return null;
      }
    },

    birthdayDate(){
      let date = {
        readable: "",
        formatted: "",
        firebase: null
      }
      if(this.birthdayDateRaw){
        date.readable = moment(this.birthdayDateRaw).format("D MMMM YYYY");
        date.formatted = moment(this.birthdayDateRaw).format("YYYY/MM/DD");
        date.firebase = moment(this.birthdayDateRaw).format("YYYY-MM-DD");
        return date
      }
      else if(this.selectedClient.birthday){
        date.readable = moment(this.selectedClient.birthday, "YYYY-MM-DD").format("D MMMM YYYY");
        date.formatted = moment(this.selectedClient.birthday, "YYYY-MM-DD").format("YYYY/MM/DD");
        date.firebase = moment(this.selectedClient.birthday, "YYYY-MM-DD").format("YYYY-MM-DD");
        return date
      }
      else {
        return date
      }
      return date
    },

    // Datum omvormen naar Europese leesbare datum
    addedDateFormatted() {
      return this.formatDate(this.date);
    },
    // Datum omvormen om te gebruiken in Firebase
    firebaseDateFormatted() {
      return new Date(this.date);
    },

    changed() {
      let changes = this.changes
      let emails = this.selectedClient.emails ? _.isEqual(this.selectedClient.emails, this.selectedClientOriginal.emails) : true;
      let phones = this.selectedClient.phones ? _.isEqual(this.selectedClient.phones, this.selectedClientOriginal.phones) : true;
      let birthday = _.isEqual(this.selectedClient.birthday, this.birthdayDate.firebase)

      if (_.isEqual(this.selectedClient, this.selectedClientOriginal) == false || !birthday || !emails || !phones) {
        return true;
      }
      else {
        return false;
      }
    },

    notesChanged() {
      if (_.isEqual(this.pictureDetailsNotesClone, this.bodyPictures[this.selectedBodyPart].pictures[this.bodyPictures[this.selectedBodyPart].selectedPicture].notes) == false) {
        return true;
      } else {
        return false;
      }
    }
  }
};
</script>

<style>
.client-count p {
  font-size: 13px;
  margin: 0;
  padding: 15px 0 10px 0;
  font-style: italic;
}
.client-count p.no-clients {

  padding: 50px 0;
}

.client-icon{
  width: 35px;
  height: 35px;
  display: block;
  margin: 0 auto;
}

.client-icon.overview{
  background: url("../assets/icons/client-overview-icon.svg") no-repeat center center;
  background-size: 25px auto; 
}

li.is-active a .client-icon.overview{
  background: url("../assets/icons/client-overview-icon-active.svg") no-repeat center center;
  background-size: 25px auto; 
}

.client-icon.bodycomposition{
  background: url("../assets/icons/client-composition-icon.svg") no-repeat center center;
  background-size: 28px auto; 
}

li.is-active a .client-icon.bodycomposition{
  background: url("../assets/icons/client-composition-icon-active.svg") no-repeat center center;
  background-size: 28px auto; 
}

.client-icon.measurement{
  background: url("../assets/icons/client-ruler-icon.svg") no-repeat center center;
  background-size: 28px auto; 
}

li.is-active a .client-icon.measurement{
  background: url("../assets/icons/client-ruler-icon-active.svg") no-repeat center center;
  background-size: 28px auto; 
}

.client-icon.photos{
  background: url("../assets/icons/client-photo-icon.svg") no-repeat center center;
  background-size: 28px auto; 
}

li.is-active a .client-icon.photos{
  background: url("../assets/icons/client-photo-icon-active.svg") no-repeat center center;
  background-size: 28px auto; 
}

.client-icon.history{
  background: url("../assets/icons/client-history-icon.svg") no-repeat center center;
  background-size: 25px auto; 
}

li.is-active a .client-icon.history{
  background: url("../assets/icons/client-history-icon-active.svg") no-repeat center center;
  background-size: 25px auto; 
}

/* TABS */

.special-content-box.client  ul.tabs-component-tabs {
  display: inline-block;
  list-style: none;
  height: 65px;
}

.special-content-box.client ul.tabs-component-tabs:after {
  display: block;
  content: "";
  clear: both;
}

.special-content-box.client  ul.tabs-component-tabs li {
  float: left;
  display: block;
  margin: 0 10px 0 0;
}

.special-content-box.client  ul.tabs-component-tabs li a {
  padding: 8px 14px 6px 14px;
  background-color: #f6f7fb;
  color: rgba(0,0,0,.87);
  font-size: 15px;
  text-decoration: none;
  display: block;
  border: 1px solid #e8e9ee;
  border-top-right-radius: 2px;
  border-top-left-radius: 2px;
}

.special-content-box.client  ul.tabs-component-tabs li.is-active a {
  background-color: #fff;
  font-weight: bold;
  border-bottom: 1px solid #ffffff;
}

.tab-component-header {
  padding: 25px 25px 0 25px;
}

.tab-component-content {
  padding: 20px;
}

h3.client-overview-subtitle{
  font-weight: bold;
}

.ideal-weight-info-icon, 
.ideal-weight-info-icon:hover {
  position: absolute;
  top: 6px;
  right: 6px;
  width: 20px;
  height: 20px;
  z-index: 9;
  cursor: pointer;
}

.ideal-weight-wrapper {
  position: relative;
  margin: 0;
  padding: 10px;
  background-color: #ffffff;
}
.ideal-weight-outer-wrapper{
  width: 100%;
  position: relative;
}

.ideal-weight-button {
  display: block;
  border: 1px solid  var(--primary-color);
  background-color:  var(--primary-color);
  text-transform: uppercase;
  color: #fff;
  font-size: 14px;
  font-family: "Sofia Pro", Helvetica, Arial, sans-serif;
  text-align: center;
  border-radius: 100px;
  padding: 8px 0;
  margin: 10px 0 0 0;
  width: 100%;
  cursor: pointer;
}

.save-ideal-weight-button {
  display: block;
  border: 1px solid #f48c00;
  background-color: #f48c00;
  text-transform: uppercase;
  color: #fff;
  font-size: 14px;
  font-family: "Sofia Pro", Helvetica, Arial, sans-serif;
  text-align: center;
  border-radius: 100px;
  margin: 10px 0 0 0;
  padding: 8px 0;
  width: 100%;
  cursor: pointer;
}

.ideal-weight-button.depressed {
  opacity: 0.5;
}

.explain-weigth-woman {
  width: 250px;
  height: 200px;
  margin: 15px auto;
  background: url("../assets/icons/woman-explain-icon.svg") no-repeat center center;
  background-size: auto 200px;
}

.client-column-two{
  background-color: #f4f7fc;
}

.composition-history-chart-wrapper{
  background-color: #ffffff;
  padding: 0px;
}

.choose-view-type-wrapper {
  padding: 15px 0 20px 0;
}
.choose-view-type-buttons {
  width: 80%;
  max-width: 350px;
  margin: 0 auto;
  position: relative;
}

.view-type-button {
  width: calc(50% - 1px);
  float: left;
  height: 32px;
  text-align: center;
  color:  var(--primary-color);
  font-size: 13px;
  line-height: 32px;
  background-color: #fff;
  border-bottom: 1px solid  var(--primary-color);
  border-top: 1px solid  var(--primary-color);
}

.view-type-button.active {
  background-color:  var(--primary-color);
  color: #fff;
}

.view-type-button.one {
  border-left: 1px solid  var(--primary-color);
  border-top-left-radius: 3px;
  border-bottom-left-radius: 3px;
}
.view-type-button.two {
  border-right: 1px solid  var(--primary-color);
  border-top-right-radius: 3px;
  border-bottom-right-radius: 3px;
}
.composition-type {
  font-weight: 700;
}

.composition-history-wrapper h1 {
    font-family: "TT Norms", Helvetica, Arial, sans-serif;
    font-size: 16px;
    color: rgba(0, 0, 0, 0.87);
    letter-spacing: 1.5px;
    text-transform: uppercase;
    text-align: center;
    font-weight: 600;
    padding: 10px 0;
}
.client-tab-header{
  min-height: 50px;
  padding:10px;
}


.first-measurement,
.reload-button {
  padding: 50px;
}

.first-measurement .v-btn,
.reload-button .v-btn {
  display: block;
  margin: 20px auto;
}

.first-measurement p,
.reload-button p {
  font-family: "Sofia Pro", Helvetica, Arial, sans-serif;
  font-size: 14px;
  color: rgba(0, 0, 0, 0.87);
  letter-spacing: 0;
  text-align: center;
}

.measurement-items-wrapper {
  background-color: #fff;
  width: 100%;
}
.measurement-item-wrapper {
  border-bottom: 1px solid #dbe5ff;
  background-color: #fff;
}

.measurement-item-header {
  padding: 8px 0;
}

.measurement-item-icon {
  width: 56px;
  height: 56px;
  float: left;
  margin: 0;
  background-color: #f1f1f1;
}
.measurement-item-text {
  float: left;
  width: calc(100% - 100px);
  font-family: "TT Norms", Helvetica, Arial, sans-serif;
  font-weight: 800;
  color: #000000;
  font-size: 17px;
  padding: 15px 16px;
}

.measurement-items-wrapper.man .measurement-item-icon.upper-arm-left {
  background: url("../assets/body-icons/upper-arm-left-male.png") no-repeat center center;
  background-size: 56px;
}

.measurement-items-wrapper.man .measurement-item-icon.upper-arm-right {
  background: url("../assets/body-icons/upper-arm-right-male.png") no-repeat center center;
  background-size: 56px;
}

.measurement-items-wrapper.man .measurement-item-icon.chest {
  background: url("../assets/body-icons/chest-male.png") no-repeat center center;
  background-size: 56px;
}

.measurement-items-wrapper.man .measurement-item-icon.upper-belly {
  background: url("../assets/body-icons/upper-belly-male.png") no-repeat center center;
  background-size: 56px;
}

.measurement-items-wrapper.man .measurement-item-icon.lower-belly {
  background: url("../assets/body-icons/lower-belly-male.png") no-repeat center center;
  background-size: 56px;
}

.measurement-items-wrapper.man .measurement-item-icon.hip {
  background: url("../assets/body-icons/hip-male.png") no-repeat center center;
  background-size: 56px;
}

.measurement-items-wrapper.man .measurement-item-icon.buttocks {
  background: url("../assets/body-icons/buttocks-male.png") no-repeat center center;
  background-size: 56px;
}

.measurement-items-wrapper.man .measurement-item-icon.upperleg-left {
  background: url("../assets/body-icons/upper-leg-left-male.png") no-repeat center center;
  background-size: 56px;
}

.measurement-items-wrapper.man .measurement-item-icon.upperleg-right {
  background: url("../assets/body-icons/upper-leg-right-male.png") no-repeat center center;
  background-size: 56px;
}

.measurement-items-wrapper.man .measurement-item-icon.calf-left,
.measurement-items-wrapper.woman .measurement-item-icon.calf-left {
  background: url("../assets/body-icons/calf-left-male.png") no-repeat center center;
  background-size: 56px;
}
.measurement-items-wrapper.man .measurement-item-icon.calf-right,
.measurement-items-wrapper.woman .measurement-item-icon.calf-right {
  background: url("../assets/body-icons/calf-right-male.png") no-repeat center center;
  background-size: 56px;
}
.measurement-items-wrapper.man .measurement-item-icon.ankle-left,
.measurement-items-wrapper.woman .measurement-item-icon.ankle-left {
  background: url("../assets/body-icons/ankle-left-male.png") no-repeat center center;
  background-size: 56px;
}
.measurement-items-wrapper.man .measurement-item-icon.ankle-right,
.measurement-items-wrapper.woman .measurement-item-icon.ankle-right {
  background: url("../assets/body-icons/ankle-right-male.png") no-repeat center center;
  background-size: 56px;
}
.measurement-items-wrapper.woman .measurement-item-icon.upper-arm-left {
  background: url("../assets/body-icons/upper-arm-left-female.png") no-repeat center center;
  background-size: 56px;
}

.measurement-items-wrapper.woman .measurement-item-icon.upper-arm-right {
  background: url("../assets/body-icons/upper-arm-right-female.png") no-repeat center center;
  background-size: 56px;
}

.measurement-items-wrapper.woman .measurement-item-icon.chest {
  background: url("../assets/body-icons/chest-female.png") no-repeat center center;
  background-size: 56px;
}

.measurement-items-wrapper.woman .measurement-item-icon.upper-belly {
  background: url("../assets/body-icons/upper-belly-female.png") no-repeat center center;
  background-size: 56px;
}

.measurement-items-wrapper.woman .measurement-item-icon.lower-belly {
  background: url("../assets/body-icons/lower-belly-female.png") no-repeat center center;
  background-size: 56px;
}

.measurement-items-wrapper.woman .measurement-item-icon.hip {
  background: url("../assets/body-icons/hip-female.png") no-repeat center center;
  background-size: 56px;
}

.measurement-items-wrapper.woman .measurement-item-icon.buttocks {
  background: url("../assets/body-icons/buttocks-female.png") no-repeat center center;
  background-size: 56px;
}

.measurement-items-wrapper.woman .measurement-item-icon.upperleg-left {
  background: url("../assets/body-icons/upper-leg-left-female.png") no-repeat center center;
  background-size: 56px;
}

.measurement-items-wrapper.woman .measurement-item-icon.upperleg-right {
  background: url("../assets/body-icons/upper-leg-right-female.png") no-repeat center center;
  background-size: 56px;
}

.measurement-items-wrapper .v-expansion-panel__header {
  padding: 8px 16px;
  background-color: #fff;
}

.measurement-items-wrapper .v-expansion-panel {
  box-shadow: none !important;
}
.measurement-items-wrapper .theme--light.v-expansion-panel .v-expansion-panel__container,
.measurement-items-wrapper .v-expansion-panel__body {
  background-color: #efeff4;
}

.measurement-items-wrapper .theme--light.v-expansion-panel .v-expansion-panel__container {
  border-top: 1px solid #dbe5ff;
}

.measurement-chart {
  background: #f9f9f9;
  margin: 15px 15px 0 15px ;
  padding: 10px 0 ;
}

.measurement-numbers{
  padding: 20px 20px 10px 20px;
  margin: 0;
}

.measurements-expansion-wrapper{
  border-top: 1px solid #dbe5ff;
}


.bodyparts-navigation-wrapper{
  position: relative;
  height: 100%;
  border-right: 1px solid var(--cardOption-color);
}

.bodypart-icon{
  width: 50px;
  height: 70px;
  float: left!important;
}

.bodyparts-navigation-item {
  border-bottom: 1px solid var(--cardOption-color);
  background-color: var(--card-color);
}

.bodyparts-navigation-item.selected {
  background-color: #ebf8fd;
}

.bodyparts-navigation-item{
  cursor: pointer;
}

.bodyparts-navigation-item.empty{
  cursor: default
}

.bodyparts-navigation-item.empty .bodypart-title,
.bodyparts-navigation-item.empty .bodypart-title .extra-label,
.bodyparts-navigation-item.empty .bodypart-pictures,
.bodyparts-navigation-item.empty .bodypart-pictures span{
  color: var(--text-color);
}

.bodyparts-navigation-item.empty .bodypart-icon{
  opacity: 0.4;
}

.bodypart-text{
  float: left;
  width: calc(100% - 50px);
  height: 70px;
}

.bodypart-text .bodypart-title{
  padding: 12px 0 0 0;
  font-size: 16px;
  font-weight: 600;
  color: var(--text-color);
}

.bodypart-text .bodypart-pictures,
.bodypart-text .bodypart-pictures span{
  font-size: 14px;
  font-weight: 400;
  color: var(--text-color);
}


.bodypart-title .extra-label{
  font-weight: 300;
}


.bodypart-icon.headfront{
  background: url("../assets/bodyparts-small/head-front-selected.png") no-repeat center center;
  background-size: 22px auto;
}

.bodypart-icon.headleft{
  background: url("../assets/bodyparts-small/head-left-selected.png") no-repeat center center;
  background-size: 22px auto;
}

.bodypart-icon.headright{
  background: url("../assets/bodyparts-small/head-right-selected.png") no-repeat center center;
  background-size: 22px auto;
}

.bodypart-icon.upperbodyfront{
  background: url("../assets/bodyparts-small/upperbody-front-selected.png") no-repeat center center;
  background-size: 22px auto;
}

.bodypart-icon.upperbodyback{
  background: url("../assets/bodyparts-small/upperbody-back-selected.png") no-repeat center center;
  background-size: 22px auto;
}

.bodypart-icon.upperbodyleft{
  background: url("../assets/bodyparts-small/upperbody-left-selected.png") no-repeat center center;
  background-size: 22px auto;
}

.bodypart-icon.upperbodyright{
  background: url("../assets/bodyparts-small/upperbody-right-selected.png") no-repeat center center;
  background-size: 22px auto;
}

.bodypart-icon.lowerbodyfront{
  background: url("../assets/bodyparts-small/lowerbody-front-selected.png") no-repeat center center;
  background-size: 22px auto;
}

.bodypart-icon.lowerbodyback{
  background: url("../assets/bodyparts-small/lowerbody-back-selected.png") no-repeat center center;
  background-size: 22px auto;
}

.bodypart-icon.lowerbodyleft{
  background: url("../assets/bodyparts-small/lowerbody-left-selected.png") no-repeat center center;
  background-size: 22px auto;
}

.bodypart-icon.lowerbodyright{
  background: url("../assets/bodyparts-small/lowerbody-right-selected.png") no-repeat center center;
  background-size: 22px auto;
}

.body-picture-content-button-wrapper{
  width: 360px;
  margin: 20px auto;
}

.body-picture-content-button-inner{
  float: left;
  width: 120px;
  height: 40px;
  line-height: 37px;
  text-align: center;
  font-size: 16px;
  font-weight: 600;
  background-color: #ffffff;
  color:  var(--primary-color);
  border-top: 1px solid  var(--primary-color);
  border-bottom: 1px solid  var(--primary-color);
  cursor: pointer;
}

.body-picture-content-button-inner.first{
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
  border-left: 1px solid  var(--primary-color);
}

.body-picture-content-button-inner.second{
  border-left: 1px solid  var(--primary-color);
  border-right: 1px solid  var(--primary-color);
}

.body-picture-content-button-inner.disabled.second{
  border-top: 1px solid #e8e9ee;
  border-bottom: 1px solid #e8e9ee;
  border-left: 1px solid #e8e9ee;
  border-right: 1px solid #e8e9ee;
  color: rgba(0,0,0,.20);
  cursor: default;
}

.body-picture-content-button-inner.disabled.last{
  border-top: 1px solid #e8e9ee;
  border-bottom: 1px solid #e8e9ee;
  border-right: 1px solid #e8e9ee;
  color: rgba(0,0,0,.20);
  cursor: default;
}

.body-picture-content-button-inner.last{
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
  border-right: 1px solid  var(--primary-color);
}

.body-picture-content-button-inner.active{
  background-color:  var(--primary-color);
  color: #fff;
}

.body-picture-content-title{
  padding: 0;
  font-size: 24px;
  font-weight: 600;
  color: rgba(0,0,0,.87);
  text-align: center;
}

.body-picture-content-title.extra-label{
  font-weight: 300;
}

.main-picture-date {
  position: absolute;
  width: 130px;
  bottom: 25px;
  left: 50%;
  margin-left: -65px;
  font-size: 16px;
  color: #ffffff;
  letter-spacing: 0.18px;
  text-align: center;
  font-family: "TT Norms", Helvetica, Arial, sans-serif;
  font-weight: 600;
  background: rgba(0, 0, 0, 0.5);
  border-radius: 20px;
  height: 24px;
  line-height: 24px;
}

.main-picture-wrapper {
  width: 375px;
  height: 500px;
  margin: 0 auto;
  position: relative;
  background-color: #e0e0e0;
  overflow: hidden;
  border: 1px solid #f1f1f1;
}

.main-picture-bodypart-icon {
  position: absolute;
  background: rgba(0, 0, 0, 0.2);
  border-radius: 100px;
  width: 50px;
  height: 100px;
  top: 10px;
  left: 10px;
}

.main-picture-bodypart-icon.headfront {
  background: rgba(0, 0, 0, 0.4) url("../assets/bodyparts-small-white/head-front-selected.png") no-repeat center center;
  background-size: auto 80px;
}
.main-picture-bodypart-icon.headleft {
  background: rgba(0, 0, 0, 0.4) url("../assets/bodyparts-small-white/head-left-selected.png") no-repeat center center;
  background-size: auto 80px;
}
.main-picture-bodypart-icon.headright {
  background: rgba(0, 0, 0, 0.4) url("../assets/bodyparts-small-white/head-right-selected.png") no-repeat center center;
  background-size: auto 80px;
}

.main-picture-bodypart-icon.upperbodyfront {
  background: rgba(0, 0, 0, 0.4) url("../assets/bodyparts-small-white/upperbody-front-selected.png") no-repeat center center;
  background-size: auto 80px;
}

.main-picture-bodypart-icon.upperbodyback {
  background: rgba(0, 0, 0, 0.4) url("../assets/bodyparts-small-white/upperbody-back-selected.png") no-repeat center center;
  background-size: auto 80px;
}

.main-picture-bodypart-icon.upperbodyleft {
  background: rgba(0, 0, 0, 0.4) url("../assets/bodyparts-small-white/upperbody-left-selected.png") no-repeat center center;
  background-size: auto 80px;
}

.main-picture-bodypart-icon.upperbodyright {
  background: rgba(0, 0, 0, 0.4) url("../assets/bodyparts-small-white/upperbody-right-selected.png") no-repeat center center;
  background-size: auto 80px;
}

.main-picture-bodypart-icon.lowerbodyfront {
  background: rgba(0, 0, 0, 0.4) url("../assets/bodyparts-small-white/lowerbody-front-selected.png") no-repeat center center;
  background-size: auto 80px;
}

.main-picture-bodypart-icon.lowerbodyback {
  background: rgba(0, 0, 0, 0.4) url("../assets/bodyparts-small-white/lowerbody-back-selected.png") no-repeat center center;
  background-size: auto 80px;
}

.main-picture-bodypart-icon.lowerbodyleft {
  background: rgba(0, 0, 0, 0.4) url("../assets/bodyparts-small-white/lowerbody-left-selected.png") no-repeat center center;
  background-size: auto 80px;
}

.main-picture-bodypart-icon.lowerbodyright {
  background: rgba(0, 0, 0, 0.2) url("../assets/bodyparts-small-white/lowerbody-right-selected.png") no-repeat center center;
  background-size: auto 100px;
}

.select-picture-wrapper {
  padding: 20px;
}

.select-picture-preview-inner {
  position: relative;
  width: 110px;
  height: 110px;
  background-color: #f1f1f1;
  margin: 10px 4px;
  float: left;
  overflow: hidden;
  background: #ffffff;
  box-shadow: 0 0 3px 0 rgba(0, 0, 0, 0.12), 0 4px 8px 0 rgba(0, 0, 0, 0.24);
}
.select-picture-preview-inner.active,
.select-picture-preview-inner.compareActiveOne,
.select-picture-preview-inner.compareActiveTwo {
  width: 108px;
  height: 108px;
  border: 1px solid  var(--primary-color);
  box-shadow: 0 2px 7px 0  var(--primary-color);
}

.select-picture-preview-inner img {
  width: 100%;
  height: auto;
}

.select-picture-date {
  position: absolute;
  width: 80px;
  bottom: 10px;
  left: 50%;
  margin-left: -35px;
  font-size: 11px;
  color: #ffffff;
  letter-spacing: 0.18px;
  text-align: center;
  font-family: "TT Norms", Helvetica, Arial, sans-serif;
  font-weight: 600;
  background: rgba(0, 0, 0, 0.5);
  border-radius: 20px;
  height: 15px;
  line-height: 15px;
  z-index: 99;
  text-transform: lowercase;
}

.dialog-header-blue-bar {
  width: 100%;
  height: 15px;
  background-color:  var(--primary-color);
}

.dialog-content-wrapper h2 {
  font-family: "TT Norms", sans-serif;
  font-weight: 900;
  font-size: 22px;
  color: #030303;
  text-align: center;
  margin: 10px 0;
  padding: 0;
  
}

.dialog-content-wrapper h2::first-letter{
  text-transform: capitalize;
}

.dialog-content-wrapper h3 {
  font-family: "TT Norms", sans-serif;
  font-weight: 900;
  font-size: 18px;
  color: #030303;
  text-align: center;
  margin: 1px 0;
  padding: 15px 0 0 0;
}

.dialog-content-wrapper h4 {
  font-family: "TT Norms", sans-serif;
  font-weight: 700;
  font-size: 15px;
  color: #030303;
  text-align: left;
  margin: 0;
  padding: 0;
}

.dialog-content-wrapper p {
  font-family: "Sofia Pro", sans-serif;
  font-weight: 400;
  font-size: 12px;
  color: #030303;
  margin: 0;
  padding: 5px 0;
}

.dialog-new-client,
.dialog-existing-client {
  width: calc(100% - 20px);
  background-color: #f4f7fc;
  border-radius: 5px;
  padding: 10px;
  margin: 15px auto;
}

.dialog-buttons-wrapper {
  width: 100%;
  padding: 15px 0 5px 0;
}

.dialog-button-wrapper {
  padding: 0px 10px 15px 10px;
}

.dialog-button,
.dialog-button.thrive:active,
.dialog-button.thrive.orange-color:active {
  width: calc(100%);
  border: 1px solid #687387;
  height: 38px;
  background-color: #fff;
  color: #687387;
  border-radius: 50px;
  font-size: 15px;
}

.dialog-button.thrive,
.dialog-button:active {
  border: 1px solid  var(--primary-color);
  height: 38px;
  background-color:  var(--primary-color);
  color: #fff;
}

.dialog-content-wrapper p.dialog-content-text {
  font-size: 14px;
  color: rgba(0, 0, 0, 0.87);
  letter-spacing: 0;
  text-align: center;
  padding: 15px;
}

.dialog-button.thrive.orange-color{
  border: 1px solid #F48C00;
  background-color: #F48C00;
}

.dialog-buttons-wrapper .dialog-button,
.dialog-buttons-wrapper .dialog-button.thrive:active {
  width: calc(50% - 10px);
  float: left;
}

.dialog-buttons-wrapper .dialog-button.first {
  margin: 0 10px 0 0;
}
.dialog-buttons-wrapper .dialog-button.second {
  margin: 0 0 0 10px;
}

.first-one-explaination{
  text-align: center;
  padding: 40px 20px;
  font-size: 18px;
  color: rgba(0,0,0, 0.87);
}


.compare-picture {
  position: relative;
  width: 50%;
  float: left;
  overflow: hidden;
}

.compare-picturer img {
  width: 100%;
  height: auto;
}

.image-selected-number {
  position: absolute;
  top: 5px;
  right: 5px;
  width: 18px;
  height: 18px;
  border-radius: 20px;
  color: #fff;
  line-height: 19px;
  text-align: center;
  font-size: 12px;
  background-color:  var(--primary-color);
  z-index: 99;
}

.image-selected-number-big {
  position: absolute;
  top: 10px;
  width: 24px;
  height: 24px;
  border-radius: 24px;
  color: #fff;
  line-height: 24px;
  text-align: center;
  font-size: 14px;
  background-color:  var(--primary-color);
  z-index: 99;
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.19);
}

.image-selected-number-big.left {
  left: 10px;
}

.image-selected-number-big.right {
  right: 10px;
}

#image-compare-slider .image-selected-number-big img{
  width: 100%;
  height: auto;
}

.compare-picture-wrapper,
#image-compare-slider {
  margin: 0 auto;
  overflow: hidden;
  background-color: #f9f9f9;
  border: 1px solid #f1f1f1;
}

.empty-image-box {
  width: 80px;
  height: 80px;
  border: 2px dashed  var(--primary-color);
  border-radius: 5px;
  margin: 50px auto 0 auto;
}

.image-slider-empty .empty-image-box {
  margin: 185px auto 0 auto;
}

.image-slider-empty p,
.image-compare-empty p {
  font-size: 12px;
  color:  var(--primary-color);
  text-align: center;
  padding: 10px 0 0 0;
}

.slider-image-two-wrapper {
  position: absolute;
  right: 0;
  height: 500px;
}

.slider-image-two {
  position: absolute;
  right: 0;
}

.image-slider-empty {
  position: absolute;
  right: 0;
  width: 100%;
  min-width: 130px;
}

.image-slider-empty p {
  width: 120px;
  margin: 0 auto;
}

#image-compare-slider {
  position: relative;
}

.image-overlay {
  position: absolute;
  top: 0;
  left: 0;
  overflow: hidden;
  z-index: 10;
  height: 500px;
}

.image-left,
.image-right {
  position: absolute;
  top: 0;
  left: 0;
  height: auto;
  z-index: 1;
  max-width: none;
}
#image-compare-handle {
  width: 4px;
  height: 500px;
  position: absolute;
  top: 0;
  margin: 0 0 0 -2px;
  z-index: 99;
}
#image-compare-handle .border-top {
  position: absolute;
  width: 4px;
  height: calc(50% - 30px);
  left: 0;
  top: 0;
  background-color: #fff;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
  -moz-box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
  -webkit-box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
  -o-box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
}

#image-compare-handle .border-bottom {
  position: absolute;
  width: 4px;
  height: calc(50% - 20px);
  left: 0;
  bottom: 0;
  background-color: #fff;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
  -moz-box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
  -webkit-box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
  -o-box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
}

#handle {
  width: 52px;
  height: 52px;
  border: 4px solid #fff;
  border-radius: 60px;
  background: url("../assets/icons/slider-handle-arrows.png") no-repeat center center;
  background-size: 45px auto;
  z-index: 999;
  position: absolute;
  left: 0;
  top: 50%;
  margin: -30px 0 0 -24px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
  -moz-box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
  -webkit-box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
  -o-box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
}

.slider-picture-date {
  position: absolute;
  width: 130px;
  bottom: 25px;
  font-size: 16px;
  color: #ffffff;
  letter-spacing: 0.18px;
  text-align: center;
  font-family: "TT Norms", Helvetica, Arial, sans-serif;
  font-weight: 600;
  background: rgba(0, 0, 0, 0.5);
  border-radius: 20px;
  height: 24px;
  line-height: 24px;
  text-transform: lowercase;
}

.slider-picture-date.left {
  left: 10px;
}

.slider-picture-date.right {
  right: 10px;
}
.show-detail-picture{
  position: absolute;
  right: 10px;
  top: 10px;
  z-index: 999;
}

.show-detail-picture:hover{
  cursor: pointer;
}

.client-information-wrapper:after{
  display: block;
  clear: both;
  content: "";
}
.client-information-menu{
  padding-top: 44px;
  width: 270px;
  float: left;
}
.client-information-content{
  width: calc(100% - 270px);
  float: left;
}

.codeBlockError {
	font-family: "Lucida Console", "Consolas", "Monaco", "Menlo", sans-serif;
	background-color: #f2f2f2;
	padding: 4px 4px 4px 16px;
	margin: 4px 0 4px 0;
}

.img-hor-vert {
    -moz-transform: scale(-1, 1);
    -o-transform: scale(-1, 1);
    -webkit-transform: scale(-1, 1);
    transform: scale(-1, 1);
}

</style>