export default {
    thrive: {
        light: {
            primary: "#9155FB", // Paars
            secondary: "#ff4c51", // Rood
            tertiary: "#ffb400", // Geel
            quaternary: "#56ca00", // Groen
            background: "#f1f1f9",
            card: "#ffffff",
            cardOption: "#f1f1f9", // Alternative Card Color
            warning: "#ffb400",
            cardHeader: "#fafaff", // Card Header Background Color
            heading: "5e5669de", // Heading Text Color
            text: "rgba(94,86,105,.87)", // Text Color
            textSmooth: "rgba(94,86,105,.67)", // Text Color
            menuBackground: "#1f1d31",
            menuText: "rgba(255,255,255,.76)",
            menuActivePrimary: "#845fd3", // Active Menu Item Color
            menuActiveSecondary: "#c48eff", // Active Menu Item Color
            chartSecondary:"#a983f8",
            chartTertiary:"#e7d6fd",
            chartQuatary: "#6232da", 
            separator: "rgba(94,86,105,.14)",
            emailEditorRow: "31,29,49",
            emailEditorColumn: "145,85,251", 
            emailEditorModule: "255,180,0",
            developer: "#9155FB15", // Paars
        },
        dark: {
            primary: "#9155FB", // Paars
            secondary: "#ff4c51", // Rood
            tertiary: "#ffb400", // Geel
            quaternary: "#56ca00", // Groen
            background: "#28243d",
            card: "#312d4b",
            cardOption: "#28243d",
            warning: "#ffb400",
            cardHeader: "rgba(231,227,252,.04)",
            menuBackground: "#1f1d31",
            heading: "rgba(231,227,252,.87)",
            text: "rgba(231,227,252,.87)",
            textSmooth: "rgba(231,227,252,.67)", // Text Color
            menuText: "rgba(255,255,255,.76)",
            menuActivePrimary: "#845fd3",
            menuActiveSecondary: "#c48eff", // Active Menu Item Color
            chartSecondary:"#a983f8",
            chartTertiary:"#e7d6fd",
            chartQuatary: "#6232da", 
            separator: "rgba(231,227,252,.14)",
            emailEditorRow: "241,241,249", 
            emailEditorColumn: "145,85,251", 
            emailEditorModule: "255,180,0",
            developer: "#9155FB", // Paars 
        },
    },
    jameda: {
        light: {
            primary: "#3d83df", // Blauw
            secondary: "#98a1ab", // grijs
            tertiary: "#00c3a5", // Azure
            quaternary: "#e0f7f4", // Groen
            background: "#f7f9fa", 
            card: "#ffffff",
            cardOption: "#f1f1f9", // Alternative Card Color
            warning: "#ffb400",
            cardHeader: "rgba(94,86,105,.04)", // Card Header Background Color
            heading: "5e5669de", // Heading Text Color
            text: "rgba(94,86,105,.87)", // Text Color
            textSmooth: "rgba(94,86,105,.67)", // Text Color
            menuBackground: "#1f1d31",
            menuText: "rgba(255,255,255,.76)",
            menuActivePrimary: "#3d83df", // Active Menu Item Color
            menuActiveSecondary: "#4a8ee7", // Active Menu Item Color
            chartSecondary:"#65c8ed",
            separator: "rgba(94,86,105,.14)",
            developer: "#9155FB", // Paars
        },
        dark: {
            primary: "#3d83df", // Blauw
            secondary: "#98a1ab", // Grijs
            tertiary: "#00c3a5", // Azure
            quaternary: "#e0f7f4", // Groen
            background: "#28243d",
            card: "#312d4b",
            cardOption: "#28243d",
            warning: "#ffb400",
            cardHeader: "rgba(231,227,252,.04)",
            menuBackground: "#1f1d31",
            heading: "rgba(231,227,252,.87)",
            text: "rgba(231,227,252,.87)",
            textSmooth: "rgba(231,227,252,.67)", // Text Color
            menuText: "rgba(255,255,255,.76)",
            menuActivePrimary: "#3d83df", // Active Menu Item Color
            menuActiveSecondary: "#4a8ee7", // Active Menu Item Color
            chartSecondary:"#65c8ed",
            separator: "rgba(231,227,252,.14)",
            developer: "#9155FB", // Paars
        },
    },
    bonair: {
        light: {
            primary: "#f09437", // Oranje
            secondary: "#7fc954", // Groen
            tertiary: "#ffb400", // Geel
            quaternary: "#56ca00", // Groen
            background: "#f1f1f9",
            card: "#ffffff",
            cardOption: "#f1f1f9", // Alternative Card Color
            warning: "#ffb400",
            cardHeader: "rgba(94,86,105,.04)", // Card Header Background Color
            heading: "5e5669de", // Heading Text Color
            text: "rgba(94,86,105,.87)", // Text Color
            textSmooth: "rgba(94,86,105,.67)", // Text Color
            menuBackground: "#1f1d31",
            menuText: "rgba(255,255,255,.76)",
            menuActivePrimary: "#f09437", // Active Menu Item Color
            menuActiveSecondary: "#65c8ed", // Active Menu Item Color
            chartSecondary:"#65c8ed",
            separator: "rgba(94,86,105,.14)",
            developer: "#9155FB", // Paars
        },
        dark: {
            primary: "#f09437", // Oranje
            secondary: "#7fc954", // Groen
            tertiary: "#ffb400", // Geel
            quaternary: "#56ca00", // Groen
            background: "#28243d",
            card: "#312d4b",
            cardOption: "#28243d",
            warning: "#ffb400",
            cardHeader: "rgba(231,227,252,.04)",
            menuBackground: "#1f1d31",
            heading: "rgba(231,227,252,.87)",
            text: "rgba(231,227,252,.87)",
            textSmooth: "rgba(231,227,252,.67)", // Text Color
            menuText: "rgba(255,255,255,.76)",
            menuActivePrimary: "#f09437", // Active Menu Item Color
            menuActiveSecondary: "#65c8ed", // Active Menu Item Color
            chartSecondary:"#65c8ed",
            separator: "rgba(231,227,252,.14)",
            developer: "#9155FB", // Paars
        },
    }
};
